import { profilePicture } from './cmsImages';

const jussiTraveller = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '2gktp8595eq1dczojvnqcB',
    type: 'Entry',
    createdAt: '2019-08-21T13:26:03.519Z',
    updatedAt: '2019-08-22T10:40:07.505Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'traveller'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    profilePicture: profilePicture,
    title: 'Jussi Jussinen',
    firstName: 'Jussi',
    slug: 'jussi',
    seoTitle: 'Jussi on kova matkaamaan',
    leadParagraph:
      'Jussin löydät reissuilla useimmiten nauttimassa laatukahvia berliiniläisessä katukahvilassa tai lukemassa Mondon uusinta julkaisua oman kodin sohvan lämpimässä syleilyssä.',
    seoDescription: 'Jotain aivan muuta kuin mitä Jussi olisi voinut luulla!',
    shortInfo: 'Jussi iskee kuin haukka Eurooppalaisiin katukahviloihin.',
    residence: 'Tampere, Suomi',
    homepage: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '7MWNVa2acxNuE5wziyNKfR',
        type: 'Entry',
        createdAt: '2019-09-30T10:26:24.025Z',
        updatedAt: '2019-09-30T10:26:24.025Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 1,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'travellerHomepageLink'
          }
        },
        locale: 'fi-FI'
      },
      fields: {
        linkText: 'Jussin kotisivut',
        url: 'https://www.example.com'
      }
    }
  }
};

const saraTraveller = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '4YWqKtM3A5TSBXkJM5WHOO',
    type: 'Entry',
    createdAt: '2020-02-24T09:53:24.985Z',
    updatedAt: '2020-04-03T06:18:33.182Z',
    environment: {
      sys: {
        id: 'staging',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'traveller'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    profilePicture: profilePicture,
    title: 'Sara Halinen',
    firstName: 'Sara',
    slug: 'sara-Halinen',
    shortInfo: 'Nautiskeleva seikkailija kukkamekossa',
    residence: 'Kespoo',
    homepage: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '7xhP5FeIYTuWKnw2lPEEFc',
        type: 'Entry',
        createdAt: '2020-02-24T09:51:40.588Z',
        updatedAt: '2020-03-31T09:58:45.049Z',
        environment: {
          sys: {
            id: 'staging',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 2,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'travellerHomepageLink'
          }
        },
        locale: 'fi-FI'
      },
      fields: {
        linkText: 'Saran sivut',
        url: 'https://www.example.com'
      }
    },
    leadParagraph:
      'Rakastan nähdä kauniita paikkoja, saada unohtumattomia kokemuksia ja maistaa uusia makuja! Matkustamisessa mulle on tärkeää monipuoliset mahdollisuudet ja samanhenkinen seura. Seikkailijan luonteestani huolimatta arvostan tiettyä mukavuutta. Lomallehan mennään nauttimaan!'
  }
};

export { jussiTraveller, saraTraveller };
