import TagManager from 'react-gtm-module';
import { configuration } from '../utils/environment';
import { DataLayerObject } from './types';
// Following https://github.com/sahava/datalayer-typescript.

export function initializeAnalytics() {
  if (configuration.googleTagManager) {
    const tagManagerArgs = {
      gtmId: configuration.googleTagManager.containerId,
      auth: configuration.googleTagManager.environmentAuthorizationCode,
      preview: configuration.googleTagManager.environmentName
    };
    TagManager.initialize(tagManagerArgs);
  }
}

export function trackAnalyticsEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
) {
  const dataLayer: DataLayerObject = {
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction
    }
  };
  pushToDataLayer(dataLayer);
}

function pushToDataLayer(dataLayer: DataLayerObject) {
  TagManager.dataLayer({ dataLayer: dataLayer });
}
