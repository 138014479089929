import React from 'react';
import { grid } from '../../styles/theme';

const Row: React.FunctionComponent = (props) => {
  return (
    <div className="row">
      {props.children}
      {/*language=CSS*/}
      <style jsx>{`
        .row {
          display: flex;
          flex-wrap: wrap;
          margin-right: -${grid.padding};
          margin-left: -${grid.padding};
        }
      `}</style>
    </div>
  );
};

export default Row;
