import React from 'react';
import Icon from '../common/Icon';
import H from '../elements/H';
import { linkStyle, spacing } from '../../styles/theme';
import Paragraph from '../elements/Paragraph';
import HyperLink from '../elements/HyperLink';
import RouteLink from './RouteLink';
import { MainMenuElement } from './MainMenu';

const ModalMainMenuElement: React.FunctionComponent<MainMenuElement> = ({
  icon,
  path,
  label,
  description,
  query
}) => {
  return (
    <div className="modal-main-menu-element">
      <RouteLink path={path} query={query}>
        <HyperLink
          display="flex"
          data-testid="modal-main-menu-link"
          noLinkStateStyles
        >
          <div className="icon">
            <Icon name={icon} size={4} color="purple-light" />
          </div>
          <div className="text">
            <H styling="h2" tag="div">
              <span className="title-text">
                <strong>{label}</strong>
              </span>
            </H>
            <Paragraph small>{description}</Paragraph>
          </div>
        </HyperLink>
      </RouteLink>
      {/*language=CSS*/}
      <style jsx>{`
        .modal-main-menu-element .title-text {
          ${linkStyle.withAnimation}
        }
        .modal-main-menu-element:hover .title-text {
          ${linkStyle.hover}
        }
        .modal-main-menu-element:active .title-text {
          ${linkStyle.active}
        }
        .icon {
          flex: 0 0 auto;
          margin-right: ${spacing.one};
        }
      `}</style>
    </div>
  );
};

export default ModalMainMenuElement;
