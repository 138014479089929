import React from 'react';
import { baseXInPixels } from '../../styles/theme';

interface ImageProps {
  src: string;
  alt: string;
  borderRadius?: number;
}

const Image: React.FunctionComponent<ImageProps> = ({
  src,
  alt,
  borderRadius,
  ...rest
}) => {
  return (
    <div className="image">
      <img src={src} alt={alt} {...rest} key={src} />
      {/*language=CSS*/}
      <style jsx>{`
        .image {
          height: 100%;
          width: 100%;
        }
        img {
          max-width: 100%;
          display: flex;
          border-radius: ${baseXInPixels(borderRadius || 0)}px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          /* To get nice fade when hover is set to image in parent component. */
          transition: 0.5s;
        }
      `}</style>
    </div>
  );
};

export default Image;
