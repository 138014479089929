const cmsHotelPhase = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '4VhlLbafzx8naX21YPQEBt',
    type: 'Entry',
    createdAt: '2019-08-21T13:58:14.643Z',
    updatedAt: '2019-08-30T12:03:17.349Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'hotelPhase'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    label: 'Lisboa Prata Hotel',
    title: 'Hotelli oli upee!',
    mainMedia: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '67GIYtEDtzS8MQFCroFNeF',
        type: 'Asset',
        createdAt: '2019-08-21T13:46:09.104Z',
        updatedAt: '2019-08-21T13:46:09.104Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 1,
        locale: 'fi-FI'
      },
      fields: {
        title: 'Lisboa Prata Hotel',
        description: 'Hotelli oli ihan keskustassa',
        file: {
          url: '//localhost:3000/static/test/dummy-image.png',
          details: {
            size: 3154329,
            image: {
              width: 1562,
              height: 1246
            }
          },
          fileName: 'Screenshot 2019-08-21 at 16.38.14.png',
          contentType: 'image/png'
        }
      }
    },
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non\nauctor dui. Nunc condimentum nibh sed ornare molestie. Curabitur id\nlibero sit amet ante porttitor ultrices. In id lacus posuere, accumsan\nlorem in, blandit lorem. Suspendisse justo nisl, bibendum vitae\nporttitor at, accumsan ut augue.\n\nSuspendisse tincidunt mi sed magna rutrum vulputate. Duis\npellentesque euismod magna non vestibulum. Fusce posuere facilisis\nmetus, ac laoreet tellus. Vestibulum eu dolor sed odio commodo\nmalesuada. Pellentesque accumsan tincidunt tincidunt. Nullam luctus\naliquet malesuada. Curabitur gravida ex non rutrum efficitur.',
    media: [
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: 'fPfT3mnIteDAlgAvx4SFh',
          type: 'Asset',
          createdAt: '2019-08-21T13:46:55.316Z',
          updatedAt: '2019-08-21T13:46:55.316Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Hotellihuone',
          description: 'Huone oli siisti',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: {
              size: 3377638,
              image: {
                width: 1688,
                height: 1244
              }
            },
            fileName: 'Screenshot 2019-08-21 at 16.38.24.png',
            contentType: 'image/png'
          }
        }
      }
    ],
    ctaText: 'Näytä hotelli',
    ctaDescription: 'Lisboa Prata Hotel',
    ctaUrl: 'http://lisboa-prata-boutique-hotel.lisbon-hotel.org/en/'
  }
};
export default cmsHotelPhase;
