import React from 'react';
import Head from 'next/head';
import { baseFontSizePx, color, font, fontWeight } from '../../styles/theme';
import { t } from '../../utils/i18n';

const BasicLayout: React.FunctionComponent = (props) => {
  const browserDefaultFontSize = 16;
  const fontSizeInPercentage = (baseFontSizePx / browserDefaultFontSize) * 100;
  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <title>{t('Tim')}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link href="/static/fonts/space-grotesk.css" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400italic,500,600,700&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="/static/img/favicon-16.png" sizes="16x16" />
        <link rel="icon" href="/static/img/favicon-32.png" sizes="32x32" />
        <link rel="icon" href="/static/img/favicon-128.png" sizes="128x128" />
        <link rel="icon" href="/static/img/favicon-192.png" sizes="192x192" />
        <link rel="icon" href="/static/img/favicon-196.png" sizes="196x196" />
        <link
          rel="apple-touch-icon"
          href="/static/img/favicon-120.png"
          sizes="120x120"
        />
        <link
          rel="apple-touch-icon"
          href="/static/img/favicon-152.png"
          sizes="152x152"
        />
        <link
          rel="apple-touch-icon"
          href="/static/img/favicon-180.png"
          sizes="180x180"
        />
      </Head>
      {props.children}
      {/*language=CSS*/}
      <style jsx global>{`
        html {
          font-size: ${fontSizeInPercentage}%;
          font-family: ${font.spaceGrotesk};
          font-weight: ${fontWeight.regular};
          color: ${color.dark};
          box-sizing: border-box;
          -webkit-font-kerning: normal;
          font-kerning: normal;
          -webkit-font-smoothing: antialiased;
        }

        html,
        body {
          margin: 0;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        button {
          font-family: inherit;
        }
      `}</style>
    </div>
  );
};

export default BasicLayout;
