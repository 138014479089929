import React from 'react';
import classNames from 'classnames';
import { baseXUnit, breakpoints, color } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

interface ImageLimiterProps {
  height: ResponsiveProp<number>;
  borderRadius?: ResponsiveProp<number>;
  skeleton?: boolean;
}

const ImageLimiter: React.FunctionComponent<ImageLimiterProps> = ({
  height,
  borderRadius = 0,
  skeleton = true,
  children
}) => {
  const heightValue = propToResponsivePropObject(height).mapDefined(baseXUnit);
  const borderRadiusValues =
    propToResponsivePropObject(borderRadius).mapDefined(baseXUnit);
  return (
    <div className={classNames('image-limiter', { skeleton })}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .image-limiter {
          position: relative;
          overflow: hidden;
          /* Fix image hover issue on mobile Safari
            Hover removed border radius from image. This fix seems to work.
          https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari 
          */
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        }
        .image-limiter.skeleton {
          /* Background skeleton during loading */
          background: ${color.lightGray};
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .image-limiter {
          height: ${heightValue.small};
          border-radius: ${borderRadiusValues.small};
        }

        @media ${breakpoints.medium} {
          .image-limiter {
            height: ${heightValue.medium};
            border-radius: ${borderRadiusValues.medium};
          }
          .image-limiter :global(img) {
            max-height: ${heightValue.medium};
          }
        }

        @media ${breakpoints.large} {
          .image-limiter {
            height: ${heightValue.large};
            border-radius: ${borderRadiusValues.large};
          }
          .image-limiter :global(img) {
            max-height: ${heightValue.large};
          }
        }

        @media ${breakpoints.extraLarge} {
          .image-limiter {
            height: ${heightValue.extraLarge};
            border-radius: ${borderRadiusValues.extraLarge};
          }
          .image-limiter :global(img) {
            max-height: ${heightValue.extraLarge};
          }
        }
      `}</style>
    </div>
  );
};

export default ImageLimiter;
