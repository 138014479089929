import Cookies from 'universal-cookie';
import uniq from 'lodash/uniq';
import isString from 'lodash/isString';
import { Question } from '../redux/features/questions';

type AnswerOptions = string[];
export type Answers = Record<string, string[]>;

function sanitizeAnswerOptions(
  answerOptions: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
): AnswerOptions {
  return uniq(answerOptions.filter(isString));
}

function sanitizeAnswers(
  answers: any // eslint-disable-line @typescript-eslint/no-explicit-any
): Answers {
  const sanitizedAnswers: Record<string, string[]> = {};
  Object.keys(answers).forEach((answerKey) => {
    const currentAnswerOptions = answers[answerKey];
    if (Array.isArray(currentAnswerOptions)) {
      sanitizedAnswers[answerKey] = sanitizeAnswerOptions(currentAnswerOptions);
    }
  });
  return sanitizedAnswers;
}

const COOKIE_NAME = 'chatAnswers';

export default class SearchAnswers {
  private cookies: Cookies;

  public constructor(cookies: Cookies) {
    this.cookies = cookies;
  }

  public setAll(searchAnswers: Answers) {
    const yearInSeconds = 60 * 60 * 24 * 365;
    return this.cookies.set(COOKIE_NAME, JSON.stringify(searchAnswers), {
      maxAge: yearInSeconds
    });
  }

  public getAll(): Answers {
    const cookieContent = this.cookies.get(COOKIE_NAME);
    const answers = cookieContent ? cookieContent : {};
    return sanitizeAnswers(answers);
  }

  public get(key: string): AnswerOptions {
    return this.getAll()[key] || [];
  }
}

export function getAnswerOptionLabel(question: Question, optionValue: string) {
  if (!question) {
    return '';
  }
  const option = question.options.find(
    (option) => option.value === optionValue
  );
  return option ? option.label : '';
}

export function getAnswerOptionLabels(questions: Question[], answers: Answers) {
  return questions.reduce<string[]>((selections, question) => {
    const answerOptionValue = answers[question.key] && answers[question.key][0];
    if (answerOptionValue) {
      const selection = getAnswerOptionLabel(question, answerOptionValue);
      if (selection) {
        selections.push(selection);
      }
    }
    return selections;
  }, []);
}
