import isNil from 'lodash/isNil';

export type ResponsiveProp<P> = P | (P | undefined)[];

export function propToResponsivePropObject<T = number>(
  value: ResponsiveProp<T> | undefined
): ResponsivePropObject<T> {
  let responsivePropObj;
  if (isNil(value)) {
    responsivePropObj = new ResponsivePropObject<T>();
  } else if (Array.isArray(value)) {
    responsivePropObj = new ResponsivePropObject<T>(
      value[0],
      value[1],
      value[2],
      value[3]
    );
  } else {
    responsivePropObj = new ResponsivePropObject<T>(value, value, value, value);
  }
  return responsivePropObj;
}

export class ResponsivePropObject<T> {
  public small: T | undefined;
  public medium: T | undefined;
  public large: T | undefined;
  public extraLarge: T | undefined;

  public constructor(small?: T, medium?: T, large?: T, extraLarge?: T) {
    this.small = small;
    this.medium = !isNil(medium) ? medium : this.small;
    this.large = !isNil(large) ? large : this.medium;
    this.extraLarge = !isNil(extraLarge) ? extraLarge : this.large;
  }

  /**
   * Map values which have defined values.
   * @param mapFunc
   */
  public mapDefined<S = number>(mapFunc: (value: T) => S) {
    const small = isNil(this.small) ? this.small : mapFunc(this.small);
    const medium = isNil(this.medium) ? this.medium : mapFunc(this.medium);
    const large = isNil(this.large) ? this.large : mapFunc(this.large);
    const extraLarge = isNil(this.extraLarge)
      ? this.extraLarge
      : mapFunc(this.extraLarge);
    return new ResponsivePropObject<S>(small, medium, large, extraLarge);
  }
}
