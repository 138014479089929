const cmsTravelPhase = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '62ofBmXDb2wkSj7QCFYd0V',
    type: 'Entry',
    createdAt: '2019-08-21T13:58:14.610Z',
    updatedAt: '2019-08-30T12:04:17.139Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'travelPhase'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    label: 'Helsinki → Lissabon, TAP Portugal',
    title: 'Lentokoneessa ompi mukavaa!',
    mainMedia: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '7sck8UXLNV3flGPRyo3bNF',
        type: 'Asset',
        createdAt: '2019-08-21T13:41:18.562Z',
        updatedAt: '2019-08-21T13:41:18.562Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 1,
        locale: 'fi-FI'
      },
      fields: {
        title: 'Lento Helsinki → Lissabon, TAP Portugal',
        description: 'Lentokoneen auringonlasku oli upee! 🌇',
        file: {
          url: '//localhost:3000/static/test/dummy-image.png',
          details: {
            size: 1578510,
            image: {
              width: 2134,
              height: 1242
            }
          },
          fileName: 'Screenshot 2019-08-21 at 16.37.39.png',
          contentType: 'image/png'
        }
      }
    },
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non\nauctor dui. Nunc condimentum nibh sed ornare molestie. Curabitur id\nlibero sit amet ante porttitor ultrices. In id lacus posuere, accumsan\nlorem in, blandit lorem. Suspendisse justo nisl, bibendum vitae\nporttitor at, accumsan ut augue.\n\nSuspendisse tincidunt mi sed magna rutrum vulputate. Duis\npellentesque euismod magna non vestibulum. Fusce posuere facilisis\nmetus, ac laoreet tellus. Vestibulum eu dolor sed odio commodo\nmalesuada. Pellentesque accumsan tincidunt tincidunt. Nullam luctus\naliquet malesuada. Curabitur gravida ex non rutrum efficitur.',
    media: [
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '2zkw7iFOKO2uZd3g37qPBx',
          type: 'Asset',
          createdAt: '2019-08-21T13:42:46.582Z',
          updatedAt: '2019-08-21T13:42:46.582Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Lentokone',
          description: 'Lentokone oli mukavan moderni!',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: {
              size: 1654484,
              image: {
                width: 1112,
                height: 1244
              }
            },
            fileName: 'Screenshot 2019-08-21 at 16.37.49.png',
            contentType: 'image/png'
          }
        }
      },
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '71MKLTkuG6NGOSt52COJLH',
          type: 'Asset',
          createdAt: '2019-08-30T07:37:31.629Z',
          updatedAt: '2019-08-30T07:38:44.062Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 2,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Suffaus',
          description: 'Surffaus on mukavvoo!',
          file: {
            url:
              '//videos.ctfassets.net/bh7k0sgo84rb/71MKLTkuG6NGOSt52COJLH/53c8b5a4394ba046a8b32ebda7db9f07/SHM_4818.mp4',
            details: {
              size: 24830945
            },
            fileName: 'SHM_4818.mp4',
            contentType: 'video/mp4'
          }
        }
      }
    ],
    ctaText: 'Näytä lennot',
    ctaDescription: 'Helsinki → Lissabon, TAP Portugal',
    ctaUrl: 'https://www.flytap.com/en-fi/'
  }
};
export default cmsTravelPhase;
