import React from 'react';

/**
 * BEWARE! Probably we should avoid abstracting CSS to components like this.
 *
 * And utility component to place line elements horizontally using display: flex.
 * @param children
 * @param [justifyContent] Flexbox justify-content style.
 * @param [flexDirection] Flexbox flex-direction style.
 * @param [alignItems] Flexbox align-items style.
 * @param rest
 * @constructor
 */
const FlexDiv: React.FunctionComponent<
  React.HTMLAttributes<HTMLElement> & {
    justifyContent?: string;
    flexDirection?: string;
    alignItems?: string;
  }
> = ({
  justifyContent = 'initial',
  flexDirection = 'initial',
  alignItems = 'initial',
  children,
  ...rest
}) => {
  return (
    <div className="flex" {...rest}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .flex {
          display: flex;
          justify-content: ${justifyContent};
          flex-direction: ${flexDirection};
          align-items: ${alignItems};
        }
      `}</style>
    </div>
  );
};

export default FlexDiv;
