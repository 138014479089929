import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import classNames from 'classnames';
import { baseXUnit, breakpoints } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

export type IconName =
  | 'contact-customer-service'
  | 'contact-email'
  | 'nav-arrow-down'
  | 'nav-arrow-left'
  | 'nav-arrow-right'
  | 'nav-arrow-up'
  | 'nav-chevron-down'
  | 'nav-chevron-up'
  | 'nav-close'
  | 'nav-close-small'
  | 'nav-expand'
  | 'nav-falafel'
  | 'nav-filter'
  | 'nav-grid'
  | 'nav-hamburger'
  | 'nav-kebab'
  | 'nav-return'
  | 'placeholder'
  | 'travel-activity'
  | 'travel-calendar-small'
  | 'travel-calendar'
  | 'travel-currency'
  | 'travel-facebook'
  | 'travel-favourites'
  | 'travel-food-drinks'
  | 'travel-hotel'
  | 'travel-house-small'
  | 'travel-house'
  | 'travel-info-pages'
  | 'travel-info-small'
  | 'travel-info'
  | 'travel-instagram'
  | 'travel-internet-small'
  | 'travel-internet'
  | 'travel-map'
  | 'travel-memory'
  | 'travel-no'
  | 'travel-parking'
  | 'travel-pin-small'
  | 'travel-pin-smalll'
  | 'travel-pin'
  | 'travel-plane-in'
  | 'travel-plane-out'
  | 'travel-plane'
  | 'travel-ship-in'
  | 'travel-ship-out'
  | 'travel-ship'
  | 'travel-population'
  | 'travel-question'
  | 'travel-quote'
  | 'travel-sport'
  | 'travel-star-off-small'
  | 'travel-star-off'
  | 'travel-star-on-small'
  | 'travel-star-on'
  | 'travel-temperature'
  | 'travel-tim-chat'
  | 'travel-timezone'
  | 'travel-traveller'
  | 'travel-vimeo'
  | 'travel-wifi';

export type IconColor =
  | 'dark'
  | 'gray'
  | 'light-gray'
  | 'purple-light'
  | 'white';

interface IconProps {
  name: IconName;
  size: ResponsiveProp<number>;
  screenReaderLabel?: string;
  color?: IconColor;
  hoverColor?: IconColor;
  forceHover?: boolean;
}

const Icon: React.FunctionComponent<IconProps> = ({
  name,
  size,
  screenReaderLabel,
  color,
  hoverColor,
  forceHover
}) => {
  color = color || 'dark';
  const colorSuffix = `-${color}`;
  const hoverColorSuffix = `-${hoverColor}`;

  const baseUnitSize = propToResponsivePropObject(size).mapDefined(baseXUnit);
  return (
    <div className="icon">
      {screenReaderLabel && (
        <VisuallyHidden>{screenReaderLabel}</VisuallyHidden>
      )}
      <img
        src={`/static/icons/atom-icon-${name}${colorSuffix}.svg`}
        alt={`Icon ${name}`}
        aria-hidden="true"
      />
      {hoverColor && (
        <img
          className={classNames('hover', { 'force-hover': forceHover })}
          src={`/static/icons/atom-icon-${name}${hoverColorSuffix}.svg`}
          alt={`Icon ${name} hover`}
          aria-hidden="true"
          data-testid="hover-image"
        />
      )}
      {/*language=CSS*/}
      <style jsx>{`
        .icon {
          position: relative;
        }
        .icon img {
          object-fit: contain;
          width: ${baseUnitSize.small};
          height: ${baseUnitSize.small};
          display: block;
        }
        .icon img.hover {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
        }
        .icon img.hover.force-hover,
        .icon:hover img.hover {
          display: block;
        }
        @media ${breakpoints.medium} {
          .icon img {
            width: ${baseUnitSize.medium};
            height: ${baseUnitSize.medium};
          }
        }
        @media ${breakpoints.large} {
          .icon img {
            width: ${baseUnitSize.large};
            height: ${baseUnitSize.large};
          }
        }
      `}</style>
    </div>
  );
};

export default Icon;
