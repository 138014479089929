export enum LoadingState {
  STARTED = 'STARTED',
  DONE = 'DONE',
  FAILED = 'FAILED'
}

export interface WithLoadingState {
  loadingState: LoadingState;
}

export function shouldBeLoaded(loadable?: WithLoadingState) {
  return (
    !loadable ||
    (loadable.loadingState !== LoadingState.STARTED &&
      loadable.loadingState !== LoadingState.DONE)
  );
}
