import { generatePath } from './generatePath';

export function generateUrl(
  host: string,
  secure = true,
  pattern: string,
  params: Record<string, string> = {}
) {
  const protocol = secure ? 'https' : 'http';
  return `${protocol}://${host}${generatePath(pattern, params)}`;
}

export class UrlGenerator {
  public host: string;
  public secure: boolean;

  public constructor(host: string, secure = true) {
    this.host = host;
    this.secure = secure;
  }

  public generateUrl(pattern: string, params: Record<string, string> = {}) {
    return generateUrl(this.host, this.secure, pattern, params);
  }
}
