import React from 'react';
import classnames from 'classnames';
import {
  baseXUnit,
  breakpoints,
  color,
  font,
  fontSize
} from '../../styles/theme';

export interface ParagraphProps {
  tag?: keyof JSX.IntrinsicElements;
  small?: boolean;
  data?: boolean;
}

const Paragraph: React.FunctionComponent<ParagraphProps> = ({
  tag,
  children,
  small,
  data,
  ...rest
}) => {
  const Tag = tag || 'div';
  return (
    <Tag
      className={classnames('paragraph', { small: small, data: data })}
      {...rest}
    >
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .paragraph {
          font-size: ${fontSize.px14};
          line-height: ${baseXUnit(2.25)};
        }
        .paragraph.small {
          font-size: ${fontSize.px12};
          line-height: ${baseXUnit(1.95)};
          color: ${color.gray};
        }
        .paragraph.data {
          font-family: ${font.mono};
        }
        @media ${breakpoints.large} {
          .paragraph {
            font-size: ${fontSize.px16};
            line-height: ${baseXUnit(3)};
          }
          .paragraph.small {
            font-size: ${fontSize.px14};
            line-height: ${baseXUnit(2.5)};
          }
          .paragraph.small.data {
            font-size: ${fontSize.px12};
            line-height: ${baseXUnit(2)};
          }
        }
      `}</style>
    </Tag>
  );
};

export default Paragraph;
