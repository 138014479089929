import { lisbonImage, lofotenImage } from './cmsImages';
import { lisbonTrip, lofotenTrip } from './cmsTrip';

export const lisbon = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '4U5Svk1KwLZpoflI19UPsG',
    type: 'Entry',
    createdAt: '2019-08-21T13:29:04.642Z',
    updatedAt: '2019-08-22T10:25:55.506Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'destination'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lissabon',
    cityErpId: 321,
    seoTitle: 'Lissabon',
    leadParagraph:
      'Erinomaiset rannat ja palvelujen läheisyys tekevät Portugalin pääkaupungista mainion kohteen jonne vetäytyä arjen kiireistä. Historiallisten kohteiden läheltä löydät myös leikkipaikkoja perheen pienimmille.',
    description: 'Lissabonissa vietät elämäsi loman',
    slug: 'lissabon',
    country: 'Portugali',
    mainImage: lisbonImage,
    trips: [lisbonTrip]
  }
};

export const lofoten = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '155S3qwkZgUofjlfCKmZFz',
    type: 'Entry',
    createdAt: '2019-10-07T07:17:41.023Z',
    updatedAt: '2019-10-16T13:25:40.271Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'destination'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lofootit',
    cityErpId: 123,
    slug: 'lofootit',
    leadParagraph:
      'Lofootit ovat Norjan yksi hienoimmista luontokohteista. Sykähdyttävät kalliot, iloiset viileässä vedessä pauhaavat valaat ja tietysti rehellinen norjalainen meriruoka odottavat matkaajaa. ',
    description:
      'Lofooteilla unohtuu arjen harmaus ja näet elämän kirkkaat värit taas uudestaan.',
    country: 'Norja',
    mainImage: lofotenImage,
    trips: [lofotenTrip]
  }
};
