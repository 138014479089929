import React from 'react';
import Link from 'next/link';
import { RoutePathsForPaths } from '../../urls/Paths';
import { generatePath } from '../../urls/generatePath';
import generatePathWithQueryString from '../../urls/generatePathWithQueryString';

export interface RouteLinkProps {
  path: string;
  params?: Record<string, string>;
  query?: Record<string, string[]>;
}

const RouteLink: React.FunctionComponent<RouteLinkProps> = ({
  path,
  params,
  query,
  children
}) => {
  const url = generatePath(path, params);
  return (
    <Link
      href={{
        pathname: RoutePathsForPaths[path],
        query: query
      }}
      as={generatePathWithQueryString(url, query)}
      passHref
    >
      {children}
    </Link>
  );
};

export default RouteLink;
