import React from 'react';
import classnames from 'classnames';
import { breakpoints, grid } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

interface ColProps {
  size: ResponsiveProp<number>;
  className?: string;
}

export function calculateColumnWidth(size: number) {
  const percentage = (size * 100) / 24;
  // Round to 5 decimal places (10^5)
  const rounder = 100000;
  return Math.round(percentage * rounder) / rounder + '%';
}

const Col: React.FunctionComponent<
  ColProps & React.HTMLAttributes<HTMLElement>
> = ({ size, children, className, ...attributes }) => {
  const percentages =
    propToResponsivePropObject(size).mapDefined(calculateColumnWidth);

  return (
    <div className={classnames('column', className)} {...attributes}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .column {
          position: relative;
          width: 100%;
          min-height: 1px;
          padding-right: ${grid.padding};
          padding-left: ${grid.padding};
          flex: 0 0 ${percentages.small};
          max-width: ${percentages.small};
          display: ${percentages.small === '0%' ? 'none' : 'block'};
        }

        @media ${breakpoints.medium} {
          .column {
            flex: 0 0 ${percentages.medium};
            max-width: ${percentages.medium};
            display: ${percentages.medium === '0%' ? 'none' : 'block'};
          }
        }

        @media ${breakpoints.large} {
          .column {
            flex: 0 0 ${percentages.large};
            max-width: ${percentages.large};
            display: ${percentages.large === '0%' ? 'none' : 'block'};
          }
        }
      `}</style>
    </div>
  );
};

export default Col;
