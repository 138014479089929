import React from 'react';
import { baseXUnit, breakpoints, layoutMaxWidthBase } from '../../styles/theme';
import { propToResponsivePropObject } from '../../utils/layoutUtils';

const containerDefaultHorizontalPadding = [2, 2, 5];
const containerNoMobilePadding = [
  0,
  containerDefaultHorizontalPadding[1],
  containerDefaultHorizontalPadding[2]
];

const Container: React.FunctionComponent<
  { noMobilePadding?: boolean } & React.HTMLAttributes<HTMLElement>
> = ({ noMobilePadding, children, ...rest }) => {
  const paddingValues = propToResponsivePropObject(
    noMobilePadding
      ? containerNoMobilePadding
      : containerDefaultHorizontalPadding
  );
  return (
    <div className="container" {...rest}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .container {
          width: 100%;
          margin: 0 auto;
          padding: 0 ${baseXUnit(paddingValues.small || 0)};
        }

        @media ${breakpoints.medium} {
          .container {
            padding: 0 ${baseXUnit(paddingValues.medium || 0)};
          }
        }

        @media ${breakpoints.large} {
          .container {
            max-width: ${baseXUnit(
              layoutMaxWidthBase + 2 * (paddingValues.large || 0)
            )};
            padding: 0 ${baseXUnit(paddingValues.large || 0)};
          }
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>
        {`
          @media ${breakpoints.small} {
            .container {
              /* Rows inside container without padding will overflow 
                 due row negative margin and cause unwanted side scroll on small devices. 
                 It SHOULD be safe use overflow: hidden to prevent side scroll. */
              ${noMobilePadding ? `overflow: hidden;` : ''}
            }
          }
        `}
      </style>
    </div>
  );
};

export default Container;
