/**
 * Add and remove input outlines based on if the user uses keyboard or not.
 * Inspired by outline.js v1.2.0 - https://github.com/lindsayevans/outline.js/
 *
 * Removed IE 8 etc. support (obsolete for us) and added a way to remove event listeners. This
 * is required for React to clean them up on component unmount.
 */

let styleElement: HTMLElement;
const setCss = (cssText: string) => {
  styleElement.innerHTML = cssText;
};

const mouseDownListener = () => {
  setCss(':focus{outline:0}::-moz-focus-inner{border:0;}');
};

const keydownListener = function () {
  setCss('');
};

export function activateRemoveOutlines() {
  styleElement = document.createElement('STYLE');
  styleElement.id = 'remove-outlines';
  document.getElementsByTagName('HEAD')[0].appendChild(styleElement);

  // Using mousedown instead of mouseover, so that previously focused elements don't lose focus ring on mouse move
  document.addEventListener('mousedown', mouseDownListener);
  document.addEventListener('keydown', keydownListener);
}

export function deactivateRemoveOutlines() {
  // Remove event listeners and remove style tag
  if (styleElement) {
    document.getElementsByTagName('HEAD')[0].removeChild(styleElement);
    document.removeEventListener('mousedown', mouseDownListener);
    document.removeEventListener('keydown', keydownListener);
  }
}
