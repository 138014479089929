import React, { useContext } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { animated } from 'react-spring';
import classNames from 'classnames';
import { baseXUnit, breakpoints, zIndex } from '../../styles/theme';
import FadeAnimation from '../animations/FadeAnimation';
import { useTestableSpring } from '../module-overrides/react-spring';
import { navbarPadding } from '../navigation/Navbar';
import { Breakpoint, WindowContext } from './WindowContextProvider';

export type ModalVariant = 'left' | 'right' | 'fullscreen' | 'centered';

interface ModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  animateIn?: boolean;
  ariaLabel: string;
  variant?: ModalVariant;
  ['data-testid']?: string;
  ['data-cy']?: string;
}

// @reach/dialog uses react-focus-lock with autoFocus set to true.
// (cf. https://github.com/reach/reach-ui/blob/master/packages/dialog/src/index.tsx#L117).
// This causes the browser to scroll to the first focusable item within the modal.
// In many cases this is the "close"-button at the top right corner.
// If the first focusable item is outside of viewport, browsers will try scroll to the item, causing "wiggle" visible
// to the user (cf. https://github.com/theKashey/react-focus-lock/issues/83).
// Therefore, we can only use a vertical translate that is less or equal with the padding on the Navbar.
const maximumDesktopRightTranslateBecauseOfFocusScrollIssue = navbarPadding;

const animations = {
  mobile: 'translate3d(0,' + baseXUnit(3) + ',0)',
  desktopLeft:
    'translate3d(-' +
    // The maximum translate issue does not affect modals coming from the left,
    // but for unified UX we will use the same value for both.
    maximumDesktopRightTranslateBecauseOfFocusScrollIssue +
    ',0,0)',
  desktopRight:
    'translate3d(' +
    maximumDesktopRightTranslateBecauseOfFocusScrollIssue +
    ',0,0)',
  desktopFullscreen: 'translate3d(0,0,0)',
  desktopCentered: `translate3d(0,${baseXUnit(3)},0)`
};

function getContentAnimation(
  breakpoint: Breakpoint | undefined,
  variant: ModalVariant
) {
  if (!breakpoint || breakpoint === 'small') {
    return animations.mobile;
  } else if (variant === 'left') {
    return animations.desktopLeft;
  } else if (variant === 'right') {
    return animations.desktopRight;
  } else if (variant === 'fullscreen') {
    return animations.desktopFullscreen;
  } else if (variant === 'centered') {
    return animations.desktopCentered;
  }
  return '';
}

const ModalContent: React.FunctionComponent<
  {
    animateIn?: boolean;
    variant?: ModalVariant;
    breakpoint?: Breakpoint;
  } & React.HTMLProps<HTMLDivElement>
> = ({ children, variant = 'left', animateIn = false, breakpoint }) => {
  const contentStyle = useTestableSpring({
    from: { transform: getContentAnimation(breakpoint, variant) },
    to: {
      transform: 'translate3d(0,0,0)'
    }
  });
  const className = classNames('modal-content', variant);
  return (
    <>
      {animateIn ? (
        <animated.div className={className} style={contentStyle}>
          <FadeAnimation data-testid="modal-fade-animation">
            {children}
          </FadeAnimation>
        </animated.div>
      ) : (
        <div className={className}>{children}</div>
      )}

      {/*language=CSS*/}
      <style jsx global>{`
        .modal-content {
          width: 100%;
          min-height: 100%;
          background: white;
          padding: 0;
          outline: none;
        }

        @media ${breakpoints.medium} {
          .modal-content.left {
            width: ${baseXUnit(60)};
            box-shadow: 0 -4px 30px 0 rgba(0, 0, 0, 0.15);
            position: absolute;
            left: 0;
          }
          .modal-content.right {
            width: ${baseXUnit(60)};
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
            position: absolute;
            right: 0;
          }
        }

        @media ${breakpoints.large} {
          .modal-content.centered {
            width: ${baseXUnit(84)};
            box-shadow: 0 -4px 30px 0 rgba(0, 0, 0, 0.15);
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      `}</style>
    </>
  );
};

const Modal: React.FunctionComponent<
  ModalProps & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  isOpen,
  onDismiss,
  ariaLabel,
  variant = 'left',
  animateIn = false,
  className,
  ...rest
}) => {
  const { breakpoint } = useContext(WindowContext);
  return (
    <div className={classNames('modal', className)}>
      <DialogOverlay
        isOpen={isOpen}
        onDismiss={onDismiss}
        className={classNames('modal-overlay', variant)}
      >
        <DialogContent
          aria-label={ariaLabel}
          className="dialog-content"
          data-cy={rest['data-cy']}
          data-testid={rest['data-testid']}
        >
          <ModalContent
            variant={variant}
            animateIn={animateIn}
            breakpoint={breakpoint}
          >
            {children}
          </ModalContent>
        </DialogContent>
      </DialogOverlay>
      {/*language=CSS*/}
      <style jsx global>{`
        .modal-overlay {
          background: #fff;
          z-index: ${zIndex.modal};
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          overflow-x: hidden;
          overflow-y: auto;
        }

        .dialog-content {
          height: 100%;
        }

        /* Disable @reach/dialog style warnings */
        :root {
          --reach-dialog: 1;
        }

        @media ${breakpoints.medium} {
          .modal-overlay {
            background-color: rgba(255, 255, 255, 0.9);
          }

          .modal-overlay.centered {
            // Force scrollbar to prevent centered modals from jumping when scrollbar appears or disappears during the animation
            overflow-y: scroll;
          }
        }
      `}</style>
    </div>
  );
};

export default Modal;
