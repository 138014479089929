import { cmsLiftBlock } from './cmsLiftBlock';
import { cmsCarouselBlock } from './cmsCarouselBlock';
import { festivalImage } from './cmsImages';
import { lisbonMemory, tenerifeMemory } from './cmsMemories';

export const memoryListPage = {
  sys: {
    space: { sys: { type: 'Link', linkType: 'Space', id: 'bh7k0sgo84rb' } },
    id: '1234567890',
    type: 'Entry',
    createdAt: '2020-02-21T11:13:48.143Z',
    updatedAt: '2020-02-21T11:13:48.143Z',
    environment: {
      sys: { id: 'sandbox1', type: 'Link', linkType: 'Environment' }
    },
    revision: 1,
    contentType: {
      sys: { type: 'Link', linkType: 'ContentType', id: 'contentPage' }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lomamuistot',
    public: true,
    slug: 'lomamuistot',
    seoTitle: 'Lomamuistot',
    blocks: [
      {
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'bh7k0sgo84rb' }
          },
          id: '5pRrKe8Q0V7afyXPGPUnD3',
          type: 'Entry',
          createdAt: '2020-04-01T11:32:41.560Z',
          updatedAt: '2020-04-01T11:32:41.560Z',
          environment: {
            sys: { id: 'sandbox1', type: 'Link', linkType: 'Environment' }
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'popularMemoriesLiftBlock'
            }
          },
          locale: 'fi-FI'
        },
        fields: {
          popularMemories: [lisbonMemory, tenerifeMemory]
        }
      },
      cmsCarouselBlock
    ]
  }
};

export const destinationListPage = {
  sys: {
    space: { sys: { type: 'Link', linkType: 'Space', id: 'bh7k0sgo84rb' } },
    id: '5QZqfH5MPgodJwxPcZw3wm',
    type: 'Entry',
    createdAt: '2020-02-21T11:13:48.143Z',
    updatedAt: '2020-02-21T11:13:48.143Z',
    environment: {
      sys: { id: 'sandbox1', type: 'Link', linkType: 'Environment' }
    },
    revision: 1,
    contentType: {
      sys: { type: 'Link', linkType: 'ContentType', id: 'contentPage' }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Matkakohteet',
    public: true,
    slug: 'matkakohteet',
    seoTitle: 'Matkakohteet'
  }
};

export const travellerListPage = memoryListPage;

export const homePage = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '55ciahwK8KVD1M2cxo10NM',
    type: 'Entry',
    createdAt: '2020-01-13T10:28:42.842Z',
    updatedAt: '2020-01-16T13:41:04.210Z',
    environment: {
      sys: {
        id: 'sandbox1',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 3,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'contentPage'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Etusivu',
    public: false,
    slug: 'home',
    mainImage: festivalImage,
    blocks: [
      cmsLiftBlock,
      cmsCarouselBlock,
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '4wPBTZ4c9dGK0HLAHttv8Q',
          type: 'Entry',
          createdAt: '2020-01-13T10:15:24.040Z',
          updatedAt: '2020-01-16T13:40:58.567Z',
          environment: {
            sys: {
              id: 'sandbox1',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 3,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'liftBlock'
            }
          },
          locale: 'fi-FI'
        },
        fields: {
          title: 'Inspiroidu Euroopan matkakohteista',
          mainLift: {
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'bh7k0sgo84rb'
                }
              },
              id: '4U5Svk1KwLZpoflI19UPsG',
              type: 'Entry',
              createdAt: '2019-08-21T13:29:04.642Z',
              updatedAt: '2019-11-08T16:36:21.914Z',
              environment: {
                sys: {
                  id: 'sandbox1',
                  type: 'Link',
                  linkType: 'Environment'
                }
              },
              revision: 12,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'destination'
                }
              },
              locale: 'fi-FI'
            },
            fields: {
              title: 'Lissabon',
              seoTitle: 'Lissabon',
              slug: 'lissabon',
              mainImage: {
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'bh7k0sgo84rb'
                    }
                  },
                  id: 'XqTteLBKsNKvpFQv96TyQ',
                  type: 'Asset',
                  createdAt: '2019-10-16T13:28:36.174Z',
                  updatedAt: '2019-10-16T13:28:36.174Z',
                  environment: {
                    sys: {
                      id: 'sandbox1',
                      type: 'Link',
                      linkType: 'Environment'
                    }
                  },
                  revision: 1,
                  locale: 'fi-FI'
                },
                fields: {
                  title: 'Lissabon',
                  file: {
                    url: '//localhost:3000/static/test/dummy-image.png',
                    details: {
                      size: 342650,
                      image: {
                        width: 1220,
                        height: 800
                      }
                    },
                    fileName: 'portugali-lissabon-linna-ss.jpg',
                    contentType: 'image/jpeg'
                  }
                }
              },
              country: 'Portugali',
              cityErpId: 1413,
              leadParagraph:
                'Erinomaiset rannat ja palvelujen läheisyys tekevät Portugalin pääkaupungista mainion kohteen jonne vetäytyä arjen kiireistä. Historiallisten kohteiden läheltä löydät myös leikkipaikkoja perheen pienimmille.',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis condimentum eros, eget maximus quam. Nulla lacinia justo sit amet libero maximus, vitae lobortis lorem interdum. Vestibulum nisl diam, laoreet vel malesuada eu, malesuada vel diam. Nam ac magna at nisi dignissim posuere in ac lectus. Curabitur at vulputate justo. Vivamus aliquam elit quis nunc dignissim, nec efficitur libero ultricies. Nulla scelerisque quis urna tempus lobortis. Nulla nec mollis lacus, a vulputate libero. Nullam sed lorem dignissim, sagittis justo eu, gravida enim. Ut molestie lobortis congue. Proin eleifend lectus non felis eleifend, eu tincidunt lacus ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum iaculis, nulla at convallis semper, urna tellus scelerisque enim, ac commodo ante velit mollis nibh. Ut viverra, massa facilisis tincidunt vulputate, lorem nunc tincidunt felis, id pellentesque nisi odio vel quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.\n\nDonec et gravida leo, vel rutrum nisi. Nulla tristique facilisis diam. Ut pellentesque ex id arcu varius porttitor. Nam vehicula fermentum lacus non iaculis. Vivamus nec finibus arcu. Cras ut sem ultricies, mollis ipsum in, feugiat diam. Praesent tristique laoreet elementum. Maecenas eleifend eleifend lorem eleifend molestie. Quisque faucibus massa at facilisis euismod. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pharetra ullamcorper interdum. Pellentesque tellus nisl, bibendum ut ullamcorper ac, eleifend id nibh. Cras condimentum tortor quis hendrerit faucibus. Ut convallis lorem vitae mollis dictum. Etiam sit amet scelerisque lacus. Etiam a orci suscipit, venenatis arcu ut, maximus massa.',
              trips: [
                {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '7r4R4BJIZTru7RxCIok4HZ',
                    type: 'Entry',
                    createdAt: '2019-08-21T13:29:10.602Z',
                    updatedAt: '2019-09-30T09:55:39.423Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 2,
                    contentType: {
                      sys: {
                        type: 'Link',
                        linkType: 'ContentType',
                        id: 'trip'
                      }
                    },
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Lissabonin kaupunkiloma',
                    erpTripId: 165
                  }
                }
              ]
            }
          },
          mainLiftTitle: 'Uusin Lomakode',
          bottomLifts: [
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '4NGGMKDbUykH0PzSSFDd84',
                type: 'Entry',
                createdAt: '2020-01-09T14:06:06.850Z',
                updatedAt: '2020-01-09T14:06:06.850Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Berliini',
                slug: 'berliini',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '6btFJxs7VWn8AeCJncdyR0',
                    type: 'Asset',
                    createdAt: '2020-01-09T14:04:10.992Z',
                    updatedAt: '2020-01-09T14:04:10.992Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 1,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Berliini',
                    description: 'Berliini, Saksa',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 28825,
                        image: {
                          width: 300,
                          height: 200
                        }
                      },
                      fileName: 'berliini-saksa-016-300x200.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Saksa',
                cityErpId: 856,
                leadParagraph: 'TBD',
                description: 'TBD',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: '4Y08aRL6TvxQBRRtJQgKot',
                      type: 'Entry',
                      createdAt: '2020-01-09T14:05:38.989Z',
                      updatedAt: '2020-01-09T14:05:38.989Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 1,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title: 'Berliinin kaupunkiloma',
                      erpTripId: 860
                    }
                  }
                ]
              }
            },
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '788g3WIRK24kSrkYGOlVLX',
                type: 'Entry',
                createdAt: '2020-01-09T13:43:24.019Z',
                updatedAt: '2020-01-09T13:43:24.019Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Barcelona',
                slug: 'barcelona',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '1OaDX27jIEzN2qYsapJxpG',
                    type: 'Asset',
                    createdAt: '2020-01-09T13:42:39.999Z',
                    updatedAt: '2020-01-09T13:42:55.754Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 2,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Barcelona',
                    description: 'Barcelona, Espanja',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 26776,
                        image: {
                          width: 300,
                          height: 200
                        }
                      },
                      fileName: 'barcelona-espanja-019-300x200.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Espanja',
                cityErpId: 1407,
                leadParagraph: 'TBD',
                description: 'TBD',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: '3CGT8DM1fF9WYt9wY87JKj',
                      type: 'Entry',
                      createdAt: '2020-01-09T13:39:36.059Z',
                      updatedAt: '2020-01-09T13:39:36.059Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 1,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title: 'Barcelonan kaupunkiloma',
                      erpTripId: 1010
                    }
                  }
                ]
              }
            },
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '5t6CErZ2t6qjwmaXhYLRr5',
                type: 'Entry',
                createdAt: '2020-01-08T14:07:46.742Z',
                updatedAt: '2020-01-08T14:07:46.742Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Lontoo',
                slug: 'lontoo',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '5Of3lkpLzFdF0eQQBbpa4C',
                    type: 'Asset',
                    createdAt: '2020-01-08T14:06:11.705Z',
                    updatedAt: '2020-01-08T14:06:37.568Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 2,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Lontoo',
                    description: 'Lontoo, Iso-Britannia',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 19552,
                        image: {
                          width: 300,
                          height: 200
                        }
                      },
                      fileName: 'lontoo-iso-britannia-011-300x200.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Iso-Britannia',
                cityErpId: 121,
                leadParagraph: 'TBD',
                description: 'TBD',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: '1cDN9awTJ1vylAYpnSJFg9',
                      type: 'Entry',
                      createdAt: '2020-01-08T14:07:34.996Z',
                      updatedAt: '2020-01-08T14:07:34.996Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 1,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title: 'Lontoon kaupunkiloma',
                      erpTripId: 842
                    }
                  }
                ]
              }
            },
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '7p9akpScyudqRXvBzLlIu8',
                type: 'Entry',
                createdAt: '2020-01-08T08:17:01.798Z',
                updatedAt: '2020-01-08T08:17:01.798Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Pietari',
                slug: 'pietari',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '4vTIzAjWILj4EyEszkz1Iz',
                    type: 'Asset',
                    createdAt: '2020-01-08T08:15:23.730Z',
                    updatedAt: '2020-01-08T08:16:39.945Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 2,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Kaupunkikuva Pietarista',
                    description: 'Pietari, Venäjä',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 22340,
                        image: {
                          width: 300,
                          height: 200
                        }
                      },
                      fileName: 'Venaja-Pietari-syksy-001-300x200.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Venäjä',
                cityErpId: 231,
                leadParagraph: 'TBD',
                description: 'TBD',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: '27aFXfCezhSz9CZRwyoP7q',
                      type: 'Entry',
                      createdAt: '2020-01-08T08:02:19.394Z',
                      updatedAt: '2020-01-08T08:02:19.394Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 1,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title:
                        'Pietarin hotellimatka 4 yötä, 2 laivalla+2 hotellissa',
                      erpTripId: 223
                    }
                  }
                ]
              }
            },
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '4RYOZbXaU9CDj2UEiFMWIw',
                type: 'Entry',
                createdAt: '2020-01-08T07:36:46.757Z',
                updatedAt: '2020-01-08T07:36:46.757Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Praha',
                slug: 'praha',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: '2DnnG73iLPlq7Ks0Uyr4A8',
                    type: 'Asset',
                    createdAt: '2020-01-08T07:36:05.395Z',
                    updatedAt: '2020-01-08T07:36:05.395Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 1,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Kaupunkikuva Praha',
                    description: 'Praha, Tsekki',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 32694,
                        image: {
                          width: 300,
                          height: 199
                        }
                      },
                      fileName: 'Praha-001-300x199.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Tsekki',
                cityErpId: 374,
                leadParagraph: 'TBD',
                description: 'TBD',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: 'eyP6A3ery5tjgwP3UubQI',
                      type: 'Entry',
                      createdAt: '2020-01-08T07:14:37.031Z',
                      updatedAt: '2020-01-08T07:14:37.031Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 1,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title: 'Prahan kaupunkiloma',
                      erpTripId: 988
                    }
                  }
                ]
              }
            },
            {
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'bh7k0sgo84rb'
                  }
                },
                id: '4U5Svk1KwLZpoflI19UPsG',
                type: 'Entry',
                createdAt: '2019-08-21T13:29:04.642Z',
                updatedAt: '2019-11-08T16:36:21.914Z',
                environment: {
                  sys: {
                    id: 'sandbox1',
                    type: 'Link',
                    linkType: 'Environment'
                  }
                },
                revision: 12,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'destination'
                  }
                },
                locale: 'fi-FI'
              },
              fields: {
                title: 'Lissabon',
                seoTitle: 'Lissabon',
                slug: 'lissabon',
                mainImage: {
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'bh7k0sgo84rb'
                      }
                    },
                    id: 'XqTteLBKsNKvpFQv96TyQ',
                    type: 'Asset',
                    createdAt: '2019-10-16T13:28:36.174Z',
                    updatedAt: '2019-10-16T13:28:36.174Z',
                    environment: {
                      sys: {
                        id: 'sandbox1',
                        type: 'Link',
                        linkType: 'Environment'
                      }
                    },
                    revision: 1,
                    locale: 'fi-FI'
                  },
                  fields: {
                    title: 'Lissabon',
                    file: {
                      url: '//localhost:3000/static/test/dummy-image.png',
                      details: {
                        size: 342650,
                        image: {
                          width: 1220,
                          height: 800
                        }
                      },
                      fileName: 'portugali-lissabon-linna-ss.jpg',
                      contentType: 'image/jpeg'
                    }
                  }
                },
                country: 'Portugali',
                cityErpId: 1413,
                leadParagraph:
                  'Erinomaiset rannat ja palvelujen läheisyys tekevät Portugalin pääkaupungista mainion kohteen jonne vetäytyä arjen kiireistä. Historiallisten kohteiden läheltä löydät myös leikkipaikkoja perheen pienimmille.',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer quis condimentum eros, eget maximus quam. Nulla lacinia justo sit amet libero maximus, vitae lobortis lorem interdum. Vestibulum nisl diam, laoreet vel malesuada eu, malesuada vel diam. Nam ac magna at nisi dignissim posuere in ac lectus. Curabitur at vulputate justo. Vivamus aliquam elit quis nunc dignissim, nec efficitur libero ultricies. Nulla scelerisque quis urna tempus lobortis. Nulla nec mollis lacus, a vulputate libero. Nullam sed lorem dignissim, sagittis justo eu, gravida enim. Ut molestie lobortis congue. Proin eleifend lectus non felis eleifend, eu tincidunt lacus ultricies. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum iaculis, nulla at convallis semper, urna tellus scelerisque enim, ac commodo ante velit mollis nibh. Ut viverra, massa facilisis tincidunt vulputate, lorem nunc tincidunt felis, id pellentesque nisi odio vel quam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.\n\nDonec et gravida leo, vel rutrum nisi. Nulla tristique facilisis diam. Ut pellentesque ex id arcu varius porttitor. Nam vehicula fermentum lacus non iaculis. Vivamus nec finibus arcu. Cras ut sem ultricies, mollis ipsum in, feugiat diam. Praesent tristique laoreet elementum. Maecenas eleifend eleifend lorem eleifend molestie. Quisque faucibus massa at facilisis euismod. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pharetra ullamcorper interdum. Pellentesque tellus nisl, bibendum ut ullamcorper ac, eleifend id nibh. Cras condimentum tortor quis hendrerit faucibus. Ut convallis lorem vitae mollis dictum. Etiam sit amet scelerisque lacus. Etiam a orci suscipit, venenatis arcu ut, maximus massa.',
                trips: [
                  {
                    sys: {
                      space: {
                        sys: {
                          type: 'Link',
                          linkType: 'Space',
                          id: 'bh7k0sgo84rb'
                        }
                      },
                      id: '7r4R4BJIZTru7RxCIok4HZ',
                      type: 'Entry',
                      createdAt: '2019-08-21T13:29:10.602Z',
                      updatedAt: '2019-09-30T09:55:39.423Z',
                      environment: {
                        sys: {
                          id: 'sandbox1',
                          type: 'Link',
                          linkType: 'Environment'
                        }
                      },
                      revision: 2,
                      contentType: {
                        sys: {
                          type: 'Link',
                          linkType: 'ContentType',
                          id: 'trip'
                        }
                      },
                      locale: 'fi-FI'
                    },
                    fields: {
                      title: 'Lissabonin kaupunkiloma',
                      erpTripId: 165
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  }
};
