import React from 'react';
import { Paths } from '../../urls/Paths';
import { CmsAsset } from '../../integrations/contentful/types';
import { formatDateRange } from '../../utils/date';
import {
  baseXInPixels,
  baseXUnit,
  imageHoverOpacity,
  linkStyle
} from '../../styles/theme';
import RouteLink from '../navigation/RouteLink';
import Paragraph from '../elements/Paragraph';
import CmsMedia from '../cms/CmsMedia';
import HyperLink from '../elements/HyperLink';

interface MemoryAuthorInfo {
  author: {
    name: string;
    picture: CmsAsset;
    slug: string;
  };
  memoryStartDate: string;
  memoryEndDate: string;
  noLink?: boolean;
}

const imageSize = baseXInPixels(4);
const MemoryAuthorInfo: React.FunctionComponent<MemoryAuthorInfo> = ({
  author,
  memoryStartDate,
  memoryEndDate,
  noLink
}) => {
  const startDate = new Date(memoryStartDate);
  const endDate = new Date(memoryEndDate);

  function renderContent(noLink: boolean) {
    return (
      <div className="memory-author-info">
        <div className="traveller-picture">
          <CmsMedia
            media={author.picture}
            width={imageSize}
            height={imageSize}
            borderRadius={7}
            crop
          />
        </div>
        <div className="author-details">
          <Paragraph data-cy="memory-author-name">
            <span className="author-name">{author.name}</span>
          </Paragraph>
          <Paragraph small data data-cy="memory-author-date">
            {formatDateRange(startDate, endDate)}
          </Paragraph>
        </div>
        {/*language=CSS*/}
        <style jsx>{`
          .traveller-picture {
            display: inline-block;
            margin-right: ${baseXUnit(1)};
            max-width: ${imageSize}px;
          }
          .author-details {
            display: inline-block;
            vertical-align: top;
          }
          .author-details > :global(div:first-child) {
            line-height: ${baseXUnit(2)};
          }
          /* Add hover styles only to the author name and image. */
          .memory-author-info .author-name {
            ${linkStyle.withAnimation}
          }
          .memory-author-info:hover .author-name {
            ${noLink ? '' : linkStyle.hover};
          }
          .memory-author-info:active .author-name {
            ${noLink ? '' : linkStyle.active}
          }
          .memory-author-info:hover :global(img) {
            ${noLink ? '' : `opacity: ${imageHoverOpacity};`}
          }
        `}</style>
      </div>
    );
  }

  return (
    <>
      {noLink ? (
        renderContent(true)
      ) : (
        <RouteLink
          path={Paths.Traveller}
          params={{
            slug: author.slug
          }}
        >
          <HyperLink data-testid="traveller-link">
            {renderContent(false)}
          </HyperLink>
        </RouteLink>
      )}
    </>
  );
};

export default MemoryAuthorInfo;
