// Taken from https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/generatePath.js
import { compile } from 'path-to-regexp';

const patternCache = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compileGenerator = (pattern: string) => {
  const cacheKey = pattern;
  // @ts-ignore
  const cache = patternCache[cacheKey] || (patternCache[cacheKey] = {});

  if (cache[pattern]) return cache[pattern];

  const compiledGenerator = compile(pattern);

  if (cacheCount < cacheLimit) {
    cache[pattern] = compiledGenerator;
    cacheCount++;
  }

  return compiledGenerator;
};

/**
 * Public API for generating a URL pathname from a pattern and parameters.
 */
export const generatePath = (
  pattern = '/',
  params: Record<string, string> = {}
) => {
  if (pattern === '/') {
    return pattern;
  }
  const generator = compileGenerator(pattern);
  return generator(params);
};

function removeTrailingSlash(string: string) {
  return string.replace(/\/$/, '');
}

/**
 * Public API for generating file pathname from a pattern and parameters.
 * Does not encode the resulting path with url-encoding.
 */
export const generateRoutePath = (
  pattern = '/',
  params: Record<string, string> = {}
) => {
  if (pattern === '/') {
    return pattern;
  }

  const generator = compileGenerator(pattern);
  const path = generator(params, { encode: (value: string) => value });
  // Route paths are used for fetching the bundle, and their path does not include a trailing slash.
  return removeTrailingSlash(path);
};
