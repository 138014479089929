import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Icon from '../common/Icon';
import DivButton from '../a11y/DivButton';
import { t } from '../../utils/i18n';
import Modal from '../common/Modal';
import { baseXUnit } from '../../styles/theme';
import { font, fontSize, fontWeight, spacing } from '../../styles/theme';
import { Paths } from '../../urls/Paths';
import HyperLink from '../elements/HyperLink';
import ModalMainMenuContent from './ModalMainMenuContent';
import Navbar from './Navbar';
import RouteLink from './RouteLink';
import TimLogo from './TimLogo';
import MainMenuInfoBox from './MainMenuInfoBox';
import { MainMenuElement } from './MainMenu';

interface ModalMainMenuProps {
  menuElements: MainMenuElement[];
}

const ModalMainMenu: React.FunctionComponent<ModalMainMenuProps> = ({
  menuElements
}) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  // If the route changes, close this main menu.
  // This is done so that the modal is not closed before the underlying content has changed in order to avoid confusion.
  const router = useRouter();
  useEffect(() => {
    close();
  }, [router]);

  return (
    <div className="modal-main-menu">
      <DivButton onClick={open} data-cy="modal-main-menu-open-button">
        <div className="root">
          <div className="name" data-testid="tim-name">
            Tim
          </div>
          <Icon
            name="nav-hamburger"
            size={4}
            screenReaderLabel={t('Avaa päävalikko')}
          />
        </div>
      </DivButton>
      <Modal
        isOpen={showDialog}
        onDismiss={close}
        ariaLabel={t('Päävalikko')}
        data-cy="main-menu-modal"
        animateIn
        variant="right"
      >
        <Navbar>
          <DivButton onClick={close}>
            <RouteLink path={Paths.Home}>
              <HyperLink className="modal-tim-container">
                <TimLogo size={4} />
              </HyperLink>
            </RouteLink>
          </DivButton>
          <DivButton onClick={close}>
            <Icon
              name="nav-close"
              size={4}
              screenReaderLabel={t('Sulje päävalikko')}
            />
          </DivButton>
        </Navbar>
        <div className="modal-container">
          <ModalMainMenuContent menuElements={menuElements} />
          <MainMenuInfoBox />
        </div>
      </Modal>
      {/*language=CSS*/}
      <style jsx>{`
        .root {
          display: flex;
        }
        .name {
          font-family: ${font.mono};
          font-size: ${fontSize.px20};
          font-weight: ${fontWeight.semiBold};
          line-height: ${spacing.four};
        }
        .modal-tim-container > :global(.h1) {
          line-height: ${baseXUnit(4)};
        }
      `}</style>
    </div>
  );
};

export default ModalMainMenu;
