// Our theme uses an 8px base grid (both horizontal and vertical).
// Every component on a page should have a height that is divisible by 8.
// Because of accessibility however, we allow
export const baseFontSizePx = 16;
const baseSizePx = 8;
export const baseSize = baseSizePx / baseFontSizePx;
export const baseUnit = 'rem';

function pxToRem(pixels: number) {
  return pixels / baseFontSizePx;
}

function appendUnit(value: string | number, unit?: string) {
  return `${value}${unit || baseUnit}`;
}

export function baseX(times: number) {
  return baseSize * times;
}

export function baseXInPixels(times: number) {
  return baseSizePx * times;
}

export function baseXUnit(times: number) {
  return appendUnit(baseX(times));
}

export const spacing = {
  fourth: baseXUnit(0.25),
  half: baseXUnit(0.5),
  one: baseXUnit(1),
  two: baseXUnit(2),
  three: baseXUnit(3),
  four: baseXUnit(4),
  five: baseXUnit(5),
  seven: baseXUnit(7)
};

export const columnTopSpacing = {
  sideColumn: 16,
  contentColumn: 5
};

const gridGutterX = 2;
export const grid = {
  padding: baseXUnit(gridGutterX / 2),
  gutter: baseXUnit(gridGutterX)
};

// These have to be updated manually based on how images are used
// within the app (DestinationCard and MemoryCard especially).
export const cardImageMaxWidthInPixels = 400;
export const cmsImageMaxAspectRatio = 2.5;

export const layoutMaxWidthBase = 170;

const layoutBreakpoints = {
  medium: '768px',
  large: '920px',
  extraLarge: '1140px'
};

export const breakpoints = {
  small: `screen and (max-width: ${layoutBreakpoints.medium})`,
  medium: `screen and (min-width: ${layoutBreakpoints.medium})`,
  large: `screen and (min-width: ${layoutBreakpoints.large})`,
  extraLarge: `screen and (min-width: ${layoutBreakpoints.extraLarge})`
};

export const font = {
  mono: "'IBM Plex Mono', monospace",
  spaceGrotesk: "'Space Grotesk'"
};

export const fontSize = {
  px10: appendUnit(pxToRem(10)),
  px11: appendUnit(pxToRem(11)),
  px12: appendUnit(pxToRem(12)),
  px13: appendUnit(pxToRem(13)),
  px14: appendUnit(pxToRem(14)),
  px16: appendUnit(pxToRem(16)),
  px18: appendUnit(pxToRem(18)),
  px20: appendUnit(pxToRem(20)),
  px24: appendUnit(pxToRem(24)),
  px28: appendUnit(pxToRem(28)),
  px32: appendUnit(pxToRem(32)),
  px36: appendUnit(pxToRem(36)),
  px48: appendUnit(pxToRem(48))
};

export const fontWeight = {
  // When adding new font weight, make sure our fonts supports different font weights.
  // You also might need load different font files for the new font weights.
  light: 300,
  regular: 400,
  semiBold: 600, // We map semibold to bold when using Space Grotesk font. Design decision by Jukka Hietamäki.
  bold: 700
};

export const letterSpacing = {
  wide: appendUnit(pxToRem(1.5))
};

export const color = {
  dark: '#333',
  light: '#ddd',
  lightBackground: '#f3f3f3',
  brand: '#003cb1',
  gray: '#7f7f7f',
  lightGray: '#eee',
  muted: '#aaa',
  purple: '#46007d',
  purpleLight: '#7c22c3',
  white: '#ffffff',
  tim: '#ffe619',
  pool: '#00e1be',
  poolVeryLight: '#e5fbf8'
};

export const zIndex = {
  navBar: 100,
  footer: 101,
  stickyBottomBox: 102,
  modal: 103
};

export const linkStyle = {
  withAnimation:
    'text-decoration: underline solid transparent; transition: text-decoration 0.2s ease;',
  hover: 'text-decoration: underline;',
  active: `color: ${color.purpleLight};`
};

export const imageHoverOpacity = '0.6';
