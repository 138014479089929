import { stringify } from 'query-string';

export function generatePathWithQueryString(
  base: string,
  query: Record<string, string | undefined> | Record<string, string[]> = {}
) {
  const queryString = stringify(query);
  return queryString ? `${base}?${queryString}` : base;
}

export default generatePathWithQueryString;
