import { generateRoutePath } from './generatePath';

const reservationBase = '/varaus';

export const Paths: Record<string, string> = {
  Home: '/',
  Help: '/tuki',
  HelpCategory: '/tuki/:slug',
  RobotsTxt: '/robots.txt',
  Sitemap: '/sitemap.xml',
  Memory: '/lomamuistot/:slug',
  MemoryList: '/lomamuistot',
  Destination: '/matkakohteet/:slug',
  DestinationList: '/matkakohteet',
  Reservation: reservationBase,
  CreateReservation: reservationBase + '#/createReservation',
  SearchResults: '/suositukset',
  Traveller: '/matkaajat/:slug',
  TravellerList: '/matkaajat'
};

const slug = { slug: '[slug]' };
export const RoutePathsForPaths = {
  [Paths.Home]: generateRoutePath(Paths.Home),
  [Paths.Help]: generateRoutePath(Paths.Help),
  [Paths.HelpCategory]: generateRoutePath(Paths.HelpCategory, slug),
  [Paths.RobotsTxt]: generateRoutePath(Paths.RobotsTxt),
  [Paths.Sitemap]: generateRoutePath(Paths.Sitemap),
  [Paths.Destination]: generateRoutePath(Paths.Destination, slug),
  [Paths.DestinationList]: generateRoutePath(Paths.DestinationList),
  [Paths.Memory]: generateRoutePath(Paths.Memory, slug),
  [Paths.MemoryList]: generateRoutePath(Paths.MemoryList),
  [Paths.Reservation]: generateRoutePath(Paths.Reservation),
  [Paths.CreateReservation]: generateRoutePath(Paths.CreateReservation),
  [Paths.SearchResults]: generateRoutePath(Paths.SearchResults),
  [Paths.Traveller]: generateRoutePath(Paths.Traveller, slug),
  [Paths.TravellerList]: generateRoutePath(Paths.TravellerList)
};
