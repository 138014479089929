import React from 'react';
import Spacing from '../common/Spacing';
import { t } from '../../utils/i18n';
import StaticTim from '../tim/StaticTim';
import Paragraph from '../elements/Paragraph';
import { baseXUnit } from '../../styles/theme';
import FlexDiv from '../common/FlexDiv';

const TimDisclaimer: React.FunctionComponent = () => {
  return (
    <div className="tim-disclaimer">
      <FlexDiv>
        <Spacing right={1.5}>
          <StaticTim size={3} />
        </Spacing>
        <div className="text">
          <Paragraph small data>
            {t(
              'Psst! Timin vastuullisena matkanjärjestäjänä toimii Ikaalisten Matkatoimisto'
            )}
          </Paragraph>
        </div>
      </FlexDiv>
      {/*language=CSS*/}
      <style jsx>{`
        .text {
          padding-top: ${baseXUnit(0.5)};
        }
      `}</style>
    </div>
  );
};

export default TimDisclaimer;
