import React from 'react';
import { IconName } from '../common/Icon';
import { Paths } from '../../urls/Paths';
import { t } from '../../utils/i18n';
import DisplayOn from '../common/DisplayOn';
import ModalMainMenu from './ModalMainMenu';
import HorizontalMainMenu from './HorizontalMainMenu';

const menuElements: MainMenuElement[] = [
  createMenuElement(
    Paths.Home,
    'travel-tim-chat',
    t('Tim — etusivu'),
    'Treffaa Tim ja löydä unohtumaton lomamuistosi',
    true
  ),
  createMenuElement(
    Paths.MemoryList,
    'travel-memory',
    t('Lomamuistot'),
    t('Selaa unohtumattomia lomakokemuksia'),
    false
  ),
  createMenuElement(
    Paths.DestinationList,
    'travel-pin',
    t('Matkakohteet'),
    t('Inspiroidu kohteista Euroopassa'),
    false
  ),
  createMenuElement(
    Paths.TravellerList,
    'travel-traveller',
    t('Matkaajat'),
    t('Tutustu kiinnostaviin Tim-matkaajiin'),
    false
  )
];

export interface MainMenuElement {
  path: string;
  query?: Record<string, string[]>;
  icon: IconName;
  label: string;
  description: string;
  isLinkToFrontPage: boolean;
}

function createMenuElement(
  path: string,
  icon: IconName,
  label: string,
  description: string,
  isLinkToFrontPage: boolean,
  query?: Record<string, string[]>
): MainMenuElement {
  return { path, query, icon, label, description, isLinkToFrontPage };
}

const MainMenu: React.FunctionComponent = () => {
  return (
    <div className="main-menu">
      <DisplayOn small>
        <ModalMainMenu menuElements={menuElements} />
      </DisplayOn>
      <DisplayOn medium large>
        <HorizontalMainMenu menuElements={menuElements} />
      </DisplayOn>
    </div>
  );
};

export default MainMenu;
