import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { CmsAsset } from '../../integrations/contentful/types';
import RenderAsync from '../common/RenderAsync';
import { t } from '../../utils/i18n';
import Spacing from '../common/Spacing';
import { RootState } from '../../redux/features';
import { selectVideo, videosActions } from '../../redux/features/videos';
import VimeoVideo from '../vimeo/VimeoVideo';
import VideoBorderRadius from '../common/VideoBorderRadius';
import {
  baseXInPixels,
  breakpoints,
  color,
  imageHoverOpacity
} from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

interface CmsVideoProps {
  video: CmsAsset;
  borderRadius?: ResponsiveProp<number>;
  width?: number;
  height?: number;
  background?: boolean;
  crop?: boolean;
  fill?: boolean;
  skeleton?: boolean;
  fixedHeight?: ResponsiveProp<number>;
  borderRadiusColor?: string;
  hover?: boolean;
}

const CmsVideo: React.FunctionComponent<CmsVideoProps> = ({
  video,
  width,
  height,
  borderRadius,
  background,
  fill,
  crop,
  skeleton = true,
  fixedHeight,
  borderRadiusColor,
  hover
}) => {
  const fixedHeightValue =
    propToResponsivePropObject(fixedHeight).mapDefined(baseXInPixels);
  const contentfulAssetId = video.sys.id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      videosActions.fetchVideoIfNotFetched({
        contentfulAssetId
      })
    );
  }, [dispatch, contentfulAssetId]);
  const videoLoadable = useSelector((state: RootState) =>
    selectVideo(state, contentfulAssetId)
  );
  const videoInfo = videoLoadable && videoLoadable.video;

  const videoAspectRatio = videoInfo && videoInfo.width / videoInfo.height;
  const videoWidth = width || (videoInfo ? videoInfo.width : 0);
  const videoHeight = height || (videoInfo ? videoInfo.height : 0);
  const wrapperAspectRatio =
    videoWidth && videoHeight ? videoWidth / videoHeight : 0;

  return (
    <div
      className={classNames('cms-video', {
        crop,
        fill,
        background,
        skeleton,
        hover,
        'fixed-height': fixedHeight
      })}
    >
      <div className="responsive-wrapper">
        {!!borderRadius && (
          <VideoBorderRadius size={borderRadius} color={borderRadiusColor} />
        )}
        <div className="video-event-catcher" />
        <RenderAsync
          state={videoLoadable && videoLoadable.loadingState}
          onLoading={() => null}
        >
          {videoInfo && videoInfo.ready ? (
            <InView threshold={background ? 0.01 : 0.5}>
              {({ inView, ref }) => (
                <div ref={ref} className="video-wrapper">
                  <VimeoVideo
                    videoId={videoInfo.videoId}
                    title={video.fields.description}
                    background={background}
                    play={inView}
                  />
                </div>
              )}
            </InView>
          ) : (
            <div>
              <Spacing top={2} right={2} bottom={2} left={2}>
                {t('Videota käsitellään. Odota hetki ja päivitä sivu.')}
              </Spacing>
            </div>
          )}
        </RenderAsync>
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        /* Takes care of max-width/max-height (cf. below) */
        .cms-video {
          overflow: hidden;
        }
        .hover:hover {
          opacity: ${imageHoverOpacity};
        }
        /* Takes care that the video scales responsively within the given width / height aspect ratio = crops the video */
        .responsive-wrapper {
          width: 100%;
          position: relative;
          z-index: 0;
        }

        .cms-video.skeleton .responsive-wrapper {
          /* Background skeleton during loading */
          background: ${color.lightGray};
        }

        /* Position all loaders correctly */
        .responsive-wrapper > :global(div:not(.video-wrapper)) {
          position: absolute;
          left: 0;
          top: 0;
        }

        /* Prevents clicks to videos in order to allow swipe events etc. */
        .video-event-catcher {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: transparent;
        }

        /* Takes care that the video is 100% of the width of the container */
        .video-wrapper {
          position: absolute;
          width: 100%;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        .video-wrapper :global(iframe) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }

        .crop .responsive-wrapper {
          overflow: hidden;
        }

        .cms-video.fill,
        .fill .responsive-wrapper,
        .fill .video-wrapper,
        .fill :global(iframe) {
          width: 100%;
          height: 100%;
        }

        .fixed-height .responsive-wrapper {
          padding-top: 0;
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .cms-video {
          ${width ? `max-width: ${width}px;` : ''}
          ${height ? `max-height: ${height}px;` : ''}
        }

        /* We utilize padding-top "aspect ratio" here (and below). Cf. https://css-tricks.com/aspect-ratio-boxes/*/
        .responsive-wrapper {
          ${!fill && wrapperAspectRatio
            ? `padding-top: ${(1 / wrapperAspectRatio) * 100}%`
            : ''};
        }

        .video-wrapper {
          ${!fill && videoInfo
            ? `padding-top: ${(videoInfo.height / videoInfo.width) * 100}%`
            : ''};
        }

        /* We need to force min-width and min-height based on the aspect ratio of the video
             in order to make sure that it always fills the container */
        .crop .video-wrapper {
          ${height && videoAspectRatio
            ? `min-height: ${Math.max(
                height * (1 / videoAspectRatio),
                height
              )}px;`
            : ''}
          ${width && videoAspectRatio
            ? `min-width: ${Math.max(width * videoAspectRatio, width)}px;`
            : ''}
        }

        .fixed-height .responsive-wrapper {
          ${fixedHeightValue.small
            ? `height: ${fixedHeightValue.small}px`
            : ''};
        }
        .fixed-height .video-wrapper {
          /* Prevent the video from becoming so small that it's height becomes smaller than fixedHeight*/
          ${fixedHeightValue.small
            ? `min-height: ${fixedHeightValue.small}px`
            : ''};
          ${fixedHeightValue.small && videoInfo
            ? `min-width: ${
                fixedHeightValue.small * (videoInfo.width / videoInfo.height)
              }px`
            : ''};
        }

        @media ${breakpoints.medium} {
          .fixed-height .responsive-wrapper {
            ${fixedHeightValue.medium
              ? `height: ${fixedHeightValue.medium}px`
              : ''};
          }
          .fixed-height .video-wrapper {
            /* Prevent the video from becoming so medium that it's height becomes mediumer than fixedHeight*/
            ${fixedHeightValue.medium
              ? `min-height: ${fixedHeightValue.medium}px`
              : ''};
            ${fixedHeightValue.medium && videoInfo
              ? `min-width: ${
                  fixedHeightValue.medium * (videoInfo.width / videoInfo.height)
                }px`
              : ''};
          }
        }

        @media ${breakpoints.large} {
          .fixed-height .responsive-wrapper {
            ${fixedHeightValue.large
              ? `height: ${fixedHeightValue.large}px`
              : ''};
          }
          .fixed-height .video-wrapper {
            /* Prevent the video from becoming so large that it's height becomes largeer than fixedHeight*/
            ${fixedHeightValue.large
              ? `min-height: ${fixedHeightValue.large}px`
              : ''};
            ${fixedHeightValue.large && videoInfo
              ? `min-width: ${
                  fixedHeightValue.large * (videoInfo.width / videoInfo.height)
                }px`
              : ''};
          }
        }

        @media ${breakpoints.extraLarge} {
          .fixed-height .responsive-wrapper {
            ${fixedHeightValue.extraLarge
              ? `height: ${fixedHeightValue.extraLarge}px`
              : ''};
          }
          .fixed-height .video-wrapper {
            /* Prevent the video from becoming so large that it's height becomes largeer than fixedHeight*/
            ${fixedHeightValue.extraLarge
              ? `min-height: ${fixedHeightValue.extraLarge}px`
              : ''};
            ${fixedHeightValue.extraLarge && videoInfo
              ? `min-width: ${
                  fixedHeightValue.extraLarge *
                  (videoInfo.width / videoInfo.height)
                }px`
              : ''};
          }
        }
      `}</style>
    </div>
  );
};

export default CmsVideo;
