import React from 'react';
import { animated } from 'react-spring';
import { useTestableSpring } from '../module-overrides/react-spring';

const FadeAnimation: React.FunctionComponent = ({ children, ...rest }) => {
  const props = useTestableSpring({
    from: { opacity: 0 },
    to: { opacity: 1 }
  });
  return (
    <animated.div style={props} {...rest}>
      {children}
    </animated.div>
  );
};

export default FadeAnimation;
