import { createClient } from 'contentful';
import compact from 'lodash/compact';
import { configuration } from '../../utils/environment';
import { QuestionCollection } from '../../redux/features/questions';
import { Answers } from '../../search/SearchAnswers';
import {
  QuestionCollection as ContentfulQuestionCollection,
  ContentPage,
  Destination,
  HelpPageCategory,
  MainHelpPage,
  Memory,
  SiteSettings,
  Traveller
} from './apiTypes';
import {
  CmsContentPage,
  CmsContentTypes,
  CmsDestination,
  CmsHelpPageCategory,
  CmsMainHelpPage,
  CmsMemory,
  CmsQuestionCollection,
  CmsSiteSettings,
  instanceOfCmsLiftBlock,
  instanceOfCmsPhase,
  TravellerWithMemories
} from './types';

const client = createClient({
  space: 'bh7k0sgo84rb',
  accessToken: configuration.contentful.accessToken,
  environment: configuration.contentful.environment,
  host: configuration.contentful.host,
  // This flag will prevent e.g. drafts from being partially leaked to the frontend
  removeUnresolved: true
});

export async function fetchContentPage(
  slug: string
): Promise<CmsContentPage | undefined> {
  const collection = await client.getEntries<ContentPage>({
    content_type: CmsContentTypes.ContentPage,
    include: 3,
    'fields.slug': slug
  });
  const [contentPage] = collection.items;
  if (contentPage && contentPage.fields.blocks) {
    await Promise.all(
      contentPage.fields.blocks
        .filter(instanceOfCmsLiftBlock)
        .filter((block) => instanceOfCmsPhase(block.fields.mainLift))
        .map(async (block) => {
          if (!block.fields.mainLift) {
            return;
          }
          const memories = await fetchMemoriesByLinkedEntry(
            block.fields.mainLift.sys.id
          );
          const [memory] = memories;
          block.memory = memory;
        })
    );
  }
  return contentPage;
}

function transformCmsQuestionCollection(
  cmsQuestionCollection?: CmsQuestionCollection
): QuestionCollection {
  const questions =
    (cmsQuestionCollection &&
      cmsQuestionCollection.fields &&
      cmsQuestionCollection.fields.questions) ||
    [];
  return questions.map((question) => {
    const { questionText, key, label, shortQuestionText, mandatory } =
      question.fields;
    const options = question.fields.options.map((option) => {
      const { value, label, replies } = option.fields;
      return {
        value,
        label,
        replies: (replies as string[]) || []
      };
    });
    return {
      questionText,
      key,
      options,
      label,
      shortQuestionText,
      mandatory
    };
  });
}

function transformToFilterParams(searchAnswers: Answers, prefix: string) {
  const filterParams: Record<string, string> = {};
  Object.keys(searchAnswers).forEach((key) => {
    const answers = searchAnswers[key];
    // Just pick the first filter value, because there should not be more.
    // In future we might want to have multiple filter values. Then we would need to use fields.key[in].
    filterParams[prefix + key] = answers[0];
  });
  return filterParams;
}

export async function fetchQuestionCollection(
  key: string
): Promise<QuestionCollection> {
  const collection = await client.getEntries<ContentfulQuestionCollection>({
    content_type: CmsContentTypes.QuestionCollection,
    include: 2,
    'fields.key': key
  });
  const [questionCollection] = collection.items;
  return transformCmsQuestionCollection(questionCollection);
}

export async function fetchMemory(
  slug: string
): Promise<CmsMemory | undefined> {
  const collection = await client.getEntries<Memory>({
    content_type: CmsContentTypes.Memory,
    include: 2,
    'fields.slug': slug
  });
  const [memory] = collection.items;
  return memory;
}

export async function fetchDestination(
  slug: string
): Promise<CmsDestination | undefined> {
  const collection = await client.getEntries<Destination>({
    content_type: CmsContentTypes.Destination,
    include: 1,
    'fields.slug': slug
  });
  const [destination] = collection.items;
  return destination;
}

export async function fetchMemoriesByLinkedEntry(entryId: string) {
  const memories = await client.getEntries<Memory>({
    content_type: CmsContentTypes.Memory,
    links_to_entry: entryId,
    include: 1
  });

  return memories.items;
}

export async function fetchMemories(searchAnswers?: Answers, limit = 1000) {
  const filteringParams = transformToFilterParams(
    searchAnswers || {},
    'fields.'
  );
  const collection = await client.getEntries<Memory>({
    content_type: CmsContentTypes.Memory,
    include: 1,
    limit: limit,
    order: '-sys.createdAt',
    ...filteringParams
  });

  return collection.items;
}

export async function fetchDestinations(searchAnswers?: Answers) {
  const filteringParams = transformToFilterParams(
    searchAnswers || {},
    'fields.'
  );
  const collection = await client.getEntries<Destination>({
    content_type: CmsContentTypes.Destination,
    limit: 1000,
    order: 'fields.title',
    ...filteringParams
  });

  return collection.items;
}

export async function fetchAllTravellersWithMemories() {
  const travellerCollection = await client.getEntries<Traveller>({
    content_type: CmsContentTypes.Traveller,
    include: 1,
    limit: 1000,
    order: '-sys.createdAt'
  });

  const travellersWithMemories = await Promise.all(
    travellerCollection.items.map(async (traveller) => {
      try {
        const travellerMemoriesCollection = await client.getEntries<Memory>({
          content_type: CmsContentTypes.Memory,
          links_to_entry: traveller.sys.id,
          include: 1
        });
        return { ...traveller, memories: travellerMemoriesCollection.items };
      } catch (err) {
        // Do not stop loading everything if a single request fails
        return undefined;
      }
    })
  );

  return compact(travellersWithMemories);
}

export async function fetchAllMemoriesForSitemap() {
  return fetchCmsEntriesForSitemap(CmsContentTypes.Memory);
}

export async function fetchTravellerWithMemories(
  slug: string
): Promise<TravellerWithMemories | undefined> {
  const travellerCollection = await client.getEntries<Traveller>({
    content_type: CmsContentTypes.Traveller,
    'fields.slug': slug
  });
  const [traveller] = travellerCollection.items;
  if (!traveller) {
    return undefined;
  }

  const travellerMemoriesCollection = await client.getEntries<Memory>({
    content_type: CmsContentTypes.Memory,
    links_to_entry: traveller.sys.id,
    include: 1
  });

  return { ...traveller, memories: travellerMemoriesCollection.items };
}

export async function fetchAllTravellersForSitemap() {
  return fetchCmsEntriesForSitemap(CmsContentTypes.Traveller);
}

export async function fetchAllDestinationsForSitemap() {
  return fetchCmsEntriesForSitemap(CmsContentTypes.Destination);
}

async function fetchCmsEntriesForSitemap(contentType: CmsContentTypes) {
  const cmsEntries = await client.getEntries<Memory | Traveller>({
    content_type: contentType,
    include: 0,
    limit: 1000,
    select: 'sys.updatedAt,fields.slug'
  });

  if (cmsEntries.total >= 1000) {
    console.error(
      'CMS query limit limit of 1000 records reached, sitemap implementation should be rewritten.'
    );
  }

  return cmsEntries.items.map(({ sys, fields }) => {
    return {
      slug: fields.slug,
      updatedAt: sys.updatedAt
    };
  });
}

export async function fetchMainHelpPage(): Promise<
  CmsMainHelpPage | undefined
> {
  const slug = 'tuki'; // There is only one main help page with slug always being the same
  const collection = await client.getEntries<MainHelpPage>({
    content_type: CmsContentTypes.MainHelpPage,
    include: 1,
    'fields.slug': slug
  });
  return collection.items[0];
}

export async function fetchHelpPageCategories(
  slug?: string
): Promise<CmsHelpPageCategory[]> {
  const collection = await client.getEntries<HelpPageCategory>({
    content_type: CmsContentTypes.HelpPageCategory,
    include: 1,
    'fields.slug': slug
  });
  return collection.items;
}

export async function fetchAllHelpPageCategoriesForSitemap() {
  return fetchCmsEntriesForSitemap(CmsContentTypes.HelpPageCategory);
}

export async function fetchSiteSettings(): Promise<
  CmsSiteSettings | undefined
> {
  const slug = 'asetukset'; // There is only one site settings and its slug is always this one
  const collection = await client.getEntries<SiteSettings>({
    content_type: CmsContentTypes.SiteSettings,
    include: 1,
    'fields.slug': slug
  });
  return collection.items[0];
}
