import React, { SyntheticEvent, useCallback } from 'react';
import classNames from 'classnames';

interface DivButtonProps {
  onClick: (event: SyntheticEvent) => void;
  disabled?: boolean;
}

const DivButton: React.FunctionComponent<
  DivButtonProps & React.HTMLAttributes<HTMLElement>
> = ({ onClick, disabled, children, className, ...rest }) => {
  const onKeyDown = useCallback(
    (event) => {
      // Cf. https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role#Keyboard_interactions
      if (event.key === ' ' || event.key === 'Enter') {
        onClick(event);
      }
    },
    [onClick]
  );
  return (
    <div
      onClick={disabled ? undefined : onClick}
      role="button"
      tabIndex={0}
      onKeyDown={disabled ? undefined : onKeyDown}
      className={classNames('div-button', className, { disabled: disabled })}
      {...rest}
    >
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .div-button {
          user-select: none;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default DivButton;
