import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../grid/Container';
import Col from '../grid/Col';
import Row from '../grid/Row';
import H from '../elements/H';
import { t } from '../../utils/i18n';
import Spacing from '../common/Spacing';
import IconText from '../common/IconText';
import Paragraph from '../elements/Paragraph';
import Icon from '../common/Icon';
import FlexDiv from '../common/FlexDiv';
import TimDisclaimer from '../tim/TimDisclaimer';
import HelpPageButton from '../common/HelpPageButton';
import HorizontalLine from '../common/HorizontalLine';
import RenderAsync from '../common/RenderAsync';
import {
  selectSiteSettings,
  siteSettingsActions,
  SiteSettingsLoadable
} from '../../redux/features/siteSettings';
import { zIndex } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';
import HyperLink from '../elements/HyperLink';

export interface FooterProps {
  footerExtraSpacing?: ResponsiveProp<number>;
}

export interface SocialMediaIconsAsyncProps {
  siteSettingsLoadable: SiteSettingsLoadable;
}

export interface SocialMediaIconsProps {
  facebookUrl?: string;
  instagramUrl?: string;
}

export const SocialMediaIcons: React.FunctionComponent<SocialMediaIconsProps> =
  ({ facebookUrl, instagramUrl }) => {
    return (
      <FlexDiv>
        {instagramUrl && (
          <Spacing right={1}>
            <HyperLink href={instagramUrl} data-testid="social-media-link">
              <Icon name="travel-instagram" size={4} color={'dark'} />
            </HyperLink>
          </Spacing>
        )}
        {facebookUrl && (
          <HyperLink href={facebookUrl} data-testid="social-media-link">
            <Icon name="travel-facebook" size={4} color={'dark'} />
          </HyperLink>
        )}
      </FlexDiv>
    );
  };

const SocialMediaIconsAsync: React.FunctionComponent<SocialMediaIconsAsyncProps> =
  ({ siteSettingsLoadable }) => {
    return (
      <RenderAsync
        state={siteSettingsLoadable && siteSettingsLoadable.loadingState}
      >
        {() => (
          <SocialMediaIcons
            facebookUrl={siteSettingsLoadable.settings.facebookUrl}
            instagramUrl={siteSettingsLoadable.settings.instagramUrl}
          />
        )}
      </RenderAsync>
    );
  };

export interface CustomerSupportProps {
  siteSettingsLoadable: SiteSettingsLoadable;
}

const CustomerSupport: React.FunctionComponent<CustomerSupportProps> = ({
  siteSettingsLoadable
}) => {
  return (
    <RenderAsync
      state={siteSettingsLoadable && siteSettingsLoadable.loadingState}
    >
      {() => (
        <div className="customer-support" data-testid="customer-support">
          <Spacing bottom={3}>
            <HyperLink
              href={'tel:' + siteSettingsLoadable.settings.supportPhoneNumber}
              data-testid="contact-line-link"
            >
              <IconText
                icon="contact-customer-service"
                iconSize={4}
                iconColor="purple-light"
              >
                <H styling="h4" data tag="div">
                  {siteSettingsLoadable.settings.supportPhoneNumber}
                </H>
              </IconText>
            </HyperLink>
            <HyperLink
              href={'mailto:' + siteSettingsLoadable.settings.supportEmail}
              data-testid="contact-line-link"
            >
              <IconText
                icon="contact-email"
                iconSize={4}
                iconColor="purple-light"
              >
                <H styling="h4" data tag="div">
                  {siteSettingsLoadable.settings.supportEmail}
                </H>
              </IconText>
            </HyperLink>
          </Spacing>
          <Paragraph small data>
            {t('Asiakaspalvelumme palvelee arkisin klo ')}
            {siteSettingsLoadable.settings.supportOfficeHours}.
          </Paragraph>
        </div>
      )}
    </RenderAsync>
  );
};

const Footer: React.FunctionComponent<FooterProps> = ({
  footerExtraSpacing
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(siteSettingsActions.fetchSiteSettingsIfNotFetched());
  }, [dispatch]);
  const siteSettingsLoadable = useSelector(selectSiteSettings);
  const bottomExtraSpacing = propToResponsivePropObject(footerExtraSpacing);
  const footerBottomSpacingSmall = bottomExtraSpacing.small
    ? 2 + bottomExtraSpacing.small
    : 2;
  const footerBottomSpacingMedium = bottomExtraSpacing.medium
    ? 4.5 + bottomExtraSpacing.medium
    : 4.5;
  const footerBottomSpacingLarge = bottomExtraSpacing.large
    ? 4.5 + bottomExtraSpacing.large
    : 4.5;
  const footerBottomSpacingExtraLarge = bottomExtraSpacing.extraLarge
    ? 4.5 + bottomExtraSpacing.extraLarge
    : 4.5;

  return (
    <div className="footer" data-testid="footer">
      <Spacing
        top={4}
        bottom={[
          footerBottomSpacingSmall,
          footerBottomSpacingMedium,
          footerBottomSpacingLarge,
          footerBottomSpacingExtraLarge
        ]}
      >
        <Container>
          <Row>
            <Spacing bottom={2}>
              <Col size={[24, 24, 24]}>
                <H styling="h5" color="gray">
                  {t('Ota yhteyttä')}
                </H>
              </Col>
            </Spacing>
          </Row>
          <Row>
            <Col size={[24, 6, 6]}>
              <CustomerSupport siteSettingsLoadable={siteSettingsLoadable} />
            </Col>
            <Col size={[24, 0, 0]}>
              <Spacing top={1}>
                <HorizontalLine />
              </Spacing>
              <Spacing top={2}>
                <HelpPageButton />
              </Spacing>
              <Spacing top={2}>
                <TimDisclaimer />
              </Spacing>
              <Spacing top={2.5}>
                <SocialMediaIconsAsync
                  siteSettingsLoadable={siteSettingsLoadable}
                />
              </Spacing>
            </Col>
            <Col size={[0, 1, 0]}></Col>
            <Col size={[0, 4, 5]}>
              <SocialMediaIconsAsync
                siteSettingsLoadable={siteSettingsLoadable}
              />
            </Col>
            <Col size={[0, 8, 8]}>
              <TimDisclaimer />
            </Col>
            <Col size={[0, 5, 5]}>
              <HelpPageButton />
            </Col>
          </Row>
        </Container>
      </Spacing>
      {/*language=CSS*/}
      <style jsx>{`
        .footer {
          z-index: ${zIndex.footer};
        }
      `}</style>
    </div>
  );
};

export default Footer;
