import React, { ReactNode } from 'react';
import isFunction from 'lodash/isFunction';
import { t } from '../../utils/i18n';
import { baseXUnit } from '../../styles/theme';
import { LoadingState } from '../../loading/loadable';

type RenderFunction = () => ReactNode;

export interface RenderAsyncProps {
  state?: LoadingState;
  onLoading?: RenderFunction;
  onError?: RenderFunction;
}

export const testIds = {
  defaultError: 'default-error',
  defaultLoader: 'default-loader'
};

const renderError = (): ReactNode => (
  <div className="root" data-testid={testIds.defaultError}>
    {t('Oho! Lataamisessa tapahtui virhe.')}
    {/*language=CSS*/}
    <style jsx>{`
      .root {
        padding: ${baseXUnit(2)};
      }
    `}</style>
  </div>
);
const renderLoading = (): ReactNode => (
  <div className="root" data-testid={testIds.defaultLoader}>
    {t('Ladataan...')}
    {/*language=CSS*/}
    <style jsx>{`
      .root {
        padding: ${baseXUnit(2)};
      }
    `}</style>
  </div>
);

const RenderAsync: React.FunctionComponent<RenderAsyncProps> = ({
  state,
  onLoading = renderLoading,
  onError = renderError,
  children
}) => {
  switch (state) {
    case LoadingState.DONE:
      return <>{isFunction(children) ? children() : children}</>;
    case LoadingState.FAILED:
      return <>{onError()}</>;
    case LoadingState.STARTED:
    default:
      return <>{onLoading()}</>;
  }
};

export default RenderAsync;
