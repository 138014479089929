import React from 'react';
import H from '../elements/H';
import Spacing from '../common/Spacing';
import { spacing } from '../../styles/theme';
import HyperLink from '../elements/HyperLink';
import RouteLink from './RouteLink';
import TimLogo from './TimLogo';
import { MainMenuElement } from './MainMenu';

interface HorizontalMainMenuProps {
  menuElements: MainMenuElement[];
}

const HorizontalMainMenu: React.FunctionComponent<HorizontalMainMenuProps> = ({
  menuElements
}) => {
  return (
    <div className="horizontal-main-menu" data-cy="horizontal-main-menu">
      {menuElements.map((menuElement, index) => {
        const isLastItem = index + 1 === menuElements.length;
        return (
          <Spacing
            right={isLastItem ? 0 : menuElement.isLinkToFrontPage ? 8 : 4}
            top={[
              0,
              menuElement.isLinkToFrontPage ? 0 : 1.95,
              menuElement.isLinkToFrontPage ? 0 : 1.95,
              menuElement.isLinkToFrontPage ? 0 : 1.35
            ]}
            key={menuElement.label}
          >
            <RouteLink path={menuElement.path} query={menuElement.query}>
              <HyperLink display="block">
                {menuElement.isLinkToFrontPage && (
                  <div className="tim-logo-wrapper" data-testid="tim-logo">
                    <TimLogo size={4} />
                  </div>
                )}
                {!menuElement.isLinkToFrontPage && (
                  <H styling="h4" tag="div" data-testid="text-menu-element">
                    <strong>{menuElement.label}</strong>
                  </H>
                )}
              </HyperLink>
            </RouteLink>
          </Spacing>
        );
      })}
      {/*language=CSS*/}
      <style jsx>{`
        .horizontal-main-menu {
          display: flex;
          padding-top: ${spacing.fourth}; // Align with the back button
        }
      `}</style>
    </div>
  );
};

export default HorizontalMainMenu;
