import React from 'react';
import { baseXUnit, breakpoints } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp,
  ResponsivePropObject
} from '../../utils/layoutUtils';

interface SpacingProps {
  bottom?: ResponsiveProp<number | 'auto'>;
  top?: ResponsiveProp<number | 'auto'>;
  left?: ResponsiveProp<number | 'auto'>;
  right?: ResponsiveProp<number | 'auto'>;
}

function outputMargin(
  marginRule: string,
  values: ResponsivePropObject<string>,
  breakpointName: 'small' | 'medium' | 'large' | 'extraLarge'
) {
  return `${
    values[breakpointName] ? `${marginRule}: ${values[breakpointName]};` : ''
  }`;
}

function baseXUnitOrAuto(times: number | 'auto') {
  return times === 'auto' ? 'auto' : baseXUnit(times);
}

const Spacing: React.FunctionComponent<SpacingProps> = ({
  bottom,
  top,
  right,
  left,
  children
}) => {
  const bottomValues =
    propToResponsivePropObject(bottom).mapDefined(baseXUnitOrAuto);
  const topValues = propToResponsivePropObject(top).mapDefined(baseXUnitOrAuto);
  const rightValues =
    propToResponsivePropObject(right).mapDefined(baseXUnitOrAuto);
  const leftValues =
    propToResponsivePropObject(left).mapDefined(baseXUnitOrAuto);

  return (
    <div className="spacing">
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .spacing {
          ${outputMargin('margin-bottom', bottomValues, 'small')}
          ${outputMargin('margin-top', topValues, 'small')}
          ${outputMargin('margin-right', rightValues, 'small')}
          ${outputMargin('margin-left', leftValues, 'small')}
        }

        @media ${breakpoints.medium} {
          .spacing {
            ${outputMargin('margin-bottom', bottomValues, 'medium')}
            ${outputMargin('margin-top', topValues, 'medium')}
            ${outputMargin('margin-right', rightValues, 'medium')}
            ${outputMargin('margin-left', leftValues, 'medium')}
          }
        }

        @media ${breakpoints.large} {
          .spacing {
            ${outputMargin('margin-bottom', bottomValues, 'large')}
            ${outputMargin('margin-top', topValues, 'large')}
            ${outputMargin('margin-right', rightValues, 'large')}
            ${outputMargin('margin-left', leftValues, 'large')}
          }
        }

        @media ${breakpoints.extraLarge} {
          .spacing {
            ${outputMargin('margin-bottom', bottomValues, 'extraLarge')}
            ${outputMargin('margin-top', topValues, 'extraLarge')}
            ${outputMargin('margin-right', rightValues, 'extraLarge')}
            ${outputMargin('margin-left', leftValues, 'extraLarge')}
          }
        }
      `}</style>
    </div>
  );
};

export default Spacing;
