import actionCreatorFactory, {
  Action,
  AnyAction,
  Failure,
  Success
} from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap
} from 'rxjs/operators';
import { ofAction } from 'typescript-fsa-redux-observable';
import { combineEpics, Epic } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import {
  LoadingState,
  shouldBeLoaded,
  WithLoadingState
} from '../../loading/loadable';
import { caseProduce } from '../util';
import {
  fetchDestinations,
  fetchMemories
} from '../../integrations/contentful/queries';
import { CmsDestination, CmsMemory } from '../../integrations/contentful/types';
import { Answers } from '../../search/SearchAnswers';
import { RootState } from './index';

export interface SearchResults {
  memories: CmsMemory[];
  destinations: CmsDestination[];
}

export type SearchResultsLoadable = {
  results: SearchResults;
} & WithLoadingState;

export const getSearchAnswersKey = (searchAnswers: Answers): string => {
  return JSON.stringify(searchAnswers);
};

// Actions
interface FetchSearchResultsParams {
  searchAnswers: Answers;
}
const actionCreator = actionCreatorFactory('searchResults');
export const searchResultActions = {
  fetchSearchResultsIfNotFetched: actionCreator<FetchSearchResultsParams>(
    'MAYBE_FETCH_SEARCH_RESULTS'
  ),
  fetchSearchResults: actionCreator.async<
    FetchSearchResultsParams,
    SearchResults
  >('FETCH_SEARCH_RESULTS')
};

// State
export type SearchResultsState = Record<string, SearchResultsLoadable>;
export const initialState: SearchResultsState = {};

// Reducer
export default reducerWithInitialState(initialState)
  .case(
    searchResultActions.fetchSearchResults.started,
    caseProduce((draftState, payload) => {
      const key = getSearchAnswersKey(payload.searchAnswers);
      draftState[key] = {
        loadingState: LoadingState.STARTED,
        results: { memories: [], destinations: [] }
      };
    })
  )
  .case(
    searchResultActions.fetchSearchResults.done,
    caseProduce((draftState, payload) => {
      const key = getSearchAnswersKey(payload.params.searchAnswers);
      draftState[key] = {
        loadingState: LoadingState.DONE,
        results: payload.result
      };
    })
  )
  .case(
    searchResultActions.fetchSearchResults.failed,
    caseProduce((draftState, payload) => {
      const key = getSearchAnswersKey(payload.params.searchAnswers);
      draftState[key] = {
        loadingState: LoadingState.FAILED,
        results: { memories: [], destinations: [] }
      };
    })
  );

// Selectors
export const selectSearchResults = (
  state: RootState,
  searchAnswers: Answers
): SearchResultsLoadable => {
  const key = getSearchAnswersKey(searchAnswers);
  return state.searchResults[key];
};

// Epics
const fetchSearchResultsIfNotFetchedEpic: Epic<
  AnyAction,
  Action<FetchSearchResultsParams>,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofAction(searchResultActions.fetchSearchResultsIfNotFetched),
    filter((action) => {
      const searchResults = selectSearchResults(
        state$.value,
        action.payload.searchAnswers
      );
      return shouldBeLoaded(searchResults);
    }),
    map((action) => {
      return searchResultActions.fetchSearchResults.started({
        searchAnswers: action.payload.searchAnswers
      });
    })
  );

const fetchSearchResultsEpic: Epic<
  AnyAction,
  Action<
    | Success<FetchSearchResultsParams, SearchResults>
    // eslint-disable-next-line @typescript-eslint/ban-types
    | Failure<FetchSearchResultsParams, {}>
  >,
  RootState
> = (action$) =>
  action$.pipe(
    ofAction(searchResultActions.fetchSearchResults.started),
    mergeMap((action) =>
      forkJoin([
        fetchMemories(action.payload.searchAnswers),
        fetchDestinations(action.payload.searchAnswers)
      ]).pipe(
        map((response) => {
          return searchResultActions.fetchSearchResults.done({
            params: action.payload,
            result: {
              memories: response[0],
              destinations: response[1]
            }
          });
        }),
        catchError((error) =>
          of(
            searchResultActions.fetchSearchResults.failed({
              params: action.payload,
              error
            })
          )
        )
      )
    )
  );

export const saveSearchAnswersEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$,
  { answers }
) =>
  action$.pipe(
    ofAction(searchResultActions.fetchSearchResults.done),
    tap((action) => {
      const searchAnswers = action.payload.params.searchAnswers;
      answers.setAll(searchAnswers);
    }),
    ignoreElements()
  );

export const searchResultsEpic = combineEpics(
  fetchSearchResultsEpic,
  fetchSearchResultsIfNotFetchedEpic,
  saveSearchAnswersEpic
);
