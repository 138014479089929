import React from 'react';
import { t } from '../../utils/i18n';
import { baseXUnit, spacing } from '../../styles/theme';
import FlexDiv from '../common/FlexDiv';
import RouteLink from '../navigation/RouteLink';
import { Paths } from '../../urls/Paths';
import ButtonText from '../elements/ButtonText';
import HyperLink from '../elements/HyperLink';
import Icon from './Icon';

const HelpPageButton: React.FunctionComponent = () => {
  return (
    <div className="help-page-button">
      <RouteLink path={Paths.Help}>
        <HyperLink className="content-row">
          <FlexDiv>
            <div className="icon">
              <Icon name="travel-info-pages" size={4} color="purple-light" />
            </div>
            <div className="text">
              <ButtonText variant={'primary'}>
                {t('Tietoa palvelusta')}
              </ButtonText>
            </div>
          </FlexDiv>
        </HyperLink>
      </RouteLink>
      {/*language=CSS*/}
      <style jsx>{`
        .text {
          padding-top: ${baseXUnit(0.5)};
        }
        .icon {
          flex: 0 0 auto;
          margin-right: ${spacing.one};
        }
      `}</style>
    </div>
  );
};

export default HelpPageButton;
