import React from 'react';
import {
  baseXInPixels,
  baseXUnit,
  breakpoints,
  color,
  spacing,
  zIndex
} from '../../styles/theme';
import Spacing from '../common/Spacing';
import MainMenu from './MainMenu';

export const defaultNavbarHeight = 8;
export const DEFAULT_NAVBAR_ANCHOR_OFFSET_IN_PIXELS = baseXInPixels(
  -(defaultNavbarHeight + 2)
);
const DefaultNavbar: React.FunctionComponent = ({ children }) => {
  return (
    <Spacing top={[8, 13, 13, 13]}>
      <div className="default-nav-bar" data-testid="default-nav-bar">
        <div className="nav-bar-wrapper">
          <div className="content">{children}</div>
          <div className="menu">
            <MainMenu />
          </div>
        </div>
        {/*language=CSS*/}
        <style jsx>{`
          .default-nav-bar {
            z-index: ${zIndex.navBar};
            width: 100%;
            position: fixed;
            top: 0;
            background-image: linear-gradient(
              to bottom,
              ${color.white},
              rgba(255, 255, 255, 0)
            );
          }
          .nav-bar-wrapper {
            display: flex;
            justify-content: space-between;
            padding: ${spacing.two};
            min-height: ${baseXUnit(defaultNavbarHeight)};
          }
          .content {
            display: flex;
            align-items: center;
          }
          @media ${breakpoints.medium} {
            .nav-bar-wrapper {
              padding-right: ${spacing.four};
              padding-left: ${spacing.four};
              padding-top: ${spacing.three};
              padding-bottom: ${spacing.five};
            }
          }
        `}</style>
      </div>
    </Spacing>
  );
};

export default DefaultNavbar;
