import * as Sentry from '@sentry/node';
import { configuration } from './environment';

let sentryInitialized = false;

// Capturing errors need to be async, because otherwise serverless environments might not report errors.
export async function captureError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
) {
  if (!sentryInitialized) {
    return;
  }
  Sentry.captureException(error);
  // This is necessary in serverless environments like Zeit Now or AWS Lambda.
  // Cf. https://github.com/getsentry/sentry-javascript/issues/1449#issuecomment-469300667
  await Sentry.flush(2000);
}

export function registerNodeJsUnhandledEventHandlers() {
  process.on('unhandledRejection', async (err) => {
    await captureError(err);
  });

  process.on('uncaughtException', async (err) => {
    await captureError(err);
  });
}

export function setupSentry() {
  if (configuration.sentry.DSN) {
    Sentry.init({
      dsn: configuration.sentry.DSN,
      environment: process.env.DEPLOYMENT_ENV,
      release: process.env.COMMIT_SHA
    });
    sentryInitialized = true;
  }
}
