import React from 'react';
import { baseXUnit, breakpoints } from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

interface StaticTimProps {
  size: ResponsiveProp<number>;
}

const StaticTim: React.FunctionComponent<StaticTimProps> = ({ size }) => {
  const baseSize = propToResponsivePropObject(size).mapDefined(baseXUnit);
  return (
    <>
      <img
        className="root"
        src={`/static/img/tim-circle.svg`}
        alt="Tim"
        aria-hidden="true"
      />
      {/*language=CSS*/}
      <style jsx>{`
        .root {
          object-fit: contain;
          width: ${baseSize.small};
          height: ${baseSize.small};
          display: block;
        }

        @media ${breakpoints.medium} {
          .root {
            width: ${baseSize.medium};
            height: ${baseSize.medium};
          }
        }
        @media ${breakpoints.large} {
          .root {
            width: ${baseSize.large};
            height: ${baseSize.large};
          }
        }
      `}</style>
    </>
  );
};

export default StaticTim;
