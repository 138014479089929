import React, { useContext } from 'react';
import { breakpoints } from '../../styles/theme';
import { WindowContext } from './WindowContextProvider';

interface DisplayOnlyProps {
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}
const DisplayOn: React.FunctionComponent<DisplayOnlyProps> = ({
  small,
  medium,
  large,
  children
}) => {
  const { breakpoint } = useContext(WindowContext);
  const isServer = !breakpoint;
  const shouldRender =
    isServer ||
    (small && breakpoint === 'small') ||
    (medium && breakpoint === 'medium') ||
    (large && breakpoint === 'large');
  return (
    <div className="display-on">
      {shouldRender && children}
      {/*language=CSS*/}
      <style jsx>{`
        .display-on {
          display: ${small ? 'block' : 'none'};
        }
        @media ${breakpoints.medium} {
          .display-on {
            display: ${medium ? 'block' : 'none'};
          }
        }
        @media ${breakpoints.large} {
          .display-on {
            display: ${large ? 'block' : 'none'};
          }
        }
      `}</style>
    </div>
  );
};

export default DisplayOn;
