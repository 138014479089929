import React, { useContext } from 'react';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';
import { CmsAsset } from '../../integrations/contentful/types';
import { BrowserContext } from '../../pages/_app';
import Image from '../elements/Image';
import { baseXInPixels, color, imageHoverOpacity } from '../../styles/theme';
import { formatFileQueryString } from '../../integrations/contentful/utils';

interface CmsImageProps {
  image: CmsAsset;
  width?: number;
  height?: number;
  crop?: boolean;
  forceJpgOrWebp?: boolean;
  borderRadius?: number;
  skeleton?: boolean;
  hover?: boolean;
}

export function getTopPaddingPercentage(
  image: CmsAsset,
  width?: number,
  height?: number
) {
  const imageDetails = image.fields.file.details.image;
  // imageDetails should always exist for image asset, but just fallback to 1.
  const imageWidth = width ? width : imageDetails ? imageDetails.width : 1;
  const imageHeight = height ? height : imageDetails ? imageDetails.height : 1;
  const wrapperAspectRatio = imageWidth / imageHeight;
  return (1 / wrapperAspectRatio) * 100;
}

const contentfulImageLimitBytes = 20971520;
const cmsImageMaxWidth = 1920;

const CmsImage: React.FunctionComponent<CmsImageProps> = ({
  image,
  width,
  height,
  crop = false,
  forceJpgOrWebp = true,
  borderRadius,
  skeleton = true,
  hover = false,
  ...rest
}) => {
  const { webpSupported } = useContext(BrowserContext);
  const queryParams = {
    w: width || cmsImageMaxWidth,
    h: height,
    fit: crop ? 'fill' : undefined
  };

  // Contentful: "As per the Technical Limits specifications, size of an image uploaded must not exceed 20MB.
  // Images exceeding the size limit are treated as assets and the manipulation features offered by the API are not applicable."
  const oversize = image.fields.file.details.size > contentfulImageLimitBytes;

  return (
    <div className="cms-image">
      <InView triggerOnce>
        {({ inView, ref }) => (
          <div
            className={classNames('image-container', {
              'responsive-wrapper': skeleton,
              hover
            })}
            ref={ref}
          >
            {!oversize && inView && (
              <Image
                src={`${image.fields.file.url}?${formatFileQueryString(
                  queryParams,
                  forceJpgOrWebp,
                  webpSupported
                )}`}
                alt={image.fields.description}
                borderRadius={borderRadius}
                {...rest}
              />
            )}
          </div>
        )}
      </InView>
      {/*language=CSS*/}
      <style jsx>{`
        .cms-image,
        .image-container {
          height: 100%;
        }
        .hover {
          transition: 0.5s;
        }
        .hover:hover {
          opacity: ${imageHoverOpacity};
        }
        .responsive-wrapper {
          position: relative;
          max-width: 100%;
          /* Background skeleton during loading */
          background: ${color.lightGray};
        }
        .responsive-wrapper > :global(.image) {
          position: absolute;
          top: 0;
          left: 0;
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .responsive-wrapper {
          ${width ? `width: ${width}px` : ''};
          /* We utilize padding-top "aspect ratio" here (and above). Cf. https://css-tricks.com/aspect-ratio-boxes/ */
          padding-top: ${getTopPaddingPercentage(image, width, height)}%;
          border-radius: ${baseXInPixels(borderRadius || 0)}px;
        }
      `}</style>
    </div>
  );
};

export default CmsImage;
