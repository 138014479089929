import React from 'react';
import { CmsAsset, CmsMemory } from '../../integrations/contentful/types';
import H from '../elements/H';
import { imageHoverOpacity } from '../../styles/theme';
import Spacing from '../common/Spacing';
import MemoryAuthorInfo from '../travellers/MemoryAuthorInfo';
import RouteLink from '../navigation/RouteLink';
import { Paths } from '../../urls/Paths';
import CmsMediaFixedHeight from '../cms/CmsMediaFixedHeight';
import HyperLink from '../elements/HyperLink';
import { arrayHead } from '../../utils/array';

interface TravelMemoryProps {
  memory: {
    mainImage: CmsAsset;
    slug: string;
    title: string;
    startDate: string;
    endDate: string;
  };
  traveller: {
    name: string;
    profilePicture: CmsAsset;
    slug: string;
  };
}

const MemoryCard: React.FunctionComponent<TravelMemoryProps> = ({
  memory,
  traveller,
  ...rest
}) => {
  return (
    <div data-cy="memory-card" {...rest}>
      <div className="image-and-title">
        <RouteLink
          path={Paths.Memory}
          params={{
            slug: memory.slug
          }}
        >
          <HyperLink
            display="block"
            noLinkStateStyles
            data-testid="memory-link"
          >
            <Spacing bottom={1}>
              <div className="image-container">
                <CmsMediaFixedHeight
                  media={memory.mainImage}
                  fixedHeight={22}
                  borderRadius={1}
                />
              </div>
            </Spacing>
            <Spacing bottom={1}>
              <H tag="h3" styling="h3" data-cy="memory-card-memory-title">
                <strong>{memory.title}</strong>
              </H>
            </Spacing>
          </HyperLink>
        </RouteLink>
      </div>
      <MemoryAuthorInfo
        author={{
          name: traveller.name,
          picture: traveller.profilePicture,
          slug: traveller.slug
        }}
        memoryStartDate={memory.startDate}
        memoryEndDate={memory.endDate}
      />
      {/*language=CSS*/}
      <style jsx>{`
        .image-and-title:hover :global(img) {
          opacity: ${imageHoverOpacity};
        }
      `}</style>
    </div>
  );
};

export function isMemoryValidToRender(memory: CmsMemory | undefined) {
  if (!memory) {
    return false;
  }
  const traveller = arrayHead(memory.fields.travellers);
  return (
    memory.fields.mainImage && traveller && traveller.fields.profilePicture
  );
}

export function renderMemoryCardSafely(memory: CmsMemory) {
  const traveller = arrayHead(memory.fields.travellers);
  if (
    !memory.fields.mainImage ||
    !traveller ||
    !traveller.fields.profilePicture
  ) {
    return null;
  }
  return (
    <MemoryCard
      data-cy="memory-card"
      key={memory.sys.id}
      memory={{
        ...memory.fields,
        mainImage: memory.fields.mainImage
      }}
      traveller={{
        name: traveller.fields.title,
        profilePicture: traveller.fields.profilePicture,
        slug: traveller.fields.slug
      }}
    />
  );
}

export default MemoryCard;
