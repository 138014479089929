export const cmsCarouselSlide = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: 'mSTFE7cWYKhaXExsq8shZ',
    type: 'Entry',
    createdAt: '2020-01-13T10:28:16.543Z',
    updatedAt: '2020-01-17T12:41:53.424Z',
    environment: {
      sys: {
        id: 'sandbox1',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 3,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'carouselSlide'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    animation: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '2Mhu4BR4ei9sb5SJQsa8Hl',
        type: 'Asset',
        createdAt: '2020-01-13T10:28:12.438Z',
        updatedAt: '2020-01-13T10:28:12.438Z',
        environment: {
          sys: {
            id: 'sandbox1',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 1,
        locale: 'fi-FI'
      },
      fields: {
        title: 'Animaatio',
        description: 'Animaatio',
        file: {
          url:
            '//assets.ctfassets.net/bh7k0sgo84rb/2Mhu4BR4ei9sb5SJQsa8Hl/6267e018b9e05ec3980c46c43d076771/lf20_5AY2UX.json',
          details: {
            size: 135612
          },
          fileName: 'lf20_5AY2UX.json',
          contentType: 'application/json'
        }
      }
    },
    loopStartFrame: 250,
    loopEndFrame: 500,
    content:
      'Vastaamalla muutamaan kysymykseen, jätät matkakohteen, lennon sekä majoituksen valinnan Timin huoleksi. Luota Timiin, hän on täällä sinua varten.',
    ctaText: 'Hae lomamuistoja',
    ctaUrl: '/suositukset'
  }
};
