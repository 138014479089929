import { format } from 'date-fns';

export function formatDateRange(startDate: Date, endDate: Date) {
  let startDateFormat = 'd.';
  if (startDate.getMonth() !== endDate.getMonth()) {
    startDateFormat = 'd.M.';
  }
  if (startDate.getFullYear() !== endDate.getFullYear()) {
    startDateFormat = 'd.M.yyyy';
  }

  const startDateFormatted = format(startDate, startDateFormat);
  const endDateFormatted = format(endDate, 'd.M.yyyy');
  return `${startDateFormatted}–${endDateFormatted}`;
}
