import React from 'react';
import { spacing } from '../../styles/theme';
import Icon, { IconColor, IconName } from './Icon';

interface IconTextProps {
  icon: IconName;
  iconSize?: number | number[];
  iconColor?: IconColor;
  iconHoverColor?: IconColor;
  iconForceHover?: boolean;
}

const IconText: React.FunctionComponent<IconTextProps> = ({
  icon,
  iconSize,
  iconColor,
  children,
  iconHoverColor,
  iconForceHover,
  ...rest
}) => {
  const size = iconSize || 4;
  return (
    <div className="icon-text" {...rest}>
      <div className="icon">
        <Icon
          name={icon}
          size={size}
          color={iconColor}
          hoverColor={iconHoverColor}
          forceHover={iconForceHover}
        />
      </div>
      <div className="text">{children}</div>
      {/*language=CSS*/}
      <style jsx>{`
        .icon-text {
          display: flex;
          max-width: 100%;
        }
        .icon {
          flex-shrink: 0;
          margin-right: ${spacing.one};
        }
        .text {
          flex: 1;
          min-width: 0;
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default IconText;
