import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import { baseXUnit } from '../../styles/theme';

interface TimLogoProps {
  size: number;
}

const TimLogo: React.FunctionComponent<TimLogoProps> = ({ size }) => {
  const baseHeight = baseXUnit(size);
  const baseWidth = baseXUnit(size * 2);
  return (
    <div className="tim-logo">
      <VisuallyHidden>Tim</VisuallyHidden>
      <img
        className="logo"
        src={`/static/img/tim-logo.svg`}
        alt="Tim"
        aria-hidden="true"
      />
      {/*language=CSS*/}
      <style jsx>{`
        .logo {
          object-fit: contain;
          width: ${baseWidth};
          height: ${baseHeight};
          display: block;
        }
      `}</style>
    </div>
  );
};

export default TimLogo;
