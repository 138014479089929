import React, { useCallback, useState } from 'react';
import Router from 'next/router';
import NavigationHistory from '../../navigation/NavigationHistory';

export const NavigationHistoryContext = React.createContext(
  new NavigationHistory()
);

interface NavigationHistoryTrackerProps {
  asPath: string;
}

export const NavigationHistoryTracker: React.FunctionComponent<NavigationHistoryTrackerProps> =
  ({ asPath, children }) => {
    const [navigationHistory] = useState(new NavigationHistory());

    const beforePopState = useCallback(
      ({ as }) => {
        if (as === navigationHistory.getBackAsPath()) {
          navigationHistory.back();
        }
        return true;
      },
      [navigationHistory]
    );

    // We should use Router only on client side. Cf. https://github.com/zeit/next.js/blob/master/errors/no-router-instance.md
    if (Router.router) {
      Router.beforePopState(beforePopState);
    }

    if (asPath !== navigationHistory.getCurrentAsPath()) {
      navigationHistory.pushItem(asPath);
    }

    return (
      <NavigationHistoryContext.Provider value={navigationHistory}>
        {children}
      </NavigationHistoryContext.Provider>
    );
  };
