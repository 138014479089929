const siteSettings = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '5z7EJ5lEWXgIoPP1cP4Kp0',
    type: 'Entry',
    createdAt: '2020-02-07T15:06:53.170Z',
    updatedAt: '2020-02-07T15:07:39.221Z',
    environment: {
      sys: {
        id: 'sandbox1',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'siteSettings'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    supportEmail: 'example@example.com',
    supportPhoneNumber: '+358 123456',
    emergencyPhoneNumber: '+358 654321',
    supportOfficeHours: '9-17',
    slug: 'asetukset'
  }
};

export default siteSettings;
