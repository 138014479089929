// This is the profile which is defaulted when no environment is given. Suitable for Jest testing etc.
export default {
  siteTitleSuffix: ' | Tim',
  sentry: {
    DSN: null
  },
  contentful: {
    environment: 'staging',
    accessToken: 'someFakeToken'
  },
  mockContentfulRequests: false,
  auth: {
    enabled: false
  },
  mockErpRequests: false,
  imtErpUrl: 'http://localhost:8081',
  imtWebshopUrl: 'http://10.10.10.10/wp',
  secure: true,
  googleAnalyticsTrackingId: null,
  disableAnimations: true,
  googleTagManager: null,
  erpShipTripIds: []
};
