import { combineReducers } from 'redux';
import {
  ActionsObservable,
  combineEpics,
  StateObservable
} from 'redux-observable';
import { catchError } from 'rxjs/operators';
import { AnyAction } from 'typescript-fsa';
import questions, { questionsEpic, QuestionsState } from './questions';
import searchResults, {
  searchResultsEpic,
  SearchResultsState
} from './searchResults';
import videos, { videosEpic, VideosState } from './videos';
import siteSettings, {
  siteSettingsEpic,
  SiteSettingsState
} from './siteSettings';

export interface RootState {
  questions: QuestionsState;
  searchResults: SearchResultsState;
  videos: VideosState;
  siteSettings: SiteSettingsState;
}

export default combineReducers({
  questions,
  searchResults,
  videos,
  siteSettings
});

export const rootEpic = (
  action$: ActionsObservable<AnyAction>,
  store$: StateObservable<RootState>,
  dependencies: Record<string, unknown>
) => {
  // We add a global catchError so that a single uncaught error does not break all Epics.
  // Cf. https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
  return combineEpics(
    questionsEpic,
    searchResultsEpic,
    videosEpic,
    siteSettingsEpic
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
};
