import React from 'react';
import classnames from 'classnames';
import {
  baseXUnit,
  breakpoints,
  font,
  fontSize,
  fontWeight,
  letterSpacing,
  spacing,
  color as themeColor
} from '../../styles/theme';

type HeadingStyle = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Color = 'dark' | 'gray' | 'purple' | 'white' | 'purpleLight' | 'light';

export interface HProps {
  tag?: keyof JSX.IntrinsicElements;
  styling: HeadingStyle;
  data?: boolean; // If it's data heading
  color?: Color;
}

const H: React.FunctionComponent<HProps> = ({
  children,
  tag,
  styling,
  data,
  color,
  ...rest
}) => {
  const Tag = tag || styling;
  return (
    <Tag className={classnames('heading', styling, { data: data })} {...rest}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .heading {
          /* This helps with flexbox issues when flex-grow is 1 */
          min-width: 0;
          margin: 0;
          font-family: ${font.spaceGrotesk};
        }
        .heading > :global(strong) {
          font-weight: ${fontWeight.bold};
        }

        .h1 {
          font-size: ${fontSize.px32};
          line-height: ${spacing.five};
          font-weight: ${fontWeight.regular};
        }
        .h2 {
          font-size: ${fontSize.px24};
          line-height: ${spacing.four};
          font-weight: ${fontWeight.regular};
        }
        .h3 {
          font-size: ${fontSize.px18};
          line-height: ${spacing.three};
          font-weight: ${fontWeight.regular};
        }
        .h4 {
          font-size: ${fontSize.px14};
          line-height: ${spacing.two};
          font-weight: ${fontWeight.regular};
        }
        .h5 {
          font-family: ${font.mono};
          line-height: ${spacing.two};
          font-size: ${fontSize.px11};
          font-weight: ${fontWeight.regular};
          letter-spacing: ${letterSpacing.wide};
          text-transform: uppercase;
        }
        .data {
          font-family: ${font.mono};
        }

        @media ${breakpoints.extraLarge} {
          .h1 {
            font-size: ${fontSize.px48};
            line-height: ${spacing.seven};
            font-weight: ${fontWeight.bold};
          }
          .h2 {
            font-size: ${fontSize.px36};
            line-height: ${spacing.five};
          }
          .h2.data {
            font-size: ${fontSize.px28};
            line-height: ${baseXUnit(4.5)};
          }
          .h3 {
            font-size: ${fontSize.px24};
            line-height: ${spacing.four};
          }
          .h4 {
            font-size: ${fontSize.px18};
            line-height: ${spacing.three};
          }
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .heading {
          ${color ? `color: ${themeColor[color]};` : ''}
        }
      `}</style>
    </Tag>
  );
};

export default H;
