import { cmsCarouselSlide } from './cmsCarouselSlide';

export const cmsCarouselBlock = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '40QTMZxrUHeE2JEjFxdfxU',
    type: 'Entry',
    createdAt: '2020-01-13T10:28:40.028Z',
    updatedAt: '2020-01-17T10:36:20.051Z',
    environment: {
      sys: {
        id: 'sandbox1',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'carouselBlock'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Koe parhaat elämykset Timin seurassa',
    ingress: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Tim on digitaalinen matka-assistenttisi joka auttaa sinua löytämään ja kokemaan unohtumattomia lomamuistoja. ',
              nodeType: 'text'
            },
            {
              data: {
                uri: '/'
              },
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Treffaa Tim',
                  nodeType: 'text'
                }
              ],
              nodeType: 'hyperlink'
            },
            {
              data: {},
              marks: [],
              value: ' – hän on täällä sinua varten!  ',
              nodeType: 'text'
            }
          ],
          nodeType: 'paragraph'
        }
      ],
      nodeType: 'document'
    },
    slides: [
      cmsCarouselSlide,
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '7niSFHnJUHZH36gquOkEiW',
          type: 'Entry',
          createdAt: '2020-01-17T10:36:15.893Z',
          updatedAt: '2020-01-17T12:42:06.618Z',
          environment: {
            sys: {
              id: 'sandbox1',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 2,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'carouselSlide'
            }
          },
          locale: 'fi-FI'
        },
        fields: {
          animation: {
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'bh7k0sgo84rb'
                }
              },
              id: '2Mhu4BR4ei9sb5SJQsa8Hl',
              type: 'Asset',
              createdAt: '2020-01-13T10:28:12.438Z',
              updatedAt: '2020-01-13T10:28:12.438Z',
              environment: {
                sys: {
                  id: 'sandbox1',
                  type: 'Link',
                  linkType: 'Environment'
                }
              },
              revision: 1,
              locale: 'fi-FI'
            },
            fields: {
              title: 'Animaatio',
              description: 'Animaatio',
              file: {
                url:
                  '//assets.ctfassets.net/bh7k0sgo84rb/2Mhu4BR4ei9sb5SJQsa8Hl/6267e018b9e05ec3980c46c43d076771/lf20_5AY2UX.json',
                details: {
                  size: 135612
                },
                fileName: 'lf20_5AY2UX.json',
                contentType: 'application/json'
              }
            }
          },
          loopStartFrame: 100,
          loopEndFrame: 250,
          title: 'Jotain hienoa sisältöä',
          content: 'Vielä hienompaa sisältöä, mutta lyhyesti',
          ctaText: 'Ota yhteyttä',
          ctaDescription: 'Moikkamoi!',
          ctaUrl: 'https://twitter.com/home'
        }
      }
    ]
  }
};
