import React from 'react';
import classNames from 'classnames';
import {
  baseXUnit,
  breakpoints,
  color as themeColor
} from '../../styles/theme';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

interface VideoBorderRadiusProps {
  size: ResponsiveProp<number>;
  color?: string;
}

const VideoBorderRadius: React.FunctionComponent<VideoBorderRadiusProps> = ({
  size,
  color = themeColor.white
}) => {
  const borderRadiusValues =
    propToResponsivePropObject(size).mapDefined(baseXUnit);

  return (
    <div className="border-radius-wrapper">
      {['top-left', 'top-right', 'bottom-right', 'bottom-left'].map(
        (className) => (
          <div
            className={classNames('border-radius', className)}
            key={className}
          >
            <svg width="8" height="8" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.75.004A8 8 0 00.005 7.75L0 8V0h8l-.25.004z"
                fillRule="evenodd"
              />
            </svg>
          </div>
        )
      )}

      {/*language=CSS*/}
      <style jsx>{`
        /*  We need to use SVGs for video borders on mobile */
        .border-radius-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }

        .border-radius {
          position: absolute;
          z-index: 1;
        }

        .border-radius svg {
          display: block;
        }

        .border-radius.top-left {
          top: 0;
          left: 0;
        }
        .border-radius.top-right {
          top: 0;
          right: 0;
          transform: rotate(90deg);
        }
        .border-radius.bottom-left {
          bottom: 0;
          right: 0;
          transform: rotate(180deg);
        }
        .border-radius.bottom-right {
          bottom: 0;
          left: 0;
          transform: rotate(270deg);
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx data-testid="dynamic-styles">{`
        .border-radius {
          width: ${borderRadiusValues.small};
          height: ${borderRadiusValues.small};
        }

        .border-radius svg {
          fill: ${color};
        }
        @media ${breakpoints.medium} {
          .border-radius {
            width: ${borderRadiusValues.medium};
            height: ${borderRadiusValues.medium};
          }
        }

        @media ${breakpoints.large} {
          .border-radius {
            width: ${borderRadiusValues.large};
            height: ${borderRadiusValues.large};
          }
        }
      `}</style>
    </div>
  );
};

export default VideoBorderRadius;
