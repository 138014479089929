import React from 'react';
import { baseXUnit, spacing } from '../../styles/theme';

export const navbarPadding = spacing.two;
const Navbar: React.FunctionComponent = ({ children }) => {
  return (
    <div className="root">
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .root {
          display: flex;
          justify-content: space-between;
          padding: ${navbarPadding};
          min-height: ${baseXUnit(8)};
        }
      `}</style>
    </div>
  );
};

export default Navbar;
