import actionCreatorFactory, {
  Action,
  AnyAction,
  Failure,
  Success
} from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ofAction } from 'typescript-fsa-redux-observable';
import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { fetchQuestionCollection } from '../../integrations/contentful/queries';
import { caseProduce } from '../util';
import {
  LoadingState,
  shouldBeLoaded,
  WithLoadingState
} from '../../loading/loadable';
import { RootState } from './index';

export const searchChatQuestionCollectionKey = 'searchChat';

export interface AnswerOption {
  value: string;
  label: string;
  replies: string[];
}

export interface Question {
  questionText: string;
  key: string;
  options: AnswerOption[];
  label: string;
  shortQuestionText: string;
  mandatory: boolean;
}

export type QuestionCollection = Question[];

export type QuestionCollectionLoadable = {
  questions: QuestionCollection;
} & WithLoadingState;

// Actions
interface FetchQuestionCollectionParams {
  collectionKey: string;
}
interface FetchQuestionCollectionResult {
  questionCollection: QuestionCollection;
}
const actionCreator = actionCreatorFactory('questions');
export const questionsActions = {
  makeSureSearchChatQuestionsLoaded: actionCreator(
    'MAKE_SURE_SEARCH_CHAT_QUESTIONS_LOADED'
  ),
  fetchQuestionCollectionIfNotFetched:
    actionCreator<FetchQuestionCollectionParams>(
      'MAYBE_FETCH_QUESTION_COLLECTION'
    ),
  fetchQuestionCollection: actionCreator.async<
    FetchQuestionCollectionParams,
    FetchQuestionCollectionResult
  >('FETCH_QUESTION_COLLECTION')
};

type QuestionCollectionMap = Record<string, QuestionCollectionLoadable>;

// State
export interface QuestionsState {
  questionCollections: QuestionCollectionMap;
}

export const initialState: QuestionsState = {
  questionCollections: {}
};

// Reducer
export default reducerWithInitialState(initialState)
  .case(
    questionsActions.fetchQuestionCollection.started,
    caseProduce((draftState, payload) => {
      draftState.questionCollections[payload.collectionKey] = {
        loadingState: LoadingState.STARTED,
        questions: []
      };
    })
  )
  .case(
    questionsActions.fetchQuestionCollection.done,
    caseProduce((draftState, payload) => {
      draftState.questionCollections[payload.params.collectionKey] = {
        loadingState: LoadingState.DONE,
        questions: payload.result.questionCollection
      };
    })
  )
  .case(
    questionsActions.fetchQuestionCollection.failed,
    caseProduce((draftState, payload) => {
      draftState.questionCollections[payload.params.collectionKey] = {
        loadingState: LoadingState.FAILED,
        questions: []
      };
    })
  );

// Selectors
export const selectQuestionCollections = (
  state: RootState
): QuestionCollectionMap => state.questions.questionCollections;
export const selectSearchChatQuestionCollection = (
  state: RootState
): QuestionCollectionLoadable =>
  selectQuestionCollections(state)[searchChatQuestionCollectionKey];

// Epics
const maybeFetchSearchChatQuestionCollectionEpic: Epic<
  AnyAction,
  AnyAction,
  RootState
> = (action$) =>
  action$.pipe(
    ofAction(questionsActions.makeSureSearchChatQuestionsLoaded),
    map(() => {
      return questionsActions.fetchQuestionCollectionIfNotFetched({
        collectionKey: searchChatQuestionCollectionKey
      });
    })
  );

const fetchQuestionCollectionIfNotFetchedEpic: Epic<
  AnyAction,
  Action<FetchQuestionCollectionParams>,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofAction(questionsActions.fetchQuestionCollectionIfNotFetched),
    filter((action) => {
      const questionCollection = selectQuestionCollections(state$.value)[
        action.payload.collectionKey
      ];
      return shouldBeLoaded(questionCollection);
    }),
    map((action) => {
      return questionsActions.fetchQuestionCollection.started({
        collectionKey: action.payload.collectionKey
      });
    })
  );

const fetchQuestionCollectionEpic: Epic<
  AnyAction,
  Action<
    | Success<FetchQuestionCollectionParams, FetchQuestionCollectionResult>
    // eslint-disable-next-line @typescript-eslint/ban-types
    | Failure<FetchQuestionCollectionParams, {}>
  >,
  RootState
> = (action$) =>
  action$.pipe(
    ofAction(questionsActions.fetchQuestionCollection.started),
    mergeMap((action) =>
      fromPromise(fetchQuestionCollection(action.payload.collectionKey)).pipe(
        map((questionCollection) => {
          return questionsActions.fetchQuestionCollection.done({
            params: action.payload,
            result: {
              questionCollection
            }
          });
        }),
        catchError((error) =>
          of(
            questionsActions.fetchQuestionCollection.failed({
              params: action.payload,
              error
            })
          )
        )
      )
    )
  );

export const questionsEpic = combineEpics(
  fetchQuestionCollectionEpic,
  fetchQuestionCollectionIfNotFetchedEpic,
  maybeFetchSearchChatQuestionCollectionEpic
);
