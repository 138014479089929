import App, { AppContext } from 'next/app';
import React from 'react';
import Head from 'next/head';
import * as Sentry from '@sentry/node';
import Cookies from 'universal-cookie';
import withRedux from 'next-redux-wrapper';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { UrlGenerator } from '../urls/UrlGenerator';
import { captureError, setupSentry } from '../utils/sentry';
import { isAuthorized, requireBasicAuth } from '../utils/auth';
import SearchAnswers from '../search/SearchAnswers';
import { NavigationHistoryTracker } from '../components/navigation/NavigationHistoryTracker';
import BasicLayout from '../components/layout/BasicLayout';
import { initializeStore } from '../redux/store';
import UserInfoStore from '../user/UserInfoStore';
import {
  activateRemoveOutlines,
  deactivateRemoveOutlines
} from '../utils/removeOutlines';
import WindowContextProvider from '../components/common/WindowContextProvider';
import { initializeAnalytics } from '../analytics/analytics';
import ErrorPage from './ErrorPage';

setupSentry();

const cookies = new Cookies();

export const BrowserContext = React.createContext({ webpSupported: false });
export const SiteContext = React.createContext({
  host: '',
  urlGenerator: new UrlGenerator('')
});
export const UserContext = React.createContext({
  user: new UserInfoStore(cookies)
});
export const SearchContext = React.createContext({
  answers: new SearchAnswers(cookies)
});

interface MyAppProps {
  webpSupported: boolean;
  host: string;
  requestCookieHeader?: string;
  store: Store;
  isIE: boolean;
}

export class MyApp extends App<MyAppProps> {
  public state = { mounted: false };

  public static webpIsSupported(acceptHeader: string) {
    return /image\/webp/.test(acceptHeader);
  }

  public static async getInitialProps(appContext: AppContext) {
    const { req, res } = appContext.ctx;
    let webpSupported = false;
    let host = '';
    let requestCookieHeader;

    if (req && res) {
      if (!isAuthorized(req)) {
        requireBasicAuth(res);
      }
      webpSupported = MyApp.webpIsSupported(String(req.headers.accept));
      host = String(req.headers.host);
      requestCookieHeader = req.headers.cookie;
    } else {
      host = window.location.host;
    }
    const appProps = await App.getInitialProps(appContext);

    let isIE = false;
    if (req && req.headers && req.headers['user-agent']) {
      isIE = /Trident/.test(req.headers['user-agent']);
    }

    // A page can return httpErrorCode from getInitialProps in order to render an error page like 404
    if (res && appProps.pageProps.httpErrorCode && !isIE) {
      if (ErrorPage.getInitialProps) {
        // We are in an user-defined error, let's fetch the Error component
        // getInitialProps if needed, and merge them to pageProps with the error object
        const error: Error & {
          statusCode?: number;
        } = new Error('Unexpected http status code received from page props');
        error.statusCode = appProps.pageProps.httpErrorCode;
        appProps.pageProps.errorPageProps = await ErrorPage.getInitialProps({
          ...appContext.ctx,
          err: error
        });
      }
      res.statusCode = appProps.pageProps.httpErrorCode;
    }

    // Call error page initialProps manually when IE is detected so that the Error-page will get proper props
    if (isIE && ErrorPage.getInitialProps) {
      appProps.pageProps.errorPageProps = await ErrorPage.getInitialProps({
        ...appContext.ctx
      });
    }

    return { webpSupported, host, requestCookieHeader, isIE, ...appProps };
  }

  public componentDidMount() {
    activateRemoveOutlines();
    this.setState({ mounted: true });
    initializeAnalytics();
  }

  public componentWillUnmount(): void {
    deactivateRemoveOutlines();
  }

  public renderNormalizeCSS() {
    const { mounted } = this.state;
    const normalizeCssUrl =
      'https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.0/normalize.min.css';
    return (
      <>
        <Head>
          <link
            rel={mounted ? 'stylesheet' : 'preload'}
            href={normalizeCssUrl}
            as="style"
          />
        </Head>
        <noscript>
          <link rel="stylesheet" href={normalizeCssUrl} />
        </noscript>
      </>
    );
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      // Sentry.withScope does not support async methods, so we cannot await for captureError to finish here.
      // However, this is not an issue, because errors in server-side renders should be caught also by our ErrorPage,
      // which means that it is okay, if these errors are not awaited for on the sever.
      captureError(error);
    });
  }

  public getDefaultLayout = (page: React.ReactNode) => <>{page}</>;

  public render() {
    const {
      Component,
      pageProps,
      webpSupported,
      host,
      requestCookieHeader,
      router,
      store,
      isIE
    } = this.props;

    if (pageProps.httpErrorCode || isIE) {
      return (
        <Provider store={store}>
          <BasicLayout>
            <ErrorPage
              statusCode={pageProps.httpErrorCode}
              isIE={isIE}
              {...pageProps.errorPageProps}
            />
          </BasicLayout>
        </Provider>
      );
    }
    const getLayout =
      //@ts-ignore -- Adding "getLayout" to the NextComponentType is so complex that we just ignore the error
      Component.getLayout || this.getDefaultLayout;

    const cookies = new Cookies(requestCookieHeader);
    return (
      <ParallaxProvider>
        <BrowserContext.Provider value={{ webpSupported }}>
          <SiteContext.Provider
            value={{ host, urlGenerator: new UrlGenerator(host) }}
          >
            <UserContext.Provider
              value={{
                user: new UserInfoStore(cookies)
              }}
            >
              <SearchContext.Provider
                value={{
                  answers: new SearchAnswers(cookies)
                }}
              >
                <NavigationHistoryTracker asPath={router.asPath}>
                  <WindowContextProvider>
                    <Provider store={store}>
                      {this.renderNormalizeCSS()}
                      <BasicLayout>
                        {getLayout(<Component {...pageProps} />)}
                      </BasicLayout>
                    </Provider>
                  </WindowContextProvider>
                </NavigationHistoryTracker>
              </SearchContext.Provider>
            </UserContext.Provider>
          </SiteContext.Provider>
        </BrowserContext.Provider>
      </ParallaxProvider>
    );
  }
}

export default withRedux(initializeStore)(MyApp);
