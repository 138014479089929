import Configuration from '../configuration/Configuration';
import testConfiguration from '../configuration/default';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      CONFIGURATION: Configuration;
    }
  }
}

export const configuration: Configuration = {
  ...(process.env.CONFIGURATION || testConfiguration)
};
