const cmsHelpCategories = [
  {
    sys: {
      id: '3nAZpD3M2iVCNybttyus8p',
      type: 'Entry',
      createdAt: '2020-01-31T11:52:55.389Z',
      updatedAt: '2020-02-04T11:41:02.266Z',
      revision: 4,
      locale: 'fi-FI'
    },
    fields: {
      title: 'Tim ja taustajoukot',
      seoTitle: 'Tim ja taustajoukot',
      label: 'Kuka on Tim ja ketkä Timin takana?',
      slug: 'tim-tiimi',
      image: {
        sys: {
          id: '76ORXyRCSsBMQgyHjGMt4h',
          type: 'Asset',
          createdAt: '2020-02-04T10:50:06.666Z',
          updatedAt: '2020-02-04T10:50:06.666Z',
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Tim-tiimin kuva',
          description: '',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: { size: 1305, image: { width: 96, height: 96 } },
            fileName: 'tim-pic.png',
            contentType: 'image/png'
          }
        }
      },
      ingress:
        'Tim on matkailun kirkas tulevaisuus ja tekoälyn aikakauden airut.',
      topics: [
        {
          sys: {
            id: '1PINelVJn5wnh1AKK6mJMJ',
            type: 'Entry',
            createdAt: '2020-01-31T11:51:11.570Z',
            updatedAt: '2020-01-31T11:51:11.570Z',
            revision: 1,
            locale: 'fi-FI'
          },
          fields: {
            title: 'Kuka on Tim?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Tim on digitaalinen matka-assistenttisi, jonka tehtävänä on tarjota juuri sinun tarpeisiisi sopivia lomamuistoja. Vastaamalla muutamaan Timin asettamaan kysymykseen, löydät helposti itsellesi sopivia lomamuistoja ja matkakohteita. ',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'paragraph'
                }
              ],
              nodeType: 'document'
            }
          }
        },
        {
          sys: {
            id: '1u4qEOoOl1jP2wPkOjNRZf',
            type: 'Entry',
            createdAt: '2020-01-31T11:51:35.928Z',
            updatedAt: '2020-01-31T11:51:35.928Z',
            revision: 1,
            locale: 'fi-FI'
          },
          fields: {
            title: 'Mitä Tim tekee?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Tim mahdollistaa muiden matkaajien lomamuistojen näkemisen, kokemisen ja jakamisen – kaikki yhdessä verkkopalvelussa. Uskomme että unohtumattomat lomamuistot muuttavat ihmisen elämän paremmaksi.  ',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'paragraph'
                }
              ],
              nodeType: 'document'
            }
          }
        },
        {
          sys: {
            id: '5UDESXSnPO0vP8IKbGwoMF',
            type: 'Entry',
            createdAt: '2020-01-31T11:52:47.896Z',
            updatedAt: '2020-01-31T11:52:47.896Z',
            revision: 1,
            locale: 'fi-FI'
          },
          fields: {
            title: 'Muotoiltua sisältöä',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Tässä on vähän tyyliäkin',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'paragraph'
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Kuten h3 otsikko',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'heading-3'
                },
                {
                  data: {},
                  content: [
                    { data: {}, marks: [], value: 'ja ', nodeType: 'text' },
                    {
                      data: { uri: 'https://www.google.fi' },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: 'linkki googleen',
                          nodeType: 'text'
                        }
                      ],
                      nodeType: 'hyperlink'
                    },
                    { data: {}, marks: [], value: '', nodeType: 'text' }
                  ],
                  nodeType: 'paragraph'
                },
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'ja pienempi otsikko',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'heading-4'
                },
                {
                  data: {},
                  content: [
                    { data: {}, marks: [], value: '', nodeType: 'text' }
                  ],
                  nodeType: 'paragraph'
                }
              ],
              nodeType: 'document'
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: 'DAAEkPzGRCo8HsaFQlOZL',
      type: 'Entry',
      createdAt: '2020-02-04T10:54:26.287Z',
      updatedAt: '2020-02-04T10:54:26.287Z',
      revision: 1,
      locale: 'fi-FI'
    },
    fields: {
      title: 'Varaaminen ja maksaminen',
      seoTitle: 'Varaaminen ja maksaminen',
      label: 'Ohjeita ja usein kysyttyä',
      slug: 'ohjeet',
      image: {
        sys: {
          id: '76ORXyRCSsBMQgyHjGMt4h',
          type: 'Asset',
          createdAt: '2020-02-04T10:50:06.666Z',
          updatedAt: '2020-02-04T10:50:06.666Z',
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Tim-tiimin kuva',
          description: '',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: { size: 1305, image: { width: 96, height: 96 } },
            fileName: 'tim-pic.png',
            contentType: 'image/png'
          }
        }
      },
      ingress:
        'Matkalla saattavat tulla ohjeet tarpeeseen. Tsekkaappa siis nää.',
      topics: [
        {
          sys: {
            id: '2TaVAfah2ZdDyl2KNqAFPz',
            type: 'Entry',
            createdAt: '2020-02-04T10:53:25.158Z',
            updatedAt: '2020-02-04T10:53:25.158Z',
            revision: 1,
            locale: 'fi-FI'
          },
          fields: {
            title:
              'Löysin itseäni kiinnostavan lomamuiston. Miten saan sen varattua?',
            content: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Lomamuistosivun alalaidasta löydät matkan tiedot sekä arvion matkasi matkustus- ja majoituskustannuksista. Varaa-nappia painamalla siirryt tilaan, jossa voit muokata varaustasi. Mikäli ette onnistu varaamaan haluamaanne matkaa, ottakaa yhteyttä asiakaspalveluumme ',
                      nodeType: 'text'
                    },
                    {
                      data: { uri: 'tel:+358345800' },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: '+358 3 45800',
                          nodeType: 'text'
                        }
                      ],
                      nodeType: 'hyperlink'
                    },
                    { data: {}, marks: [], value: ' tai ', nodeType: 'text' },
                    {
                      data: { uri: 'mailto:matkat@tim.travel' },
                      content: [
                        {
                          data: {},
                          marks: [],
                          value: 'matkat@tim.travel',
                          nodeType: 'text'
                        }
                      ],
                      nodeType: 'hyperlink'
                    },
                    {
                      data: {},
                      marks: [],
                      value: ' -osoitteeseen.',
                      nodeType: 'text'
                    }
                  ],
                  nodeType: 'paragraph'
                }
              ],
              nodeType: 'document'
            }
          }
        }
      ]
    }
  }
];

export default cmsHelpCategories;
