import React from 'react';
import Container from '../grid/Container';
import Stack from '../spacing/Stack';
import Spacing from '../common/Spacing';
import ModalMainMenuElement from './ModalMainMenuElement';
import { MainMenuElement } from './MainMenu';

interface ModalMainMenuContentProps {
  menuElements: MainMenuElement[];
}

const ModalMainMenuContent: React.FunctionComponent<ModalMainMenuContentProps> =
  ({ menuElements }) => {
    return (
      <Container data-cy="modal-main-menu-content">
        <Spacing top={4}>
          <Stack space={3}>
            {menuElements.map((menuElement) => (
              <ModalMainMenuElement key={menuElement.label} {...menuElement} />
            ))}
          </Stack>
        </Spacing>
      </Container>
    );
  };

export default ModalMainMenuContent;
