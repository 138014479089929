import React, { useEffect, useRef } from 'react';
import { stringify } from 'query-string';
import Player from '@vimeo/player';
import { color } from '../../styles/theme';

interface VimeoVideoProps {
  title: string;
  videoId: number;
  background?: boolean;
  play?: boolean;
}

const VimeoVideo: React.FunctionComponent<VimeoVideoProps> = ({
  videoId,
  title,
  background,
  play
}) => {
  const ref = useRef(null);
  const videoQueryParams = {
    background: background ? 1 : 0,
    // Color cannot have # in the beginning
    color: color.purpleLight.substr(1),
    loop: 1,
    portrait: 0,
    title: 0,
    byline: 0,
    controls: 0,
    inline: 1,
    muted: 1
  };
  useEffect(() => {
    const player = new Player(
      //@ts-ignore
      ref.current
    );
    if (play) {
      player.play();
    } else {
      player.pause();
    }
  }, [ref, play]);

  return (
    <iframe
      ref={ref}
      title={title}
      src={`https://player.vimeo.com/video/${videoId}?${stringify(
        videoQueryParams
      )}`}
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  );
};

export default VimeoVideo;
