import { Spring, SpringProps } from 'react-spring/renderprops.cjs';
import React from 'react';
import { useSpring, useTransition } from 'react-spring';
import { configuration } from '../../utils/environment';

export function useTestableSpring(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any
) {
  if (configuration.disableAnimations) {
    args = { ...args, immediate: true };
  }
  return useSpring(args);
}

export const TestableSpring: React.FunctionComponent<SpringProps> = (props) => {
  if (configuration.disableAnimations) {
    props = { ...props, immediate: true };
  }
  return <Spring {...props} />;
};

export function useTestableTransition(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keys: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any
) {
  if (configuration.disableAnimations) {
    values = { ...values, immediate: true };
  }
  return useTransition(items, keys, values);
}
