import { AnyAction } from 'typescript-fsa';
import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, createStore, DeepPartial } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Cookies from 'universal-cookie';
import { NextPageContext } from 'next';
import SearchAnswers from '../search/SearchAnswers';
import rootReducer, { rootEpic, RootState } from './features/index';

export function initializeStore(
  initialState: DeepPartial<RootState | undefined>,
  { req }: NextPageContext
) {
  const answers = new SearchAnswers(new Cookies(req && req.headers.cookie));
  const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>({
    dependencies: { answers: answers }
  });
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}
