import { t } from '../../../src/utils/i18n';
import { Question } from '../../../src/redux/features/questions';

function createOption(value: string, label: string, replies: string[]) {
  return {
    label,
    value,
    replies
  };
}
export const questions: Question[] = [
  {
    questionText: t('Mitä haluat tulevalta lomamatkaltasi?'),
    shortQuestionText: t('Mitä haluat tehdä?'),
    label: t('Tekeminen'),
    key: 'activities',
    options: [
      createOption('party', t('Bilettää'), [
        t('🕺'),
        t('Sokka irti 🥳'),
        t('🍾🥂🤪')
      ]),
      createOption('escape', t('Ottaa rennosti ja löhöillä'), [
        t('Ei siis suunnitella liikoja 🧘‍️'),
        t('Herätys pois päältä 😴'),
        t('👙🌊🍹')
      ]),
      createOption('chill', t('Chillata & fiilistellä'), [
        t('Jos tykkäät herkutella, tiedän muutaman raflan 😋'),
        t('😊 lungisti vaan.')
      ]),
      createOption('learn', t('Oppia uutta'), [
        t('🎓'),
        t('Näen edessäsi jotain unohtumatonta 🔮')
      ]),
      createOption('challenge', t('Kohdata haasteita'), [
        t('😱 varovasti sitten...'),
        t('Vakuutukset kunnossa? 🤕'),
        t('You rock! 💪')
      ])
    ],
    mandatory: true
  },
  {
    questionText: t('Kenen kanssa haluaisit matkalle lähteä?'),
    shortQuestionText: t('Kenen kanssa?'),
    label: t('Seura'),
    key: 'groups',
    options: [
      createOption('couple', t('Puolison kanssa'), [
        t('Nauttikaa 😍'),
        t('🌹Hemmottelua kehiin 🥂'),
        t('💘')
      ]),
      createOption('friends', t('Kaveriporukalla'), [
        t('Mukana 🤘🤖'),
        t('Muistakaa pääluku lähtiessä 🤪')
      ]),
      createOption('family', t('Perheenä'), [
        t('Tuleehan anoppi mukaan? 😉'),
        t('Vauvasta 👶 vaariin 👴 siis.'),
        t('Pikkurobotteja varten - muistathan käsidesin 🤢')
      ]),
      createOption('single', t('Yksin'), [
        t('Oman tien kulkija siis. Siistiä 👌'),
        t('Minäkin tykkään olla aina itsekseni 🤖')
      ])
    ],
    mandatory: false
  },
  {
    questionText: t('Kuinka pitkään matkasi voisi kestää?'),
    shortQuestionText: t('Miten pitkään?'),
    label: t('Matkan pituus'),
    key: 'lengths',
    options: [
      createOption('weekend', t('Viikonlopun'), [
        t('🤖optimoidaan matka-aikaa 🚀'),
        t('Keskeinen sijainti ✅')
      ]),
      createOption('longweekend', t('Pitkän viikonlopun'), [
        t('Täydellistä vaikka citylomalle 🚖'),
        t('Just sopiva reissu 🤝')
      ]),
      createOption('week', t('Viikon'), [
        t('Ei siis mitään kiirettä 🛌'),
        t('Sopivasti aikaa nauttia 😌')
      ]),
      createOption('verylong', t('Pitkään!'), [
        t('Ei kiire yhtään minnekään? Kade! 😆'),
        t('Ehditään siis vaikka pallon ympäri 🙃')
      ])
    ],
    mandatory: false
  }
];
