import isEmpty from 'lodash/isEmpty';
import {
  lisbonMemory,
  tenerifeMemory
} from '../../../../test/data/cmsMemories';
import { lisbon, lofoten } from '../../../../test/data/cmsDestinations';
import { jussiTraveller } from '../../../../test/data/cmsTraveller';
import {
  CmsContentPage,
  CmsDestination,
  CmsHelpPageCategory,
  CmsMainHelpPage,
  CmsMemory,
  CmsSiteSettings,
  TravellerWithMemories
} from '../types';
import { QuestionCollection } from '../../../redux/features/questions';
import { questions } from '../../../../test/data/questions/questions';
import { homePage } from '../../../../test/data/cmsContentPages';
import cmsMainHelpPage from '../../../../test/data/cmsMainHelpPage';
import cmsHelpCategories from '../../../../test/data/cmsHelpCategories';
import cmsSiteSettings from '../../../../test/data/cmsSiteSettings';
import { Answers } from '../../../search/SearchAnswers';

export const nonExistingSlug = 'thisSlugDoesNotExist';

export async function fetchContentPage(
  slug: string
): Promise<CmsContentPage | undefined> {
  if (slug === nonExistingSlug) {
    return undefined;
  }
  //@ts-ignore
  return homePage;
}

export async function fetchQuestionCollection(
  slug: string
): Promise<QuestionCollection> {
  if (slug === nonExistingSlug) {
    return [];
  }
  return questions;
}

export async function fetchDestination(
  slug: string
): Promise<CmsDestination | undefined> {
  if (slug === nonExistingSlug) {
    return undefined;
  }
  return [lisbon, lofoten].find(
    (destination) => destination.fields.slug === slug
  );
}
export async function fetchMemoriesByLinkedEntry(
  // eslint-disable-next-line no-unused-vars
  destinationId: string
): Promise<CmsMemory[]> {
  return [lisbonMemory, tenerifeMemory];
}

export async function fetchDestinations(): Promise<CmsDestination[]> {
  return [lisbon, lofoten];
}

export async function fetchAllDestinationsForSitemap() {
  return [
    {
      slug: 'lissabon',
      updatedAt: '2019-10-28T08:30:54.856Z'
    }
  ];
}

export async function fetchMemory(
  slug: string
): Promise<CmsMemory | undefined> {
  if (slug === nonExistingSlug) {
    return undefined;
  }
  return [lisbonMemory, tenerifeMemory].find(
    (memory) => memory.fields.slug === slug
  );
}

export async function fetchMemories(
  searchAnswers?: Answers
): Promise<CmsMemory[]> {
  if (!searchAnswers || isEmpty(searchAnswers)) {
    return [lisbonMemory];
  }
  // TODO: We should control what mock returns in E2E test file - not here, for example like this:
  // cypress test-file:
  // 1. fetchMemoriesMock.returns([lisbonMemory, lisbonMemory])
  // 2. actual test code
  // We are a bit hurry now, so just hard code some logic here.
  const partyCoupleWeekend =
    searchAnswers['activities'] &&
    searchAnswers['activities'].includes('party') &&
    searchAnswers['groups'] &&
    searchAnswers['groups'].includes('couple') &&
    searchAnswers['lengths'] &&
    searchAnswers['lengths'].includes('weekend');
  const onlyParty =
    searchAnswers['activities'] &&
    searchAnswers['activities'].includes('party') &&
    searchAnswers['groups'] &&
    searchAnswers['groups'].length === 0 &&
    searchAnswers['lengths'] &&
    searchAnswers['lengths'].length === 0;
  if (partyCoupleWeekend || onlyParty) {
    return [lisbonMemory];
  }
  return [];
}

export async function fetchAllTravellersWithMemories(): Promise<
  TravellerWithMemories[]
> {
  return [{ ...jussiTraveller, memories: [] }];
}

export async function fetchAllMemoriesForSitemap() {
  return [
    {
      slug: 'nos-festareilla-lissabonissa',
      updatedAt: '2019-08-22T09:57:39.524Z'
    }
  ];
}

export async function fetchTravellerWithMemories(
  slug: string
): Promise<TravellerWithMemories | undefined> {
  if (slug === nonExistingSlug) {
    return undefined;
  }
  return { ...jussiTraveller, memories: [] };
}

export async function fetchAllTravellersForSitemap() {
  return [
    {
      slug: 'jussi',
      updatedAt: '2019-08-22T10:40:01.833Z'
    }
  ];
}

export async function fetchMainHelpPage(): Promise<
  CmsMainHelpPage | undefined
> {
  return cmsMainHelpPage;
}

export async function fetchHelpPageCategories(
  slug?: string
): Promise<CmsHelpPageCategory[]> {
  if (slug) {
    return [cmsHelpCategories[0]];
  }
  return cmsHelpCategories;
}

export async function fetchAllHelpPageCategoriesForSitemap() {
  return [
    {
      slug: 'ohjeita',
      updatedAt: '2019-08-22T10:40:01.833Z'
    }
  ];
}

export async function fetchSiteSettings(): Promise<CmsSiteSettings> {
  return cmsSiteSettings;
}
