import {
  Asset,
  CarouselBlock,
  CarouselSlide,
  ContentPage,
  Destination,
  Entry,
  ExperiencePhase,
  HelpPageCategory,
  HelpPageTopic,
  HotelPhase,
  LiftBlock,
  MainHelpPage,
  Memory,
  PopularMemoriesLiftBlock,
  QuestionCollection,
  SiteSettings,
  Traveller,
  TravellerHomepageLink,
  TravelPhase
} from './apiTypes';

interface MaybeWithCmsMemory {
  // This can be fetched with links_to_entry
  memory?: CmsMemory;
}

export type CmsMemory = Entry<Memory>;
export type CmsBlock = Entry<
  LiftBlock | CarouselBlock | PopularMemoriesLiftBlock
>;
export type CmsQuestionCollection = Entry<QuestionCollection>;
export type CmsTraveller = Entry<Traveller>;
export type CmsHomepageLink = Entry<TravellerHomepageLink>;
export type CmsDestination = Entry<Destination>;
export type CmsExperiencePhase = Entry<ExperiencePhase>;
export type CmsHotelPhase = Entry<HotelPhase>;
export type CmsTravelPhase = Entry<TravelPhase>;
export type CmsPhase = Entry<ExperiencePhase | HotelPhase | TravelPhase>;
export type CmsAsset = Asset;
export type CmsCarouselBlock = Entry<CarouselBlock>;
export type CmsPopularMemoriesLiftBlock = Entry<PopularMemoriesLiftBlock>;
export type CmsCarouselSlide = Entry<CarouselSlide>;
export type CmsLiftBlock = Entry<LiftBlock> & MaybeWithCmsMemory;
export type CmsMainHelpPage = Entry<MainHelpPage>;
export type CmsHelpPageCategory = Entry<HelpPageCategory>;
export type CmsHelpPageTopic = Entry<HelpPageTopic>;
export type CmsSiteSettings = Entry<SiteSettings>;
export type CmsContentPage = Entry<
  ContentPage & {
    readonly blocks?: readonly (CmsBlock & MaybeWithCmsMemory)[];
  }
>;
export interface CmsFile {
  contentType: string;
  details: {
    image?: {
      height: number;
      width: number;
    };
    size: number;
  };
  fileName: string;
  url: string;
}

export enum CmsContentTypes {
  Destination = 'destination',
  Memory = 'memory',
  Traveller = 'traveller',
  TravellerHomepageLink = 'travellerHomepageLink',
  TravelPhase = 'travelPhase',
  HotelPhase = 'hotelPhase',
  ExperiencePhase = 'experiencePhase',
  Question = 'question',
  QuestionCollection = 'questionCollection',
  QuestionAnswerOption = 'questionAnswerOption',
  ContentPage = 'contentPage',
  LiftBlock = 'liftBlock',
  CarouselBlock = 'carouselBlock',
  PopularMemoriesLiftBlock = 'popularMemoriesLiftBlock',
  MainHelpPage = 'mainHelpPage',
  HelpPageCategory = 'helpPageCategory',
  SiteSettings = 'siteSettings'
}

export interface TravellerWithMemories extends CmsTraveller {
  memories: CmsMemory[];
}

function isOfCmsType(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
  type: string
) {
  return (
    object.sys &&
    object.sys.contentType &&
    object.sys.contentType.sys &&
    object.sys.contentType.sys.id === type
  );
}

export function instanceOfCmsExperiencePhase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsExperiencePhase {
  return isOfCmsType(object, CmsContentTypes.ExperiencePhase);
}

export function instanceOfCmsHotelPhase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsHotelPhase {
  return isOfCmsType(object, CmsContentTypes.HotelPhase);
}

export function instanceOfCmsTravelPhase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsTravelPhase {
  return isOfCmsType(object, CmsContentTypes.TravelPhase);
}

export function instanceOfCmsPhase(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsPhase {
  return (
    instanceOfCmsExperiencePhase(object) ||
    instanceOfCmsTravelPhase(object) ||
    instanceOfCmsHotelPhase(object)
  );
}

export function instanceOfCmsMemory(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsMemory {
  return isOfCmsType(object, CmsContentTypes.Memory);
}

export function instanceOfCmsDestination(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsDestination {
  return isOfCmsType(object, CmsContentTypes.Destination);
}

export function instanceOfCmsLiftBlock(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsLiftBlock {
  return isOfCmsType(object, CmsContentTypes.LiftBlock);
}

export function instanceOfCmsCarouselBlock(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsCarouselBlock {
  return isOfCmsType(object, CmsContentTypes.CarouselBlock);
}

export function instanceOfCmsPopularMemoriesLiftBlock(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsPopularMemoriesLiftBlock {
  return isOfCmsType(object, CmsContentTypes.PopularMemoriesLiftBlock);
}

export function instanceOfCmsAsset(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): object is CmsAsset {
  return object.sys && object.sys.type === 'Asset';
}
