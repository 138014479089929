import React from 'react';
import DefaultNavbar from '../components/navigation/DefaultNavbar';
import Footer from '../components/layout/Footer';
import HorizontalLine from '../components/common/HorizontalLine';
import { ResponsiveProp } from '../utils/layoutUtils';

export function navbarLayout(
  navbar: React.ReactNode,
  page: React.ReactNode,
  footerExtraSpacing?: ResponsiveProp<number>
) {
  return (
    <>
      <DefaultNavbar>{navbar}</DefaultNavbar>
      {page}
      <HorizontalLine expand={false} />
      <Footer footerExtraSpacing={footerExtraSpacing} />
    </>
  );
}
