import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineEpics } from 'redux-observable';
import axios from 'axios';
import {
  LoadingState,
  shouldBeLoaded,
  WithLoadingState
} from '../../loading/loadable';
import { caseProduce } from '../util';
import { GetVideoResponse } from '../../api/videos/types';
import { fetchIfNotFetchedEpic, fetchPromiseEpic } from '../epicUtils';
import { RootState } from './index';

async function fetchVideo(contentfulAssetId: string) {
  const { data } = await axios.get<GetVideoResponse>(
    `/api/videos/${contentfulAssetId}`
  );
  return data;
}

export type VideoLoadable = {
  video?: GetVideoResponse;
} & WithLoadingState;

// Actions
interface FetchVideoParams {
  contentfulAssetId: string;
}
const actionCreator = actionCreatorFactory('videos');
export const videosActions = {
  fetchVideoIfNotFetched: actionCreator<FetchVideoParams>('MAYBE_FETCH_VIDEO'),
  fetchVideo: actionCreator.async<FetchVideoParams, GetVideoResponse>(
    'FETCH_VIDEO'
  )
};

// State
export type VideosState = Record<string, VideoLoadable>;
export const initialState: VideosState = {};

// Reducer
export default reducerWithInitialState(initialState)
  .case(
    videosActions.fetchVideo.started,
    caseProduce((draftState, payload) => {
      const key = payload.contentfulAssetId;
      draftState[key] = {
        loadingState: LoadingState.STARTED
      };
    })
  )
  .case(
    videosActions.fetchVideo.done,
    caseProduce((draftState, payload) => {
      const key = payload.params.contentfulAssetId;
      draftState[key] = {
        loadingState: LoadingState.DONE,
        video: payload.result
      };
    })
  )
  .case(
    videosActions.fetchVideo.failed,
    caseProduce((draftState, payload) => {
      const key = payload.params.contentfulAssetId;
      draftState[key] = {
        loadingState: LoadingState.FAILED
      };
    })
  );

// Selectors
export const selectVideo = (
  state: RootState,
  contentfulAssetId: string
): VideoLoadable => {
  return state.videos[contentfulAssetId];
};

// Epics
const fetchVideoIfNotFetchedEpic = fetchIfNotFetchedEpic<FetchVideoParams>(
  videosActions.fetchVideoIfNotFetched,
  (action, state) => {
    const videos = selectVideo(state, action.payload.contentfulAssetId);
    return shouldBeLoaded(videos);
  },
  (action) => {
    return videosActions.fetchVideo.started({
      contentfulAssetId: action.payload.contentfulAssetId
    });
  }
);

const fetchVideoEpic = fetchPromiseEpic<
  FetchVideoParams,
  GetVideoResponse,
  GetVideoResponse
>(
  videosActions.fetchVideo.started,
  (action) => fetchVideo(action.payload.contentfulAssetId),
  (action, videoResponse) =>
    videosActions.fetchVideo.done({
      params: action.payload,
      result: videoResponse
    }),
  (action, error) =>
    videosActions.fetchVideo.failed({
      params: action.payload,
      error
    })
);

export const videosEpic = combineEpics(
  fetchVideoEpic,
  fetchVideoIfNotFetchedEpic
);
