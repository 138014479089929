import { configuration } from '../../utils/environment';
import * as contentfulQueries from './contenfulQueries';
import * as mockQueries from './__mocks__/queries';

const cmsQueries = configuration.mockContentfulRequests
  ? mockQueries
  : contentfulQueries;

export const fetchContentPage = cmsQueries.fetchContentPage;
export const fetchQuestionCollection = cmsQueries.fetchQuestionCollection;
export const fetchDestination = cmsQueries.fetchDestination;
export const fetchDestinations = cmsQueries.fetchDestinations;
export const fetchMemory = cmsQueries.fetchMemory;
export const fetchMemories = cmsQueries.fetchMemories;
export const fetchMemoriesByLinkedEntry = cmsQueries.fetchMemoriesByLinkedEntry;
export const fetchAllTravellersWithMemories =
  cmsQueries.fetchAllTravellersWithMemories;
export const fetchAllDestinationsForSitemap =
  cmsQueries.fetchAllDestinationsForSitemap;
export const fetchAllMemoriesForSitemap = cmsQueries.fetchAllMemoriesForSitemap;
export const fetchAllTravellersForSitemap =
  cmsQueries.fetchAllTravellersForSitemap;
export const fetchMainHelpPage = cmsQueries.fetchMainHelpPage;
export const fetchHelpPageCategories = cmsQueries.fetchHelpPageCategories;
export const fetchAllHelpPageCategoriesForSitemap =
  cmsQueries.fetchAllHelpPageCategoriesForSitemap;
export const fetchSiteSettings = cmsQueries.fetchSiteSettings;

export const fetchTravellerWithMemories = cmsQueries.fetchTravellerWithMemories;
