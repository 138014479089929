import React from 'react';
import { CmsAsset } from '../../integrations/contentful/types';
import { baseXInPixels, cmsImageMaxAspectRatio } from '../../styles/theme';
import ImageLimiter from '../common/ImageLimiter';
import { ResponsiveProp } from '../../utils/layoutUtils';
import {
  isImageContent,
  isVideoContent
} from '../../integrations/contentful/utils';
import CmsImage from './CmsImage';
import CmsVideo from './CmsVideo';

const CmsMediaFixedHeight: React.FunctionComponent<{
  media: CmsAsset;
  fixedHeight: ResponsiveProp<number>;
  maxWidth?: number;
  borderRadius?: ResponsiveProp<number>;
  hover?: boolean;
}> = ({ media, fixedHeight, maxWidth, borderRadius = 0, hover }) => {
  const largestHeight: number = Array.isArray(fixedHeight)
    ? Math.max(...fixedHeight.map(Number))
    : fixedHeight;
  if (isImageContent(media.fields.file.contentType)) {
    const fixedHeightForRetina = largestHeight * 2;
    let width;
    if (maxWidth) {
      width = baseXInPixels(maxWidth) * 2;
    } else {
      width = baseXInPixels(fixedHeightForRetina) * cmsImageMaxAspectRatio;
    }
    return (
      <ImageLimiter height={fixedHeight} borderRadius={borderRadius}>
        <CmsImage
          image={media}
          width={width}
          height={baseXInPixels(fixedHeightForRetina)}
          skeleton={false}
          crop
          hover={hover}
        />
      </ImageLimiter>
    );
  } else if (isVideoContent(media.fields.file.contentType)) {
    return (
      <CmsVideo
        video={media}
        borderRadius={borderRadius}
        background
        fixedHeight={fixedHeight}
        hover={hover}
      />
    );
  }
  return <div />;
};

export default CmsMediaFixedHeight;
