const cmsExperiencePhase = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '2lJ83tERb7gyO5Pf34Q9sb',
    type: 'Entry',
    createdAt: '2019-08-21T13:58:14.616Z',
    updatedAt: '2019-08-30T12:04:41.365Z',
    environment: {
      sys: {
        id: 'master',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 2,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'experiencePhase'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    label: 'NOS Festival, kahden päivän lippu',
    title: 'Mahtava meininki ja musa soi lujaa',
    mainMedia: {
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'bh7k0sgo84rb'
          }
        },
        id: '2kRmEXS7GO2ynmj9LR7p4m',
        type: 'Asset',
        createdAt: '2019-08-21T13:50:51.676Z',
        updatedAt: '2019-08-21T13:50:51.676Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment'
          }
        },
        revision: 1,
        locale: 'fi-FI'
      },
      fields: {
        title: 'Ilotuksia festareilla',
        description: 'Bätäräng!',
        file: {
          url: '//localhost:3000/static/test/dummy-image.png',
          details: {
            size: 3425553,
            image: {
              width: 1560,
              height: 1518
            }
          },
          fileName: 'Screenshot 2019-08-21 at 16.39.11.png',
          contentType: 'image/png'
        }
      }
    },
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam non\nauctor dui. Nunc condimentum nibh sed ornare molestie. Curabitur id\nlibero sit amet ante porttitor ultrices. In id lacus posuere, accumsan\nlorem in, blandit lorem. Suspendisse justo nisl, bibendum vitae\nporttitor at, accumsan ut augue.\n\nSuspendisse tincidunt mi sed magna rutrum vulputate. Duis\npellentesque euismod magna non vestibulum. Fusce posuere facilisis\nmetus, ac laoreet tellus. Vestibulum eu dolor sed odio commodo\nmalesuada. Pellentesque accumsan tincidunt tincidunt. Nullam luctus\naliquet malesuada. Curabitur gravida ex non rutrum efficitur.',
    media: [
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '1kruHCLjtkH8wjMcdXAzgE',
          type: 'Asset',
          createdAt: '2019-08-21T13:52:24.183Z',
          updatedAt: '2019-08-21T13:52:24.183Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Festariyleisöä',
          description: 'Oli nättii!',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: {
              size: 2117385,
              image: {
                width: 1684,
                height: 760
              }
            },
            fileName: 'Screenshot 2019-08-21 at 16.39.19.png',
            contentType: 'image/png'
          }
        }
      },
      {
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'bh7k0sgo84rb'
            }
          },
          id: '5WdMGI6xPAc0ezh4ql8kFo',
          type: 'Asset',
          createdAt: '2019-08-21T13:53:15.090Z',
          updatedAt: '2019-08-21T13:53:15.090Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment'
            }
          },
          revision: 1,
          locale: 'fi-FI'
        },
        fields: {
          title: 'Astronautti',
          description: 'Astronautti oli aika huikee!',
          file: {
            url: '//localhost:3000/static/test/dummy-image.png',
            details: {
              size: 1921975,
              image: {
                width: 1686,
                height: 772
              }
            },
            fileName: 'Screenshot 2019-08-21 at 16.39.24.png',
            contentType: 'image/png'
          }
        }
      }
    ],
    ctaText: 'Tutustu',
    ctaUrl: 'https://nosalive.com/'
  }
};
export default cmsExperiencePhase;
