export interface NavigationHistoryItem {
  asPath: string;
}

export default class NavigationHistory {
  private items: NavigationHistoryItem[] = [];

  public pushItem(asPath: string) {
    this.items = [...this.items, { asPath }];
  }

  public back() {
    this.items = this.items.slice(0, -1);
  }

  public getCurrentAsPath(): string | undefined {
    const currentItem = this.getCurrentItem();
    return currentItem && currentItem.asPath;
  }

  public getBackAsPath(): string | undefined {
    const backItem = this.getBackItem();
    return backItem && backItem.asPath;
  }

  public getCurrentItem(): NavigationHistoryItem {
    return this.items[this.items.length - 1];
  }

  public getBackItem(): NavigationHistoryItem | undefined {
    return this.items[this.items.length - 2];
  }
}
