import { IncomingMessage, ServerResponse } from 'http';
import auth from 'basic-auth';
import compare from 'tsscmp';
import { configuration } from '../utils/environment';

export function isAuthorized(req: IncomingMessage): boolean {
  if (!configuration.auth.enabled) {
    return true;
  }
  return hasAllowedApikey(req) || isAllowedIp(req) || isValidAuth(req);
}

export function requireBasicAuth(res: ServerResponse) {
  res.statusCode = 401;
  res.setHeader('WWW-Authenticate', 'Basic realm="TIM"');
  res.end('Access denied');
}

function isValidAuth(req: IncomingMessage) {
  const credentials = auth(req);
  return (
    !!credentials && isValidCredentials(credentials.name, credentials.pass)
  );
}

// From https://www.npmjs.com/package/basic-auth
function isValidCredentials(givenUsername: string, givenPassword: string) {
  if (!configuration.auth.username || !configuration.auth.password) {
    return false;
  }

  // We have named variables, because want to run both compare-methods every time in order to avoid timing attacks
  const isValidUsername = compare(givenUsername, configuration.auth.username);
  const isValidPassword = compare(givenPassword, configuration.auth.password);

  return isValidUsername && isValidPassword;
}

function hasAllowedApikey(req: IncomingMessage) {
  return (
    !!req.url &&
    !!configuration.auth.apikey &&
    new RegExp(`apikey=${configuration.auth.apikey}`).test(req.url)
  );
}

function isAllowedIp(req: IncomingMessage) {
  if (!configuration.auth.bypassIPAddresses) {
    return false;
  }

  // https://zeit.co/docs/v2/network/headers#x-forwarded-for
  const ipAddress =
    req.headers &&
    String(req.headers['x-forwarded-for'] || req.connection.remoteAddress);

  return (
    !!ipAddress && configuration.auth.bypassIPAddresses.includes(ipAddress)
  );
}
