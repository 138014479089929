import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { combineEpics } from 'redux-observable';
import {
  LoadingState,
  shouldBeLoaded,
  WithLoadingState
} from '../../loading/loadable';
import { caseProduce } from '../util';
import { fetchSiteSettings } from '../../integrations/contentful/queries';
import { CmsSiteSettings } from '../../integrations/contentful/types';
import { fetchIfNotFetchedEpic, fetchPromiseEpic } from '../epicUtils';
import { RootState } from './index';

export interface SiteSettings {
  emergencyPhoneNumber: string;
  supportEmail: string;
  supportOfficeHours: string;
  supportPhoneNumber: string;
  facebookUrl?: string;
  instagramUrl?: string;
}

export type SiteSettingsLoadable = {
  settings: SiteSettings;
} & WithLoadingState;

const actionCreator = actionCreatorFactory('siteSettings');
export const siteSettingsActions = {
  fetchSiteSettingsIfNotFetched: actionCreator('MAYBE_FETCH_SITE_SETTINGS'),
  fetchSiteSettings: actionCreator.async<undefined, SiteSettings>(
    'FETCH_SITE_SETTINGS'
  )
};

// State
export type SiteSettingsState = SiteSettingsLoadable;
export const initialState = {};

// Reducer
export default reducerWithInitialState(initialState)
  .case(
    siteSettingsActions.fetchSiteSettings.started,
    caseProduce((draftState) => {
      draftState = {
        loadingState: LoadingState.STARTED,
        settings: {}
      };
      return draftState;
    })
  )
  .case(
    siteSettingsActions.fetchSiteSettings.done,
    caseProduce((draftState, payload) => {
      draftState = {
        loadingState: LoadingState.DONE,
        settings: payload.result
      };
      return draftState;
    })
  )
  .case(
    siteSettingsActions.fetchSiteSettings.failed,
    caseProduce((draftState) => {
      draftState = {
        loadingState: LoadingState.FAILED,
        settings: {}
      };
      return draftState;
    })
  );

// Selectors
export const selectSiteSettings = (state: RootState): SiteSettingsLoadable => {
  return state.siteSettings;
};

// Epics
const fetchSiteSettingsIfNotFetchedEpic = fetchIfNotFetchedEpic(
  siteSettingsActions.fetchSiteSettingsIfNotFetched,
  (action, state) => {
    const siteSettings = selectSiteSettings(state);
    return shouldBeLoaded(siteSettings);
  },
  () => {
    return siteSettingsActions.fetchSiteSettings.started(undefined);
  }
);

const fetchSiteSettingsEpic = fetchPromiseEpic<
  undefined,
  CmsSiteSettings | undefined,
  SiteSettings | undefined
>(
  siteSettingsActions.fetchSiteSettings.started,
  () => fetchSiteSettings(),
  (action, siteSettingsResponse) => {
    if (siteSettingsResponse) {
      return siteSettingsActions.fetchSiteSettings.done({
        result: siteSettingsResponse.fields
      });
    } else {
      return siteSettingsActions.fetchSiteSettings.failed({
        error: {}
      });
    }
  },
  (action, error) =>
    siteSettingsActions.fetchSiteSettings.failed({
      error
    })
);

export const siteSettingsEpic = combineEpics(
  fetchSiteSettingsEpic,
  fetchSiteSettingsIfNotFetchedEpic
);
