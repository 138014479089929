export const festivalImage = {
  sys: {
    id: '7h9nXFz52WDdDNArmfdVl9',
    type: 'Asset',
    createdAt: '2019-08-22T09:41:51.254Z',
    updatedAt: '2019-08-22T09:41:51.254Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'Festarikuva',
    description: 'Festarireissulla oli kova meno',
    file: {
      url: '//localhost:3000/static/test/dummy-image.png',
      details: {
        size: 544685,
        image: {
          width: 692,
          height: 529
        }
      },
      fileName: 'Screenshot 2019-08-22 at 12.41.17.png',
      contentType: 'image/png'
    }
  }
};

export const lisbonImage = {
  sys: {
    id: 'XqTteLBKsNKvpFQv96TyQ',
    type: 'Asset',
    createdAt: '2019-10-16T13:28:36.174Z',
    updatedAt: '2019-10-16T13:28:36.174Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lissabonin kuva',
    description: 'Lissabon on kaupunki Portugalissa',
    file: {
      url: '//localhost:3000/static/test/dummy-image.png',
      details: {
        size: 342650,
        image: {
          width: 1220,
          height: 800
        }
      },
      fileName: 'portugali-lissabon-linna-ss.jpg',
      contentType: 'image/jpeg'
    }
  }
};

export const lofotenImage = {
  sys: {
    id: '7ikkfAjQWmlQ2Y2E8YyRkj',
    type: 'Asset',
    createdAt: '2019-10-16T13:25:31.610Z',
    updatedAt: '2019-10-16T13:25:31.610Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lofootit',
    description: 'Lofootit ovat Pohjois-Norjan kruunun jalokivi.',
    file: {
      url: '//localhost:3000/static/test/dummy-image.png',
      details: {
        size: 162161,
        image: {
          width: 900,
          height: 600
        }
      },
      fileName: 'Lofootit_Norja_01.jpg',
      contentType: 'image/jpeg'
    }
  }
};

export const profilePicture = {
  sys: {
    id: '8Pg0V3YukMC8XjWCDcpjl',
    type: 'Asset',
    createdAt: '2019-08-22T10:40:01.833Z',
    updatedAt: '2019-08-22T10:40:01.833Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'adult-boy-casual-220453',
    description: '',
    file: {
      url: '//localhost:3000/static/test/dummy-image.png',
      details: {
        size: 4064053,
        image: {
          width: 3476,
          height: 5214
        }
      },
      fileName: 'adult-boy-casual-220453.jpg',
      contentType: 'image/jpeg'
    }
  }
};
