/**
 * This is a very simple function that is used to mark texts that need to be translated,
 * in case we need to translate something in the future. Includes super simple singular and plural
 * support.
 *
 * @param text Singular version of the text "yö alkaen"
 * @param pluralizedText Pluralized version of the text "yötä alkaen"
 * @param isPlural If set true, uses the pluralized version of the text
 */
export function t(text: string, pluralizedText?: string, isPlural?: boolean) {
  return isPlural ? pluralizedText || '' : text;
}
