const lisbonTrip = {
  sys: {
    id: '7r4R4BJIZTru7RxCIok4HZ',
    type: 'Entry',
    createdAt: '2019-08-21T13:29:10.602Z',
    updatedAt: '2019-08-21T13:29:10.602Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lissabonin kaupunkiloma',
    erpTripId: 1002
  }
};

const lofotenTrip = {
  sys: {
    id: 'gxcassa',
    type: 'Entry',
    createdAt: '2019-08-21T13:29:10.602Z',
    updatedAt: '2019-08-21T13:29:10.602Z',
    revision: 1,
    locale: 'fi-FI'
  },
  fields: {
    title: 'Lofoottien kaupunkiloma',
    erpTripId: 555
  }
};

export { lisbonTrip, lofotenTrip };
