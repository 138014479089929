import React, { useContext } from 'react';
import { CmsAsset } from '../../integrations/contentful/types';
import {
  isImageContent,
  isVideoContent
} from '../../integrations/contentful/utils';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';
import { WindowContext } from '../common/WindowContextProvider';
import CmsImage from './CmsImage';
import CmsVideo from './CmsVideo';

interface CmsMediaProps {
  media: CmsAsset;
  width?: ResponsiveProp<number>;
  height?: ResponsiveProp<number>;
  crop?: boolean;
  forceJpgOrWebp?: boolean;
  borderRadius?: number;
  skeleton?: boolean;
  borderRadiusColor?: string;
  hover?: boolean;
}

const CmsMedia: React.FunctionComponent<CmsMediaProps> = ({
  media,
  width,
  height,
  crop,
  forceJpgOrWebp,
  borderRadius,
  skeleton,
  borderRadiusColor,
  hover
}) => {
  const heightValue = propToResponsivePropObject(height);
  const widthValue = propToResponsivePropObject(width);
  const { breakpoint } = useContext(WindowContext);
  const currentHeight = breakpoint && heightValue[breakpoint];
  const currentWidth = breakpoint && widthValue[breakpoint];

  if (isImageContent(media.fields.file.contentType)) {
    return (
      <CmsImage
        image={media}
        // Handle retina displays
        width={currentWidth ? currentWidth * 2 : undefined}
        height={currentHeight ? currentHeight * 2 : undefined}
        crop={crop}
        forceJpgOrWebp={forceJpgOrWebp}
        borderRadius={borderRadius}
        skeleton={skeleton}
        hover={hover}
      />
    );
  } else if (isVideoContent(media.fields.file.contentType)) {
    return (
      <CmsVideo
        video={media}
        width={currentWidth}
        height={currentHeight}
        borderRadius={borderRadius}
        crop={crop}
        background
        skeleton={skeleton}
        borderRadiusColor={borderRadiusColor}
        hover={hover}
      />
    );
  }
  return <div />;
};

export default CmsMedia;
