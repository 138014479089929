import React from 'react';
import classNames from 'classnames';
import { baseXUnit, breakpoints, color, spacing } from '../../styles/theme';

const HorizontalLine: React.FunctionComponent<{ expand?: boolean }> = ({
  expand = true
}) => {
  return (
    <div className={classNames('line', { expand })}>
      {/*language=CSS*/}
      <style jsx>{`
        .line {
          border-bottom: 1px solid ${color.light};
          padding-bottom: ${baseXUnit(2)};
        }

        .line.expand {
          /* On small devices draw the line over one container padding so that it reaches from screen's edge to edge
             Otherwise we would need to split the markup everywhere to separate Containers where we need horizontal line.
             While often possible, it gets irksome with responsive columns.
          */
          margin-left: -${spacing.two};
          margin-right: -${spacing.two};
        }

        @media ${breakpoints.large} {
          .line.expand {
            margin-left: 0;
            margin-right: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default HorizontalLine;
