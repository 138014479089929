import React from 'react';
import {
  baseXUnit,
  font,
  fontSize,
  fontWeight,
  letterSpacing,
  spacing,
  color as themeColor
} from '../../styles/theme';

type ButtonTextVariant = 'primary' | 'secondary';
type TextColor = 'purpleLight' | 'dark' | 'white' | 'gray';

export interface ButtonTextProps {
  variant: ButtonTextVariant;
  color?: TextColor;
  lineHeight?: number;
}

const ButtonText: React.FunctionComponent<ButtonTextProps> = ({
  children,
  color,
  lineHeight,
  variant
}) => {
  return (
    <div className={`button-text ${variant}`}>
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .button-text {
          max-width: 100%;
          font-weight: ${fontWeight.bold};
          font-size: ${fontSize.px16};
          line-height: ${lineHeight ? baseXUnit(lineHeight) : spacing.three};
          ${color ? `color: ${themeColor[color]}` : ''};
        }
        .button-text.secondary {
          font-family: ${font.mono};
          line-height: ${lineHeight ? baseXUnit(lineHeight) : spacing.two};
          font-size: ${fontSize.px10};
          font-weight: ${fontWeight.bold};
          letter-spacing: ${letterSpacing.wide};
          text-transform: uppercase;
        }
      `}</style>
    </div>
  );
};

export default ButtonText;
