import React from 'react';
import { baseXUnit } from '../../styles/theme';

interface StackProps {
  space: number;
}

const Stack: React.FunctionComponent<StackProps> = ({ space, children }) => {
  return (
    <div className="root">
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .root > :global(*:last-child) {
          margin-bottom: 0;
        }
      `}</style>
      {/*language=CSS*/}
      <style jsx>{`
        .root > :global(*) {
          ${space ? `margin-bottom: ${baseXUnit(space)};` : ''}
        }
      `}</style>
    </div>
  );
};

export default Stack;
