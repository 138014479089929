import cmsHelpCategories from './cmsHelpCategories';

const cmsMainHelpPage = {
  sys: {
    space: {
      sys: {
        type: 'Link',
        linkType: 'Space',
        id: 'bh7k0sgo84rb'
      }
    },
    id: '6rdHFq4Lyisuw09nj3jqCs',
    type: 'Entry',
    createdAt: '2020-02-06T13:00:05.195Z',
    updatedAt: '2020-02-06T13:00:05.195Z',
    environment: {
      sys: {
        id: 'sandbox1',
        type: 'Link',
        linkType: 'Environment'
      }
    },
    revision: 1,
    contentType: {
      sys: {
        type: 'Link',
        linkType: 'ContentType',
        id: 'mainHelpPage'
      }
    },
    locale: 'fi-FI'
  },
  fields: {
    title: 'Mutkia matkassa?',
    slug: 'tuki',
    ingress:
      'Tutustu matkustusohjeisiin tai etsi vastauksia usein kysyttyihin kysymyksiin. Ellet löydä etsimääsi, otathan yhteyttä suoraan asiakaspalveluumme.',
    seoTitle: 'Tukisivut',
    mostReadTopics: cmsHelpCategories[0].fields.topics,
    categories: cmsHelpCategories
  }
};

export default cmsMainHelpPage;
