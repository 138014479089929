import Cookies from 'universal-cookie';

enum UserInfo {
  chatFinished = 'chatFinished'
}
export default class UserInfoStore {
  private cookies: Cookies;

  public constructor(cookies: Cookies) {
    this.cookies = cookies;
  }

  public isChatFinished(): boolean {
    return this.get(UserInfo.chatFinished) || false;
  }

  public setChatFinished(chatFinished: boolean) {
    return this.set(UserInfo.chatFinished, chatFinished);
  }

  private set(key: string, value: string | boolean | number) {
    const yearInSeconds = 60 * 60 * 24 * 365;
    return this.cookies.set(key, value, { maxAge: yearInSeconds });
  }

  private get(key: string) {
    return this.cookies.get(key);
  }
}
