import { stringify } from 'query-string';

export const isImageContent = (contentType: string) => {
  return /image\//.test(contentType);
};

export const isVideoContent = (contentType: string) => {
  return /video\//.test(contentType);
};

// Cf. https://www.contentful.com/developers/docs/references/images-api/
interface ImageQueryParams {
  fit?: string;
  fl?: string;
  fm?: string;
  h?: number;
  w?: number;
}

export function formatFileQueryString(
  queryParams: ImageQueryParams,
  // This can be used, when you don't want to render PNG images even if
  // someone has uploaded them.
  forceJpgOrWebp: boolean,
  webpSupported: boolean
) {
  if (webpSupported) {
    queryParams.fm = 'webp';
  } else if (forceJpgOrWebp) {
    queryParams.fm = 'jpg';
    queryParams.fl = 'progressive';
  }
  return stringify(queryParams);
}
