import React from 'react';
import classnames from 'classnames';
import { linkStyle, color as themeColor } from '../../styles/theme';
import { EventData } from '../../analytics/types';
import { trackAnalyticsEvent } from '../../analytics/analytics';

export type HyperLinkColor = 'dark' | 'purple-light';

type HyperLinkTags = 'div' | 'a';

export interface HyperLinkProps {
  linkColor?: HyperLinkColor;
  tag?: HyperLinkTags;
  noLinkStateStyles?: boolean; // disable hover and active styles.
  display?: string;
  hrefEventData?: EventData;
}

/* Usually HyperLink is child of RouteLink (and Link) component.
"If the child of Link is a function component, in addition to using passHref, you must wrap the component in React.forwardRef."
https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component. */
const HyperLink: React.FunctionComponent<
  HyperLinkProps &
    React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLDivElement>
> = React.forwardRef(
  (
    {
      linkColor,
      children,
      className,
      noLinkStateStyles,
      display = 'inline',
      href,
      tag,
      hrefEventData,
      ...rest
    }: HyperLinkProps &
      React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLDivElement>,
    ref: React.Ref<HTMLAnchorElement & HTMLDivElement>
  ) => {
    const Tag = tag || 'a';
    return (
      <Tag
        className={classnames('hyper-link', linkColor, className, {
          'no-state-style': noLinkStateStyles
        })}
        data-testid="hyper-link"
        href={href}
        ref={ref}
        onClick={() => {
          if (href && hrefEventData) {
            // Immediately after onClick user is redirected to outside of the React app. Is there always enough time to send event?
            // Don't know if this is really non-blocking operation.
            trackAnalyticsEvent(
              hrefEventData.category,
              hrefEventData.action,
              hrefEventData.label,
              hrefEventData.value,
              hrefEventData.nonInteraction
            );
          }
          return true;
        }}
        {...rest}
      >
        {children}
        {/*language=CSS*/}
        <style jsx>{`
          .hyper-link {
            /* Disable styles for example in email & tel links. */
            color: inherit;
            text-decoration: inherit;
            cursor: pointer;
          }
          .hyper-link:not(.no-state-style) {
            ${linkStyle.withAnimation}
          }
          .hyper-link.purple-light {
            color: ${themeColor.purpleLight};
          }
          .hyper-link:not(.no-state-style):hover {
            ${linkStyle.hover}
          }
          .hyper-link.dark:not(.no-state-style):active {
            ${linkStyle.active}
          }
          .hyper-link.purple-light:not(.no-state-style):active {
            color: ${themeColor.dark};
          }
        `}</style>
        {/*language=CSS*/}
        <style jsx>{`
          .hyper-link {
            display: ${display};
          }
        `}</style>
      </Tag>
    );
  }
);

export default HyperLink;
