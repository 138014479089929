import {
  Action,
  ActionCreator,
  AnyAction,
  Failure,
  Success
} from 'typescript-fsa';
import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { of } from 'rxjs';
import { RootState } from './features';

export function fetchIfNotFetchedEpic<FetchParams>(
  actionCreator: ActionCreator<FetchParams>,
  filterCallback: (action: Action<FetchParams>, state: RootState) => boolean,
  mapCallback: (action: Action<FetchParams>) => Action<FetchParams>
) {
  const epic: Epic<AnyAction, Action<FetchParams>, RootState> = (
    action$,
    state$
  ) =>
    action$.pipe(
      ofAction(actionCreator),
      filter((action) => filterCallback(action, state$.value)),
      map(mapCallback)
    );
  return epic;
}

export function fetchPromiseEpic<FetchParams, Response, Result>(
  actionCreator: ActionCreator<FetchParams>,
  promiseCallback: (action: Action<FetchParams>) => Promise<Response>,
  doneCallback: (
    action: Action<FetchParams>,
    response: Response
  ) => Action<Success<FetchParams, Result>>,
  errorCallback: (
    action: Action<FetchParams>,
    // FIXME: Fix this typing to be eslint compliant on better time
    // eslint-disable-next-line @typescript-eslint/ban-types
    error: {}
  ) => // eslint-disable-next-line @typescript-eslint/ban-types
  Action<Failure<FetchParams, {}>>
) {
  const epic: Epic<
    AnyAction,
    // eslint-disable-next-line @typescript-eslint/ban-types
    Action<Success<FetchParams, Result> | Failure<FetchParams, {}>>,
    RootState
  > = (action$) =>
    action$.pipe(
      ofAction<FetchParams>(actionCreator),
      mergeMap((action: Action<FetchParams>) =>
        fromPromise(promiseCallback(action)).pipe(
          map((response) => doneCallback(action, response)),
          catchError((error) => of(errorCallback(action, error)))
        )
      )
    );
  return epic;
}
