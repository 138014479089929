import React from 'react';
import Spacing from '../common/Spacing';
import { baseXUnit, color } from '../../styles/theme';
import TimDisclaimer from '../tim/TimDisclaimer';
import HelpPageButton from '../common/HelpPageButton';

const MainMenuInfoBox: React.FunctionComponent = () => {
  return (
    <div className="main-menu-info-box">
      <Spacing left={2} top={2} bottom={2}>
        <HelpPageButton />
      </Spacing>
      <Spacing left={2.5} right={1} bottom={3}>
        <TimDisclaimer />
      </Spacing>
      {/*language=CSS*/}
      <style jsx>{`
        .main-menu-info-box {
          border-top: 1px solid ${color.lightGray};
          position: absolute;
          width: 100%;
          bottom: 0;
        }

        // Prevent info box from going on top of the other content on really small screens
        @media screen and (max-height: 540px) {
          .main-menu-info-box {
            margin-top: ${baseXUnit(4)};
            position: initial;
          }
        }
      `}</style>
    </div>
  );
};

export default MainMenuInfoBox;
