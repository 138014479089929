import React from 'react';
import { NextPageContext } from 'next';
import { t } from '../utils/i18n';
import { captureError } from '../utils/sentry';
import { navbarLayout } from '../layouts/navbarLayout';
import Container from '../components/grid/Container';
import Row from '../components/grid/Row';
import Col from '../components/grid/Col';
import Spacing from '../components/common/Spacing';
import StaticTim from '../components/tim/StaticTim';
import H from '../components/elements/H';
import HyperLink from '../components/elements/HyperLink';
import RouteLink from '../components/navigation/RouteLink';
import { Paths } from '../urls/Paths';
import FlexDiv from '../components/common/FlexDiv';
import { baseXUnit } from '../styles/theme';
import {
  fetchMemories,
  fetchSiteSettings
} from '../integrations/contentful/queries';
import { CmsMemory, CmsSiteSettings } from '../integrations/contentful/types';
import {
  isMemoryValidToRender,
  renderMemoryCardSafely
} from '../components/memories/MemoryCard';
import DisplayOn from '../components/common/DisplayOn';
import IconText from '../components/common/IconText';
import { SocialMediaIcons } from '../components/layout/Footer';
import TimLogo from '../components/navigation/TimLogo';
import { TimPage } from './TimPage';

const ErrorInfoCard: React.FunctionComponent = ({ children }) => {
  return (
    <div className="info-card">
      {children}
      {/*language=CSS*/}
      <style jsx>{`
        .info-card {
          border-radius: ${baseXUnit(1)};
          box-shadow: 0 2px ${baseXUnit(2)} 0 rgba(0, 0, 0, 0.15);
          padding: ${baseXUnit(3.5)};
        }
      `}</style>
    </div>
  );
};

const ContactCustomerServiceInfo: React.FunctionComponent<{
  siteSettings: CmsSiteSettings;
}> = ({ siteSettings }) => {
  return (
    <IconText
      icon="contact-customer-service"
      iconColor="purple-light"
      data-testid="customer-service-info"
    >
      <H styling="h4" tag="div" data>
        {t('Kiireellisissä asioissa voit ottaa yhteyttä puhelimitse ')}
        <HyperLink
          href={'tel:' + siteSettings.fields.supportPhoneNumber}
          linkColor="purple-light"
        >
          {siteSettings.fields.supportPhoneNumber}
        </HyperLink>
        {t(' tai sähköpostitse ')}
        <HyperLink
          href={'mailto:' + siteSettings.fields.supportEmail}
          linkColor="purple-light"
        >
          {siteSettings.fields.supportEmail}
        </HyperLink>
        .
      </H>
    </IconText>
  );
};

const ErrorPageLayout: React.FunctionComponent<{
  testId: string;
  displayTimLogo?: boolean;
  secondRow?: React.ReactNode;
}> = ({ testId, secondRow, displayTimLogo = true, children }) => {
  return (
    <div data-testid={testId} data-cy={testId}>
      <Container>
        {displayTimLogo && (
          <Row>
            <Col size={24}>
              <FlexDiv justifyContent="flex-end">
                <Spacing top={[2, 2, 4]} right={[2, 2, 4]}>
                  <RouteLink path={Paths.Home}>
                    <a>
                      <TimLogo size={4} />
                    </a>
                  </RouteLink>
                </Spacing>
              </FlexDiv>
            </Col>
          </Row>
        )}
        <Row>
          <Col size={[0, 2, 5]} />
          <Col size={[24, 20, 14]}>
            <Spacing top={[15, 26]} bottom={15}>
              <FlexDiv>
                <div className="tim-wrapper">
                  <StaticTim size={[3, 8, 8]} />
                </div>
                <Spacing left={1} top={[0, 2]} bottom={3}>
                  {children}
                </Spacing>
              </FlexDiv>
            </Spacing>
          </Col>
        </Row>
        {secondRow}
      </Container>
    </div>
  );
};

const Error404Page: React.FunctionComponent<{ memory?: CmsMemory }> = ({
  memory
}) => {
  return (
    <ErrorPageLayout
      testId="error-404-page"
      displayTimLogo={false}
      secondRow={
        <Row>
          <Col size={[24, 0, 0]}>
            {isMemoryValidToRender(memory) && (
              <Spacing top={5}>
                <div className="memory-box">
                  {memory && renderMemoryCardSafely(memory)}
                </div>
              </Spacing>
            )}
          </Col>
        </Row>
      }
    >
      <H styling="h2" data>
        <strong>{t('”Error 404.” 🤖💥')}</strong>
      </H>
      <Spacing top={3}>
        <H styling="h2" tag="div" data>
          {t(
            'Ihmisten kielellä se tarkoittaa, että sivua ei nyt valitettavasti löydy.'
          )}
        </H>
      </Spacing>
      <Spacing top={3}>
        <H styling="h2" tag="div" data>
          {t('Mutta ei hätää! ')}
          <RouteLink path={Paths.Home}>
            <HyperLink linkColor="purple-light">
              {t('Palaa etusivulle')}
            </HyperLink>
          </RouteLink>{' '}
          {isMemoryValidToRender(memory) &&
            t('tai lue vaikka tämä mahtava lomamuisto:')}
        </H>
      </Spacing>
      <DisplayOn medium large>
        {isMemoryValidToRender(memory) && (
          <Spacing top={5}>
            <div className="memory-box" data-testid="memory-suggestion">
              {memory && renderMemoryCardSafely(memory)}
            </div>
          </Spacing>
        )}
      </DisplayOn>
      {/*language=CSS*/}
      <style jsx>{`
        .memory-box {
          max-width: ${baseXUnit(60)};
          padding: ${baseXUnit(2)};
          border-radius: ${baseXUnit(1)};
          box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
        }
      `}</style>
    </ErrorPageLayout>
  );
};

const OtherErrorPage: React.FunctionComponent<{
  statusCode?: number;
  siteSettings?: CmsSiteSettings;
}> = ({ statusCode, siteSettings }) => {
  return (
    <ErrorPageLayout testId="other-error-page">
      <H styling="h2" data>
        <strong>
          {statusCode ? `”Error ${statusCode}.” ` : '”Error” '}
          <span role="img" aria-label="robot">
            🔧🤖
          </span>
        </strong>
      </H>
      <Spacing top={3}>
        <H styling="h2" tag="div" data>
          {t(
            'Se tarkoittaa sitä, että jokin on rikki ja insinöörit yrittävät korjata sen.'
          )}
        </H>
      </Spacing>
      <Spacing top={5} bottom={5} right={2}>
        <ErrorInfoCard>
          <IconText icon="nav-arrow-right">
            <H styling="h4" tag="div" data>
              {t(
                'Voit yrittää ladata tämän sivun uudelleen (joskus tästä voi olla apua).'
              )}
            </H>
          </IconText>
          <Spacing top={2.5} bottom={2.5}>
            <IconText icon="nav-arrow-right">
              <H styling="h4" tag="div" data>
                {t('Palaa tänne takaisin hieman myöhemmin.')}
              </H>
            </IconText>
          </Spacing>
          {siteSettings && (
            <ContactCustomerServiceInfo siteSettings={siteSettings} />
          )}
        </ErrorInfoCard>
      </Spacing>

      <H styling="h2" tag="div" data>
        {t('Kiitos kärsivällisyydestä ja nähdään myöhemmin ❤️')}
        <Spacing top={2}>
          <strong>– Tim</strong>
        </Spacing>
      </H>
      {siteSettings && (
        <Spacing top={10}>
          <SocialMediaIcons
            facebookUrl={siteSettings.fields.facebookUrl}
            instagramUrl={siteSettings.fields.instagramUrl}
          />
        </Spacing>
      )}
    </ErrorPageLayout>
  );
};

const IEErrorPage: React.FunctionComponent<{
  siteSettings?: CmsSiteSettings;
}> = ({ siteSettings }) => {
  return (
    <ErrorPageLayout testId="ie-error-page">
      <H styling="h2" data>
        {t(
          'Voi ei… 😞 Nyt näyttää siltä, että saatan olla liian moderni selaimellesi.'
        )}
      </H>
      <Spacing top={5} bottom={5} right={2}>
        <ErrorInfoCard>
          <IconText icon="nav-arrow-right" iconColor="purple-light">
            <HyperLink href="https://www.google.com/intl/fi_fi/chrome/">
              <H styling="h4" tag="div">
                <strong>{t('Lataa Google Chrome -selain')}</strong>
              </H>
            </HyperLink>
          </IconText>
          <Spacing top={2.5} bottom={2.5}>
            <IconText icon="nav-arrow-right" iconColor="purple-light">
              <HyperLink href="https://www.mozilla.org/fi/firefox/new/">
                <H styling="h4" tag="div">
                  <strong>{t('Lataa Mozilla Firefox -selain')}</strong>
                </H>
              </HyperLink>
            </IconText>
          </Spacing>
          {siteSettings && (
            <ContactCustomerServiceInfo siteSettings={siteSettings} />
          )}
        </ErrorInfoCard>
      </Spacing>
      <H styling="h2" tag="div" data>
        {t('Nähdään 🎉')}
        <Spacing top={2}>
          <strong>– Tim</strong>
        </Spacing>
      </H>
      {siteSettings && (
        <Spacing top={10}>
          <SocialMediaIcons
            facebookUrl={siteSettings.fields.facebookUrl}
            instagramUrl={siteSettings.fields.instagramUrl}
          />
        </Spacing>
      )}
    </ErrorPageLayout>
  );
};

const ErrorPage: TimPage<{
  statusCode?: number;
  newestMemory?: CmsMemory;
  siteSettings?: CmsSiteSettings;
  isIE?: boolean;
}> = ({ statusCode, isIE, newestMemory, siteSettings }) => {
  if (isIE) {
    return <IEErrorPage siteSettings={siteSettings} />;
  }
  if (statusCode === 404) {
    return navbarLayout(null, <Error404Page memory={newestMemory} />);
  } else {
    return (
      <OtherErrorPage statusCode={statusCode} siteSettings={siteSettings} />
    );
  }
};

ErrorPage.getInitialProps = async function ({ res, err }: NextPageContext) {
  if (err) {
    await captureError(err);
  }

  const statusCode = err ? err.statusCode : res ? res.statusCode : undefined;
  if (statusCode === 404) {
    const newestMemory = (await fetchMemories(undefined, 1))[0] || undefined;
    return { statusCode, newestMemory };
  } else {
    try {
      const siteSettings = await fetchSiteSettings();
      return { statusCode, siteSettings };
    } catch (err) {
      return { statusCode };
    }
  }
};

export default ErrorPage;
